import React, { useState, useEffect } from 'react'
import { axiosPrivate } from '../apis/axios'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import styled from 'styled-components'
import ReadingExamResults from './ReadingExamResults'
import MathExamResults from './MathExamResults'
import useAuth from '../hooks/useAuth'

function IndividualExam() {
  const location = useLocation()
  const navigate = useNavigate()
  const [exam, setExam] = useState()
  const [userTestData, setUserTestData] = useState()
  const [pageIndex, setPageIndex] = useState(0)
  const [oldESection, setOldESection] = useState('1')
  const { auth, setAuth } = useAuth()

  const params = useParams()

  useEffect(() => {
    const getDemoExam = async () => {
      try {
        if (auth.username != 'demo' || !location.state) {
          const response1 = await axiosPrivate.get('/examdata/', {
            headers: { authorization: 'Bearer ' + auth.accessToken },
            params: { public_id: params.id },
          })

          setUserTestData(response1.data)
          setOldESection(response1.data.exam_section)
          setExam(response1.data.test_data.sections)
        } else {
          setUserTestData(JSON.parse(JSON.stringify(location.state)))
          setOldESection(location.state.exam_section)
          setExam(location.state.test_data.sections)
        }
      } catch (err) {
        console.log(err)
      }
    }
    getDemoExam()
  }, [])
  const ReviewSwitch = (test) => {
    switch (test.module) {
      case '1':
      case '2':
        return (
          <>
            <ReadingExamResults test={test} />
          </>
        )
      case '3':
      case '4':
        return (
          <>
            <MathExamResults test={test} />
          </>
        )
    }
  }
  const examSwitch = () => {
    const expiryTimestamp = new Date()
    const breakTimestamp = new Date()

    switch (parseInt(userTestData.exam_section)) {
      case 1:
        if (!userTestData.exam_json.expireTime) {
          expiryTimestamp.setSeconds(expiryTimestamp.getSeconds() + 1920)

          if (expiryTimestamp) {
            navigate('reading', {
              state: {
                expiryTimestamp: expiryTimestamp,
                questions: exam['1'],
                testData: userTestData,
                replace: true,
              },
              replace: true,
            })
          }
        } else {
          expiryTimestamp.setSeconds(
            expiryTimestamp.getSeconds() + userTestData.exam_json.expireTime
          )
          if (expiryTimestamp) {
            navigate('reading', {
              state: {
                expiryTimestamp: expiryTimestamp,
                questions: exam['1'],
                testData: userTestData,
                replace: true,
              },
              replace: true,
            })
          }
        }
        return

      case 2:
        if (!userTestData.exam_json.expireTime) {
          expiryTimestamp.setSeconds(expiryTimestamp.getSeconds() + 1920)

          if (expiryTimestamp) {
            navigate('reading', {
              state: {
                expiryTimestamp: expiryTimestamp,
                questions: exam['2'],
                testData: userTestData,
                replace: true,
              },
              replace: true,
            })
          }
        } else {
          expiryTimestamp.setSeconds(
            expiryTimestamp.getSeconds() + userTestData.exam_json.expireTime
          )
          if (expiryTimestamp) {
            navigate('reading', {
              state: {
                expiryTimestamp: expiryTimestamp,
                questions: exam['2'],
                testData: userTestData,
                replace: true,
              },
              replace: true,
            })
          }
        }
        return

      case 3:
        if (userTestData.exam_json.finishbreak) {
          if (!userTestData.exam_json.expireTime) {
            expiryTimestamp.setSeconds(expiryTimestamp.getSeconds() + 2100)
            if (expiryTimestamp) {
              navigate('math', {
                state: {
                  expiryTimestamp: expiryTimestamp,
                  breakTimestamp: breakTimestamp,
                  questions: exam['3'],
                  testData: userTestData,
                  replace: true,
                },
                replace: true,
              })
            }
          } else {
            expiryTimestamp.setSeconds(
              expiryTimestamp.getSeconds() + userTestData.exam_json.expireTime
            )
            if (expiryTimestamp) {
              navigate('math', {
                state: {
                  expiryTimestamp: expiryTimestamp,
                  breakTimestamp: breakTimestamp,
                  questions: exam['3'],
                  testData: userTestData,
                  replace: true,
                },
                replace: true,
              })
            }
          }
        } else {
          if (!userTestData.exam_json.breakTime) {
            breakTimestamp.setSeconds(breakTimestamp.getSeconds() + 600)
            expiryTimestamp.setSeconds(expiryTimestamp.getSeconds() + 2100)
            if (expiryTimestamp) {
              navigate('math', {
                state: {
                  expiryTimestamp: expiryTimestamp,
                  breakTimestamp: breakTimestamp,
                  questions: exam['3'],
                  testData: userTestData,
                  replace: true,
                },
                replace: true,
              })
            }
          } else {
            breakTimestamp.setSeconds(
              breakTimestamp.getSeconds() + userTestData.exam_json.breakTime
            )
            expiryTimestamp.setSeconds(
              expiryTimestamp.getSeconds() + userTestData.exam_json.expireTime
            )
            if (expiryTimestamp) {
              navigate('math', {
                state: {
                  expiryTimestamp: expiryTimestamp,
                  breakTimestamp: breakTimestamp,
                  questions: exam['3'],
                  testData: userTestData,
                  replace: true,
                },
                replace: true,
              })
            }
          }
        }

        return

      case 4:
        if (!userTestData.exam_json.expireTime) {
          expiryTimestamp.setSeconds(expiryTimestamp.getSeconds() + 2100)

          if (expiryTimestamp) {
            navigate('math', {
              state: {
                expiryTimestamp: expiryTimestamp,
                questions: exam['4'],
                testData: userTestData,
                // testData: userTestData,
                replace: true,
              },
              replace: true,
            })
          }
        } else {
          expiryTimestamp.setSeconds(
            expiryTimestamp.getSeconds() + userTestData.exam_json.expireTime
          )
          if (expiryTimestamp) {
            navigate('math', {
              state: {
                expiryTimestamp: expiryTimestamp,
                questions: exam['4'],
                testData: userTestData,
                // testData: userTestData,
                replace: true,
              },
              replace: true,
            })
          }
        }

        return
    }
  }
  if (exam && userTestData) {
    return (
      <>
        {userTestData.completed == true
          ? ReviewSwitch(location.state ? location.state : null)
          : examSwitch(location.state ? location.state : null)}
      </>
    )
  }
}
export default IndividualExam
