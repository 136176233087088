import React, { useState } from 'react'
import styled from 'styled-components'
import { MathJax } from 'better-react-mathjax'

const ChoicesContainer = styled.div`
  border-top: 1px solid #ccc;
  pointer-events: none;
`

const ChoiceSection = styled.div`
  display: flex;
  gap: 15px;
  align-items: center;
  justify-content: flex-start;
  // border-top: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
  padding: 8px 16px 8px 16px;
  &:hover {
    background: #d0e6f4;
    outline: 2px solid #1586ca;
  }
`
const QChoicesContainer = styled.div`
  padding: 20px 0px 20px 0px;
`
const QChoices = styled.div`
  // font-height: 15px;
  font-size: 1em;
`
const QExplain = styled.div``
const AnsButton = styled.div`
  min-width: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #1586ca;
  border: 2px solid #1586ca;
  border-radius: 50%;
  height: 30px;
  width: 30px;
  ${(props) =>
    // console.log(props)
    props.selected && props.correct == true
      ? `
    color: white;
    background: #1586ca;`
      : null}
  ${(props) =>
    props.correctAnswer != props.selected
      ? `color:black; 
              background:#fad980;`
      : null}
  ${(props) =>
    props.selected && props.correct == false
      ? `color:white; 
      background:#981b1e;`
      : null}
`

function ExamReviewRadioButton({ qChoices, optionsChosen, answers, index }) {
  console.log(
    'qChoices',
    qChoices,
    'optionsChosen',
    optionsChosen,
    'answers',
    answers,
    'index',
    index
  )
  return (
    <ChoicesContainer>
      <MathJax>
        <ChoiceSection tabIndex={0} selected={optionsChosen == 'A'}>
          <AnsButton
            correct={answers[index]}
            correctAnswer={qChoices.answer == 'A'}
            selected={optionsChosen == 'A'}
          >
            A
          </AnsButton>
          <QChoicesContainer>
            <QChoices>
              <MathJax hideUntilTypeset={'first'} inline dynamic>
                {qChoices.choices[0]}
              </MathJax>{' '}
            </QChoices>
            <QExplain></QExplain>
          </QChoicesContainer>
        </ChoiceSection>
      </MathJax>
      <MathJax>
        <ChoiceSection tabIndex={0} selected={optionsChosen == 'B'}>
          <AnsButton
            correct={answers[index]}
            correctAnswer={qChoices.answer == 'B'}
            selected={optionsChosen == 'B'}
          >
            B
          </AnsButton>
          <QChoicesContainer>
            <QChoices>
              <MathJax hideUntilTypeset={'first'} inline dynamic>
                {qChoices.choices[1]}
              </MathJax>{' '}
            </QChoices>
            <QExplain></QExplain>
          </QChoicesContainer>
        </ChoiceSection>
      </MathJax>
      <MathJax>
        <ChoiceSection tabIndex={0} selected={optionsChosen == 'C'}>
          <AnsButton
            correct={answers[index]}
            correctAnswer={qChoices.answer == 'C'}
            selected={optionsChosen == 'C'}
          >
            C
          </AnsButton>
          <QChoicesContainer>
            <QChoices>
              <MathJax hideUntilTypeset={'first'} inline dynamic>
                {qChoices.choices[2]}
              </MathJax>{' '}
            </QChoices>
            <QExplain></QExplain>
          </QChoicesContainer>
        </ChoiceSection>
      </MathJax>
      <MathJax>
        <ChoiceSection tabIndex={0} selected={optionsChosen == 'D'}>
          <AnsButton
            correct={answers[index]}
            correctAnswer={qChoices.answer == 'D'}
            selected={optionsChosen == 'D'}
          >
            D
          </AnsButton>
          <QChoicesContainer>
            <QChoices>
              <MathJax hideUntilTypeset={'first'} inline dynamic>
                {qChoices.choices[3]}
              </MathJax>{' '}
            </QChoices>
            <QExplain></QExplain>
          </QChoicesContainer>
        </ChoiceSection>
      </MathJax>
    </ChoicesContainer>
  )
}

export default ExamReviewRadioButton
