import React from 'react'
import { useParams } from 'react-router-dom'

function Details() {
  const params = useParams()
  return (
    <div>
      Details... <p>{JSON.stringify(params)}</p>
    </div>
  )
}

export default Details
