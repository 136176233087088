import React, { useState, useRef, useEffect } from 'react'
import styled from 'styled-components'
import { Formik } from 'formik'
import axios from '../apis/axios'
import { AiOutlineClose, AiOutlineCheck } from 'react-icons/ai'
import { useNavigate } from 'react-router-dom'

const FormContainer = styled.div`
  font-family: 'Roboto';
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;

  min-height: 100vh;
`
const Form = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
  width: 90%;
  box-shadow: 0 1px 8px rgba(0, 0, 0, 0.3);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
  border-radius: 10px;
  padding: 50px;
  width: max-content;
`
const SignUpHeader = styled.div`
  height: 40px;
  font-size: 30px;
`

const UserInputContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: left;
`

const UserSpan = styled.span`
  padding-bottom: 5px;
`
const UserInput = styled.input`
  font-size: 20px;
  height: 30px;
  width: 300px;
  border: 1px solid lightgrey;
  border-radius: 3px;

  &:focus,
  &:active {
    box-shadow: rgb(210, 213, 217) 0px 0px 2px 1px,
      rgb(227, 230, 232) 0px 0px 0px 3px;
    border: 1px solid rgb(26, 33, 43);
    outline: none;
  }
  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus {
    background-color: white;
    border: 1px solid lightgrey;
    box-shadow: 0 0 0px 1000px #fff inset;
    -webkit-box-shadow: 0 0 0px 1000px #fff inset;
    transition: background-color 5000s ease-in-out 0s;
    -webkit-text-fill-color: black;
  }

  ${({ valid }) =>
    valid &&
    `
      border: 1px solid rgb(0, 156, 38);

      &:focus,
      &:active {
        border: 1px solid rgb(0, 156, 38);
        box-shadow: rgb(106, 237, 97) 0px 0px 2px 1px,
          rgb(177, 247, 160) 0px 0px 0px 3px;
        outline: none;
      }

      /* Autocomplete styles in Chrome*/
      &:-webkit-autofill,
      &:-webkit-autofill:hover,
      &:-webkit-autofill:focus {
        border: 1px solid rgb(0, 156, 38);
      }
    `}
  ${({ error }) =>
    error &&
    `
      border: 1px solid rgb(191, 49, 12);
      outline: none;

      &:focus,
      &:active {
        box-shadow: rgb(244, 129, 116) 0px 0px 2px 1px,
          rgb(251, 178, 174) 0px 0px 0px 3px;
        border: 1px solid rgb(191, 49, 12);
        outline: none;
      }

      /* Autocomplete styles in Chrome*/
      &:-webkit-autofill,
      &:-webkit-autofill:hover,
      &:-webkit-autofill:focus {
        border: 1px solid rgb(191, 49, 12);
      }
    `}
`

const SignUpErrorMessage = styled.div`
  margin-top: 10px;
  background-color: #ffe0e0;
  color: #560000;
  height: 25px;
  width: 300px;
  font-size: 15px;
  line-height: 25px;
`
const NameInputContainer = styled.div`
  display: grid;
  flex-direction: row;
  justify-content: left;
`
const FirstNameSpan = styled.span`
  padding-bottom: 5px;
  grid-column: 1/2;
`
const FirstNameInput = styled.input`
  margin-right: 20px;
  font-size: 20px;
  height: 30px;
  width: 300px;
  border: 1px solid lightgrey;
  border-radius: 3px;
  grid-column: 1/2;
  grid-row: 2/3;

  &:focus,
  &:active {
    box-shadow: rgb(210, 213, 217) 0px 0px 2px 1px,
      rgb(227, 230, 232) 0px 0px 0px 3px;
    border: 1px solid rgb(26, 33, 43);
    outline: none;
  }
  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus {
    background-color: white;
    border: 1px solid lightgrey;
    box-shadow: 0 0 0px 1000px #fff inset;
    -webkit-box-shadow: 0 0 0px 1000px #fff inset;
    transition: background-color 5000s ease-in-out 0s;
    -webkit-text-fill-color: black;
  }

  ${({ valid }) =>
    valid &&
    `
      border: 1px solid rgb(0, 156, 38);

      &:focus,
      &:active {
        border: 1px solid rgb(0, 156, 38);
        box-shadow: rgb(106, 237, 97) 0px 0px 2px 1px,
          rgb(177, 247, 160) 0px 0px 0px 3px;
        outline: none;
      }

      /* Autocomplete styles in Chrome*/
      &:-webkit-autofill,
      &:-webkit-autofill:hover,
      &:-webkit-autofill:focus {
        border: 1px solid rgb(0, 156, 38);
      }
    `}
  ${({ error }) =>
    error &&
    `
      border: 1px solid rgb(191, 49, 12);
      outline: none;

      &:focus,
      &:active {
        box-shadow: rgb(244, 129, 116) 0px 0px 2px 1px,
          rgb(251, 178, 174) 0px 0px 0px 3px;
        border: 1px solid rgb(191, 49, 12);
        outline: none;
      }

      /* Autocomplete styles in Chrome*/
      &:-webkit-autofill,
      &:-webkit-autofill:hover,
      &:-webkit-autofill:focus {
        border: 1px solid rgb(191, 49, 12);
      }
    `}
`
const FirstNameErrorMessage = styled.div`
  margin-top: 10px;
  background-color: #ffe0e0;
  color: #560000;
  height: 25px;
  width: 300px;
  font-size: 15px;
  line-height: 25px;
  grid-column: 1/2;
  grid-row: 3/3;
`
const LastNameSpan = styled.span`
  padding-bottom: 5px;
  grid-column: 2/2;
`
const LastNameInput = styled.input`
  font-size: 20px;
  height: 30px;
  width: 300px;
  border: 1px solid lightgrey;
  border-radius: 3px;
  grid-column: 2/2;
  grid-row: 2/3;

  &:focus,
  &:active {
    box-shadow: rgb(210, 213, 217) 0px 0px 2px 1px,
      rgb(227, 230, 232) 0px 0px 0px 3px;
    border: 1px solid rgb(26, 33, 43);
    outline: none;
  }
  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus {
    background-color: white;
    border: 1px solid lightgrey;
    box-shadow: 0 0 0px 1000px #fff inset;
    -webkit-box-shadow: 0 0 0px 1000px #fff inset;
    transition: background-color 5000s ease-in-out 0s;
    -webkit-text-fill-color: black;
  }

  ${({ valid }) =>
    valid &&
    `
      border: 1px solid rgb(0, 156, 38);

      &:focus,
      &:active {
        border: 1px solid rgb(0, 156, 38);
        box-shadow: rgb(106, 237, 97) 0px 0px 2px 1px,
          rgb(177, 247, 160) 0px 0px 0px 3px;
        outline: none;
      }

      /* Autocomplete styles in Chrome*/
      &:-webkit-autofill,
      &:-webkit-autofill:hover,
      &:-webkit-autofill:focus {
        border: 1px solid rgb(0, 156, 38);
      }
    `}
  ${({ error }) =>
    error &&
    `
      border: 1px solid rgb(191, 49, 12);
      outline: none;

      &:focus,
      &:active {
        box-shadow: rgb(244, 129, 116) 0px 0px 2px 1px,
          rgb(251, 178, 174) 0px 0px 0px 3px;
        border: 1px solid rgb(191, 49, 12);
        outline: none;
      }

      /* Autocomplete styles in Chrome*/
      &:-webkit-autofill,
      &:-webkit-autofill:hover,
      &:-webkit-autofill:focus {
        border: 1px solid rgb(191, 49, 12);
      }
    `}
`
const LastNameErrorMessage = styled.div`
  margin-top: 10px;
  background-color: #ffe0e0;
  color: #560000;
  height: 25px;
  width: 300px;
  font-size: 15px;
  line-height: 25px;
  grid-column: 2/2;
  grid-row: 3/3;
`
const EmailInputContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: left;
`
const EmailSpan = styled.span`
  padding-bottom: 5px;
`
const EmailInput = styled.input`
  font-size: 20px;
  height: 30px;
  width: 300px;
  border: 1px solid lightgrey;
  border-radius: 3px;

  &:focus,
  &:active {
    box-shadow: rgb(210, 213, 217) 0px 0px 2px 1px,
      rgb(227, 230, 232) 0px 0px 0px 3px;
    border: 1px solid rgb(26, 33, 43);
    outline: none;
  }
  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus {
    background-color: white;
    border: 1px solid lightgrey;
    box-shadow: 0 0 0px 1000px #fff inset;
    -webkit-box-shadow: 0 0 0px 1000px #fff inset;
    transition: background-color 5000s ease-in-out 0s;
    -webkit-text-fill-color: black;
  }

  ${({ valid }) =>
    valid &&
    `
      border: 1px solid rgb(0, 156, 38);

      &:focus,
      &:active {
        border: 1px solid rgb(0, 156, 38);
        box-shadow: rgb(106, 237, 97) 0px 0px 2px 1px,
          rgb(177, 247, 160) 0px 0px 0px 3px;
        outline: none;
      }

      /* Autocomplete styles in Chrome*/
      &:-webkit-autofill,
      &:-webkit-autofill:hover,
      &:-webkit-autofill:focus {
        border: 1px solid rgb(0, 156, 38);
      }
    `}
  ${({ error }) =>
    error &&
    `
      border: 1px solid rgb(191, 49, 12);
      outline: none;

      &:focus,
      &:active {
        box-shadow: rgb(244, 129, 116) 0px 0px 2px 1px,
          rgb(251, 178, 174) 0px 0px 0px 3px;
        border: 1px solid rgb(191, 49, 12);
        outline: none;
      }

      /* Autocomplete styles in Chrome*/
      &:-webkit-autofill,
      &:-webkit-autofill:hover,
      &:-webkit-autofill:focus {
        border: 1px solid rgb(191, 49, 12);
      }
    `}
`
const PasswordInputContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: left;
`
const PasswordSpan = styled.span`
  padding-bottom: 5px;
`
const PasswordInput = styled.input`
  font-size: 20px;
  height: 30px;
  width: 300px;
  border: 1px solid lightgrey;
  border-radius: 3px;

  &:focus,
  &:active {
    box-shadow: rgb(210, 213, 217) 0px 0px 2px 1px,
      rgb(227, 230, 232) 0px 0px 0px 3px;
    border: 1px solid rgb(26, 33, 43);
    outline: none;
  }
  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus {
    background-color: white;
    border: 1px solid lightgrey;
    box-shadow: 0 0 0px 1000px #fff inset;
    -webkit-box-shadow: 0 0 0px 1000px #fff inset;
    transition: background-color 5000s ease-in-out 0s;
    -webkit-text-fill-color: black;
  }

  ${({ valid }) =>
    valid &&
    `
    border: 1px solid rgb(0, 156, 38);

    &:focus,
    &:active {
      border: 1px solid rgb(0, 156, 38);
      box-shadow: rgb(106, 237, 97) 0px 0px 2px 1px,
        rgb(177, 247, 160) 0px 0px 0px 3px;
      outline: none;
    }

    /* Autocomplete styles in Chrome*/
    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus {
      border: 1px solid rgb(0, 156, 38);
    }
  `}
  ${({ error }) =>
    error &&
    `
    border: 1px solid rgb(191, 49, 12);
    outline: none;

    &:focus,
    &:active {
      box-shadow: rgb(244, 129, 116) 0px 0px 2px 1px,
        rgb(251, 178, 174) 0px 0px 0px 3px;
      border: 1px solid rgb(191, 49, 12);
      outline: none;
    }

    /* Autocomplete styles in Chrome*/
    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus {
      border: 1px solid rgb(191, 49, 12);
    }
  `}
`
const ConfirmPasswordInputContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: left;
`
const ConfirmPasswordSpan = styled.span`
  padding-bottom: 5px;
`
const ConfirmPasswordInput = styled.input`
  font-size: 20px;
  height: 30px;
  width: 300px;
  border: 1px solid lightgrey;
  border-radius: 3px;

  &:focus,
  &:active {
    box-shadow: rgb(210, 213, 217) 0px 0px 2px 1px,
      rgb(227, 230, 232) 0px 0px 0px 3px;
    border: 1px solid rgb(26, 33, 43);
    outline: none;
  }
  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus {
    background-color: white;
    border: 1px solid lightgrey;
    box-shadow: 0 0 0px 1000px #fff inset;
    -webkit-box-shadow: 0 0 0px 1000px #fff inset;
    transition: background-color 5000s ease-in-out 0s;
    -webkit-text-fill-color: black;
  }

  ${({ valid }) =>
    valid &&
    `
  border: 1px solid rgb(0, 156, 38);

  &:focus,
  &:active {
    border: 1px solid rgb(0, 156, 38);
    box-shadow: rgb(106, 237, 97) 0px 0px 2px 1px,
      rgb(177, 247, 160) 0px 0px 0px 3px;
    outline: none;
  }

  /* Autocomplete styles in Chrome*/
  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus {
    border: 1px solid rgb(0, 156, 38);
  }
`}
  ${({ error }) =>
    error &&
    `
  border: 1px solid rgb(191, 49, 12);
  outline: none;

  &:focus,
  &:active {
    box-shadow: rgb(244, 129, 116) 0px 0px 2px 1px,
      rgb(251, 178, 174) 0px 0px 0px 3px;
    border: 1px solid rgb(191, 49, 12);
    outline: none;
  }

  /* Autocomplete styles in Chrome*/
  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus {
    border: 1px solid rgb(191, 49, 12);
  }
`}
`
const SignUpButton = styled.button`
  color: black;
  text-decoration: none;
  font-size: 20px;
  line-height: 16px;
  min-height: 40px;
  border: 1px solid rgba(0, 0, 0, 0.3);
  border-radius: 6px;
  box-shadow: rgba(0, 0, 0, 0.1) 1px 2px 4px;
  box-sizing: border-box;
  cursor: pointer;
  display: inline-block;
  font-family: roboto;
  &:hover {
    border-radius: 6px;
    color: white;
    background: linear-gradient(145deg, #9cc5ff, #2b81f7, #2b81f7);
    box-shadow: 0 1px 8px rgba(0, 0, 0, 0.3);
    transition: box-shadow 0.4s ease;
  }
`

const USER_REGEX = /^[a-zA-Z][a-zA-Z0-9-_]{5,23}$/
const NAME_REGEX = /^[a-zA-Z]{1,24}$/
const PWD_REGEX = /^(?=.*[a-z]).{8,24}$/
const EML_REGEX =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

function SignUp() {
  const userRef = useRef()
  const navigate = useNavigate()
  const [errorMessage, setErrorMessage] = useState('')
  const [showPassword, setShowPassword] = useState(false)

  useEffect(() => {
    userRef.current.focus()
  }, [])

  const handleSubmit = async (e) => {
    try {
      const response = await axios.post('/auth/signup', {
        user_username: e.username,
        user_firstname: e.firstName,
        user_lastname: e.lastName,
        user_email: e.email,
        user_password: e.password,
      })
      //console.log(response.data)
      navigate('../login')
    } catch (err) {
      // console.log(err.response.data.message)
      err.response.data.errorMessage.includes('usertable_un')
        ? setErrorMessage('Username Already in Use')
        : setErrorMessage('') ||
          err.response.data.errorMessage.includes('user_email')
        ? setErrorMessage('Email Already in Use')
        : setErrorMessage('There was an issue signing up')
      // console.log(errorMessage)
    }
    //if (value.email == '' && value.password == '') return

    //dispatch(loginUser(value))
  }
  return (
    <>
      <FormContainer>
        <Formik
          initialValues={{
            username: '',
            firstName: '',
            lastName: '',
            email: '',
            password: '',
            confirmPassword: '',
          }}
          validate={(values) => {
            let errors = {}
            if (!values.username) {
              errors.username = 'Username is Required'
            } else if (values.username.length < 6) {
              errors.username = 'Needs to be longer than 7 characters'
            } else if (!USER_REGEX.test(values.username)) {
              errors.username = 'Invalid character used'
            }
            if (!values.firstName) {
              errors.firstName = 'First Name is Required'
            } else if (values.firstName.length < 1) {
              errors.firstName = 'Needs to be longer than 1 character'
            } else if (!NAME_REGEX.test(values.firstName)) {
              errors.firstName = 'Invalid character used'
            }
            if (!values.lastName) {
              errors.lastName = 'Last Name is Required'
            } else if (values.lastName.length < 1) {
              errors.lastName = 'Needs to be longer than 1 character'
            } else if (!NAME_REGEX.test(values.lastName)) {
              errors.lastName = 'Invalid character used'
            }
            if (!values.email) {
              errors.email = 'Email is Required'
            } else if (!EML_REGEX.test(values.email)) {
              errors.email = 'Needs a Valid Email'
            }
            if (!values.password) {
              errors.password = 'Password is Required'
            } else if (values.password.length < 9) {
              errors.password = 'Needs to be longer than 9 characters'
            } else if (!PWD_REGEX.test(values.password)) {
              errors.password =
                'Password needs to contain at least 1 Capital letter, number, and special character'
            }
            if (!values.confirmPassword) {
              errors.confirmPassword = 'Please Confirm Password'
            } else if (values.password !== values.confirmPassword) {
              errors.confirmPassword = 'Passwords do not match'
            }
            return errors
          }}
          onSubmit={handleSubmit}
        >
          {({ errors, touched, handleChange, handleBlur, handleSubmit }) => (
            <Form onSubmit={handleSubmit}>
              {errorMessage && (
                <SignUpErrorMessage>{errorMessage}</SignUpErrorMessage>
              )}
              <SignUpHeader>Sign Up</SignUpHeader>
              <UserInputContainer>
                <UserSpan>Username</UserSpan>

                <UserInput
                  name='username'
                  ref={userRef}
                  placeholder='Enter Username'
                  onChange={handleChange}
                  onBlur={handleBlur}
                  autoComplete='off'
                  required
                  valid={touched.username && !errors.username}
                  error={touched.username && errors.username}
                />
                {touched.username && errors.username && (
                  <SignUpErrorMessage>{errors.username}</SignUpErrorMessage>
                )}
              </UserInputContainer>
              <NameInputContainer>
                <FirstNameSpan>First Name</FirstNameSpan>
                <FirstNameInput
                  name='firstName'
                  placeholder='Enter First Name'
                  onChange={handleChange}
                  onBlur={handleBlur}
                  autoComplete='off'
                  autoCapitalize='characters'
                  required
                  valid={touched.firstName && !errors.firstName}
                  error={touched.firstName && errors.firstName}
                />

                <LastNameSpan>Last Name</LastNameSpan>
                <LastNameInput
                  name='lastName'
                  placeholder='Enter Last Name'
                  onChange={handleChange}
                  onBlur={handleBlur}
                  autoComplete='off'
                  autoCapitalize='characters'
                  required
                  valid={touched.lastName && !errors.lastName}
                  error={touched.lastName && errors.lastName}
                />
                {touched.firstName && errors.firstName && (
                  <FirstNameErrorMessage>
                    {errors.firstName}
                  </FirstNameErrorMessage>
                )}
                {touched.lastName && errors.lastName && (
                  <LastNameErrorMessage>{errors.lastName}</LastNameErrorMessage>
                )}
              </NameInputContainer>
              <EmailInputContainer>
                <EmailSpan>Email</EmailSpan>
                <EmailInput
                  name='email'
                  placeholder='Enter Email'
                  onChange={handleChange}
                  onBlur={handleBlur}
                  autoComplete='off'
                  required
                  valid={touched.email && !errors.email}
                  error={touched.email && errors.email}
                />
                {touched.email && errors.email && (
                  <SignUpErrorMessage>{errors.email}</SignUpErrorMessage>
                )}
              </EmailInputContainer>
              <PasswordInputContainer>
                <PasswordSpan>Password</PasswordSpan>
                <PasswordInput
                  name='password'
                  type='password'
                  placeholder='Enter Password'
                  onChange={handleChange}
                  onBlur={handleBlur}
                  Required
                  valid={touched.password && !errors.password}
                  error={touched.password && errors.password}
                />
                {touched.password && errors.password && (
                  <SignUpErrorMessage>{errors.password}</SignUpErrorMessage>
                )}
              </PasswordInputContainer>
              {/* <AiOutlineCheck onClick={setShowPassword(!showPassword)} />
              {console.log(showPassword)} */}
              <ConfirmPasswordInputContainer>
                <ConfirmPasswordSpan>Confirm Password</ConfirmPasswordSpan>
                <ConfirmPasswordInput
                  name='confirmPassword'
                  type='password'
                  placeholder='Confirm Password'
                  onChange={handleChange}
                  onBlur={handleBlur}
                  Required
                  valid={touched.confirmPassword && !errors.confirmPassword}
                  error={touched.confirmPassword && errors.confirmPassword}
                />
                {touched.confirmPassword && errors.confirmPassword && (
                  <SignUpErrorMessage>
                    {errors.confirmPassword}
                  </SignUpErrorMessage>
                )}
              </ConfirmPasswordInputContainer>

              <SignUpButton type='submit'>Sign Up</SignUpButton>
            </Form>
          )}
        </Formik>
      </FormContainer>
    </>
  )
}

export default SignUp
