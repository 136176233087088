import './ReportModal.css'
import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { Modal, Form } from 'react-bootstrap'
import { Formik, Field } from 'formik'
import useAuth from '../hooks/useAuth'
import { axiosPrivate } from '../apis/axios'

// Username, Name of file, up

const FormContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-height: 20vh;
`
const FormRadioDiv = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`
const FormLabel = styled.label`
  display: flex;
  gap: 10px;
`
// const Form = styled.form`
//   display: flex;
//   flex-direction: column;
//   justify-content: center;
//   align-items: center;
//   width: 90%;
// `
const UploadHeader = styled.div`
  height: 40px;
  font-size: 30px;
`
const UserInputContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: left;
`
const UserSpan = styled.span`
  padding-bottom: 10px;
`
const UserInput = styled.input`
  font-size: 20px;
`
const ButtonDiv = styled.div`
  display: flex;
  justify-content: center;
`
const Button = styled.button`
  appearance: none;
  border-radius: 4px;
  height: 30px;
  cursor: pointer;
  font-size: 15px;
  padding: 0px 15px;
  border: none;
  background: #0084ce;
  color: white;
`

const HomeButtonDiv = styled(Link)`
  font-size: 10px;
`

function ReportModal(props) {
  const { auth } = useAuth()
  const handleSubmit = async (e) => {
    // e.preventDefault()
    // console.log(e)
    try {
      const comment = 'qNum' + props.d3qnum + ':' + e.picked + ', ' + e.comment
      const resp = await axiosPrivate.post(
        '/comment',
        { uuid: auth.UUID, comment: comment },
        { headers: { authorization: 'Bearer ' + auth.accessToken } }
      )
    } catch (err) {
      console.log(err.response.data.message)
    }
    props.onHide()
  }

  return (
    <Modal
      // className='reportModal'
      {...props}
      aria-labelledby='Report-Question-modal'
      dialogClassName='main-Modal'
      backdropClassName='ReportBackdrop'
      contentClassName='ReportContent'
      // style={{ 'background-color': 'blue' }}
    >
      <Modal.Header className='reportHeader' closeButton>
        <Modal.Title id='Report-Question-modal'>
          Report this Question?
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>What would you like to report?</p>
        <Formik
          initialValues={{
            picked: '',
            comment: '',
          }}
          onSubmit={handleSubmit}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
          }) => (
            <Form onSubmit={handleSubmit}>
              <FormRadioDiv key={'default-radio'} className='mb-3'>
                <FormLabel>
                  <Field type='radio' name='picked' value='WrongAnswer' />
                  Answer is Wrong
                </FormLabel>
                <FormLabel>
                  <Field type='radio' name='picked' value='DifficultQuestion' />
                  Question was confusing
                </FormLabel>
              </FormRadioDiv>
              <Form.Group
                className='mb-3'
                controlId='exampleForm.ControlTextarea1'
              >
                <Form.Control
                  as='textarea'
                  rows={3}
                  placeholder='Explain in greater details'
                  name='comment'
                  // value='comment'
                  onChange={handleChange}
                />
              </Form.Group>
              <Modal.Footer>
                <Button type='submit'> Submit </Button>
                <Button type='button' onClick={props.onHide}>
                  Cancel
                </Button>
              </Modal.Footer>
            </Form>
          )}
        </Formik>
      </Modal.Body>
    </Modal>
  )
}

export default ReportModal
