import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { Modal } from 'react-bootstrap'
import useAuth from '../hooks/useAuth'
import { IoInformationCircleSharp } from 'react-icons/io5'

// Username, Name of file, up

const Button = styled.button`
  appearance: none;
  border-radius: 4px;
  height: 30px;
  cursor: pointer;
  font-size: 15px;
  padding: 0px 15px;
  border: none;
  background: #0084ce;
  color: white;
`

const HomeButtonDiv = styled(Link)`
  font-size: 10px;
`

function ReadingDirectionModal(props) {
  return (
    <Modal {...props} aria-labelledby='finshed-exam-modal' centered>
      <Modal.Header closeButton>
        <Modal.Title id='finshed-exam-modal'>Reading Directions</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>
          The question in this section address a number of important reading and
          writing skills. Each question includes one or more passages, which may
          include a table or graph. Read each passage and question carefully,
          and then choose the best answer to the question based on the
          passage(s).
        </p>
        <p>
          All questions in the section are multiple-choice with four answer
          choices. Each question has a single best answer.
        </p>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={props.onHide}>Close</Button>
      </Modal.Footer>
    </Modal>
  )
}

export default ReadingDirectionModal
