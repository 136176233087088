import React from 'react'
import styled from 'styled-components'
import UserDropdownMenu from './UserDropdownMenu'
import EliteLogo from '../Images/EliteLogo.png'
import ErrorReportButton from './ErrorReportButton'

import NavBar from './NavBar'
import { useNavigate } from 'react-router-dom'
const TopBarContainer = styled.div`
  margin: 0;
  display: flex;
  height: 8vh;
  background: #e6f8fc;
  justify-content: flex-start;
  align-items: center;
  padding-left: 20px;
  gap: 150px;
  // border-bottom: 1px #8e9193 solid;
`

const Logo = styled.img`
  width: 10vw;
`
const UserContainer = styled.div`
  position: absolute;
  right: 40px;
`
const ErrorContainer = styled.div`
  position: absolute;
  right: 10px;
`

function TopBar({ errorTelemetry }) {
  const navigate = useNavigate()
  if (errorTelemetry) {
    errorTelemetry.button_location = 'TopBar'
    errorTelemetry.url = window.location.href
  } else {
    let errorTelemetry = {}
    errorTelemetry.button_location = 'TopBar'
    errorTelemetry.url = window.location.href
  }
  return (
    <TopBarContainer>
      <Logo onClick={() => navigate('/')} src={EliteLogo}></Logo>
      <UserContainer>
        <UserDropdownMenu />
      </UserContainer>
      {window.location.pathname != '/createticket' ? (
        <ErrorContainer>
          <ErrorReportButton id='Topbar' telemetry={errorTelemetry} />
        </ErrorContainer>
      ) : (
        <></>
      )}
    </TopBarContainer>
  )
}
export default TopBar
