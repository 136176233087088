import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { Modal } from 'react-bootstrap'
import useAuth from '../hooks/useAuth'
import { axiosPrivate } from '../apis/axios'

// Username, Name of file, up

const FormContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-height: 20vh;
`
const Form = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 90%;
`
const UploadHeader = styled.div`
  height: 40px;
  font-size: 30px;
`
const UserInputContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: left;
`
const UserSpan = styled.span`
  padding-bottom: 10px;
`
const UserInput = styled.input`
  font-size: 20px;
`
const ButtonDiv = styled.div`
  display: flex;
  justify-content: center;
`
const Button = styled.button`
  appearance: none;
  border-radius: 4px;
  height: 30px;
  cursor: pointer;
  font-size: 15px;
  padding: 0px 15px;
  border: none;
  background: #0084ce;
  color: white;
`

const HomeButtonDiv = styled(Link)`
  font-size: 10px;
`

function DeleteExamModal(props) {
  const { auth } = useAuth()
  const deleteExam = async (test) => {
    try {
      // const resp = await axiosPrivate.delete('/exam/delete/', {
      //   data: { uuid: test.user_uuid, start_date: test.start_date },
      //   headers: { authorization: 'Bearer ' + auth.accessToken },
      // })
      // console.log('deleting exam', test.exam_id)
      const resp = await axiosPrivate.delete('/deletedemoexam', {
        headers: { authorization: 'Bearer ' + auth.accessToken },
        params: { exam_id: test.exam_id },
      })
    } catch (err) {
      console.log(err)
    }
    props.onHide()
  }
  const closeTrigger = () => {}
  return (
    <Modal {...props} aria-labelledby='finshed-exam-modal' centered>
      <Modal.Header closeButton>
        <Modal.Title id='finshed-exam-modal'>
          Do you want to delete this exam?
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>You won't be able to recover this exam after.</p>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={() => deleteExam(props.test)}> Delete </Button>
        <Button onClick={props.onHide}>Close</Button>
      </Modal.Footer>
    </Modal>
  )
  // const [name, setName] = useState('')
  // const { auth } = useAuth()
  // const handleSubmit = (e) => {
  //   e.preventDefault()
  //   if (name === '') return
  //   try {
  //     console.log(auth.username)
  //     axiosPrivate.post(
  //       '/data/',
  //       {
  //         name: name,
  //         username: auth.username,
  //       },
  //       { headers: { authorization: 'Bearer ' + auth.accessToken } }
  //     )
  //     //upload to blob
  //   } catch (err) {
  //     console.log(err)
  //   }
  //   setShowModal(false)
  // }
  // return (
  //   <>
  //     <FormContainer>
  //       <Form onSubmit={handleSubmit}>
  //         <UploadHeader>Upload</UploadHeader>
  //         <UserInputContainer>
  //           <UserSpan>Name</UserSpan>
  //           <UserInput
  //             name='name'
  //             placeholder='Enter Name'
  //             onChange={(e) => setName(e.target.value)}
  //             autoComplete='off'
  //             Required
  //           />
  //         </UserInputContainer>
  //       </Form>
  //     </FormContainer>
  //     <ButtonDiv>
  //       <Button onClick={handleSubmit} type='submit'>
  //         Upload
  //       </Button>
  //       {/* <HomeButtonDiv to={'/home'}>Back</HomeButtonDiv> */}
  //     </ButtonDiv>
  //   </>
  // )
}

export default DeleteExamModal
