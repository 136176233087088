import React from 'react'
import useRefreshToken from '../hooks/useRefreshToken'
import useAuth from '../hooks/useAuth'
const { useState, useEffect } = require('react')
const { Outlet } = require('react-router-dom')

const PersistLogin = () => {
  const [isLoading, setIsLoading] = useState(true)
  const refresh = useRefreshToken()
  const { auth } = useAuth()

  useEffect(() => {
    const verifyRefreshToken = async () => {
      try {
        await refresh()
      } catch (err) {
        console.log(err)
      } finally {
        setIsLoading(false)
      }
    }
    !auth.accessToken ? verifyRefreshToken() : setIsLoading(false)
  }, [])

  // useEffect(() => {
  //   console.log('isLoading:', isLoading)
  //   console.log('at:', auth?.accessToken)
  // }, [isLoading])

  return <>{isLoading ? <p>Loading...</p> : <Outlet />}</>
}

export default PersistLogin
