import React, { useState, useEffect } from 'react'
import useAuth from '../hooks/useAuth'
import { axiosPrivate } from '../apis/axios'
import TopBar from '../components/Topbar'
import 'react-toastify/dist/ReactToastify.css'
import styled from 'styled-components'
import { useLocation, useNavigate } from 'react-router-dom'
import { ToastContainer, toast } from 'react-toastify'
import { Button } from 'react-bootstrap'
import MySunEditorTicket from '../components/SunEditorTicket'
const CreateTicketContainer = styled.div`
  background-image: linear-gradient(
      rgba(15, 135, 162, 0.44),
      rgba(15, 135, 162, 0.44)
    )
    
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  height: 90vh;
  overflow:auto;
`
const SidebarContainer = styled.div`
  display: flex;
  height: 100%;
`
const TicketDiv = styled.div`
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  width: 70vw;
  height: 100%;
  padding-top: 20px;
`
const Form = styled.form`
  background: #eee;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  padding: 10px;
`
const UserEmailContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: left;
`
const UserContactContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: left;
`
const UserCheckboxInput = styled.input`
  height: 20px;
  width: 20px;
`
const UserInput = styled.input`
  font-size: calc(0.6em + 0.5vw);
  height: 30px;
  width: 300px;
  border: 1px solid lightgrey;
  border-radius: 10px;
  padding-left: 20px;
  &:focus,
  &:active {
    box-shadow: rgb(210, 213, 217) 0px 0px 2px 1px,
      rgb(227, 230, 232) 0px 0px 0px 3px;
    border: 1px solid rgb(26, 33, 43);
    outline: none;
  }
  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus {
    background-color: white;
    border: 1px solid lightgrey;
    box-shadow: 0 0 0px 1000px #fff inset;
    -webkit-box-shadow: 0 0 0px 1000px #fff inset;
    transition: background-color 5000s ease-in-out 0s;
    -webkit-text-fill-color: black;
  }

  ${({ valid }) =>
    valid &&
    `
    border: 1px solid rgb(0, 156, 38);

    &:focus,
    &:active {
      border: 1px solid rgb(0, 156, 38);
      box-shadow: rgb(106, 237, 97) 0px 0px 2px 1px,
        rgb(177, 247, 160) 0px 0px 0px 3px;
      outline: none;
    }

    /* Autocomplete styles in Chrome*/
    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus {
      border: 1px solid rgb(0, 156, 38);
    }
  `}
  ${({ error }) =>
    error &&
    `
    border: 1px solid rgb(191, 49, 12);
    outline: none;

    &:focus,
    &:active {
      box-shadow: rgb(244, 129, 116) 0px 0px 2px 1px,
        rgb(251, 178, 174) 0px 0px 0px 3px;
      border: 1px solid rgb(191, 49, 12);
      outline: none;
    }

    /* Autocomplete styles in Chrome*/
    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus {
      border: 1px solid rgb(191, 49, 12);
    }
  `}
`
const ButtonDiv = styled.div`
  display: flex;
  justify-content: center;
  gap: 20px;
  margin-top: 20px;
`
const SubmitButton = styled(Button)`
  color: white;
`

const CreateTicketPage = () => {
  //const [videoData, setVideoData] = useState()
  const { auth } = useAuth()
  const location = useLocation()
  const [report, setReport] = useState('')
  const [reportSteps, setReportSteps] = useState('')
  const [email, setEmail] = useState('')
  const [sendEmailCheck, setSendEmailCheck] = useState(false)
  const navigate = useNavigate()
  console.log('rs', reportSteps)
  // console.log('telemetry', location.state.telemetry)
  const submitTicket = async () => {
    let telemetry = {}
    if (report == '' || report == '<p><br></p>') {
      toast.error('Please explain the issue before submitting')
    } else {
      if (location.state?.telemetry) {
        telemetry = location.state.telemetry
      }
      console.log('creating ticket', report, telemetry)
      try {
        telemetry.userAgent = navigator.userAgent
        const resp = await axiosPrivate.post('/dev/tickets/create', {
          telemetry: telemetry,
          report:
            reportSteps != ''
              ? report +
                '\n\n<b>Steps to Reproduce:</b>' +
                '<br/>' +
                reportSteps
              : report,
          contact_email: email,
          ok_to_contact: sendEmailCheck,
        })
        toast.success('Ticket created. Thank you for your feedback!')
      } catch (err) {
        toast.error('Issue creating ticket, please try again')
      }
    }
  }

  return (
    <>
      <ToastContainer
        position='top-center'
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme='light'
      />
      <TopBar color={'#9AB5CE'} titleName={'Create Ticket'} />
      <CreateTicketContainer>
        <TicketDiv>
          <div>What is the Problem?</div>
          <MySunEditorTicket
            height='10vh'
            value={report}
            setValue={setReport}
            image
            alwaysShowToolbar={true}
            placeholder='What is the Problem?*'
          />
          <br />
          <div>
            Please write down the steps taken to reach this error.
            <br /> Be as specific as possible.
          </div>
          <MySunEditorTicket
            height='20vh'
            value={reportSteps}
            setValue={setReportSteps}
            image
            alwaysShowToolbar={true}
            placeholder='Please write the steps take to reach this error. '
          />

          <Form onSubmit={submitTicket} autoComplete='off'>
            <UserEmailContainer>
              <div>Preferred Email</div>
              {/* <UserSpan>Username</UserSpan> */}
              <UserInput
                name='Email'
                placeholder='Email'
                type='email'
                autocomplete='off'
                // autofill='off'
                onChange={(e) => setEmail(e.target.value)}
                value={email}
                // valid={errorMessage == ''}
              />
            </UserEmailContainer>
            <UserContactContainer>
              <div>
                It is okay to be contacted about this issue to my email.
              </div>

              {/* <UserSpan>Username</UserSpan> */}
              <UserCheckboxInput
                name='SendEmailCheckbox'
                placeholder='EmailCheckbox'
                type='checkbox'
                autoComplete='off'
                defaultChecked={sendEmailCheck}
                onChange={() => setSendEmailCheck(!sendEmailCheck)}
                value={email}
                // valid={errorMessage == ''}
              />
            </UserContactContainer>
          </Form>
          <ButtonDiv>
            <Button style={{ marginTop: 20 }} onClick={() => navigate(-1)}>
              Back
            </Button>
            <Button style={{ marginTop: 20 }} onClick={() => submitTicket()}>
              Submit Report
            </Button>
          </ButtonDiv>
        </TicketDiv>
      </CreateTicketContainer>
    </>
  )
}

export default CreateTicketPage
