import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { MathJax } from 'better-react-mathjax'
import parse2 from './CustomLatexParser'

const ChoicesContainer = styled.div`
  // border-top: 1px solid #ccc;
  width: 40vw;
  margin: 0 auto;
`
const WithCrossContainer = styled.div`
  display: flex;
  align-items: center;
`
const ChoiceSection = styled.div`
  width: 40vw;
  margin: 2px;
  cursor: pointer;
  display: flex;
  gap: 15px;
  align-items: center;
  justify-content: flex-start;

  border-bottom: 1px solid #ccc;
  padding: 20px 16px 20px 16px;
  &:hover {
    background: #d0e6f4;
    outline: 2px solid #1586ca;
  }
  ${(props) =>
    props.correct == true
      ? `
              background: #d0e6f4;`
      : ` background:none;`}
`
const ChoiceSectionA = styled(ChoiceSection)`
  border-top: 1px solid #ccc;
`
const QChoicesContainer = styled.div`
  padding: 20px 0px 20px 0px;
  ${(props) =>
    props.cross
      ? ` text-decoration: line-through; color:#ccc; `
      : `text-decoration: none;`}}
`
const ChoiceCross = styled.div`
  // position: absolute;
  right: 0;
  // top: 0px;
  transition: ease-in, 0.5s;
`
const QChoices = styled.div`
  font-height: 15px;
  font-size: 15px;
`
const QExplain = styled.div``
const AnsButton = styled.div`
  min-width: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #1586ca;
  border: 2px solid #1586ca;
  border-radius: 50%;
  height: 30px;
  width: 30px;

  ${(props) =>
    // console.log(props)
    props.selected && props.correct == true
      ? `
    color: white;
    background: #1586ca;`
      : null}
  ${(props) =>
    props.correct != props.selected
      ? `color:white; 
              background:#1586ca;`
      : null}
  ${(props) =>
    props.selected && props.correct == false
      ? `color:white; 
      background:#EB5264;`
      : null}
  ${(props) =>
    props.omitted && props.correct
      ? `color:white; 
    background:#EB5264;`
      : null}
`

function MathReviewRadioButton({ qChoices, optionsChosen, correctAnswer }) {
  // useEffect(() => {
  //   if (userTestData != null || userTestData != '') {
  //     setChosen(userTestData)
  //   }
  // }, [userTestData])

  // const selectOption = (e) => {
  //   if (optionsChosen == e) {
  //     userTestData = ''
  //     setChosen('')
  //   } else {
  //     setChosen(e)
  //     userTestData = e
  //   }

  //   console.log(e)
  // }
  return (
    <ChoicesContainer>
      <WithCrossContainer>
        <ChoiceSection
          selected={optionsChosen == 'A'}
          correct={correctAnswer == 'A'}
          omitted={optionsChosen == ''}
        >
          <AnsButton
            selected={optionsChosen == 'A'}
            correct={correctAnswer == 'A'}
            omitted={optionsChosen == ''}
          >
            A
          </AnsButton>
          <QChoicesContainer>
            <QChoices>
              <MathJax key={qChoices[0]}>{parse2(qChoices[0], true)}</MathJax>{' '}
            </QChoices>

            <QExplain></QExplain>
          </QChoicesContainer>
        </ChoiceSection>
      </WithCrossContainer>
      <WithCrossContainer>
        <ChoiceSection
          selected={optionsChosen == 'B'}
          correct={correctAnswer == 'B'}
          omitted={optionsChosen == ''}
        >
          <AnsButton
            selected={optionsChosen == 'B'}
            correct={correctAnswer == 'B'}
            omitted={optionsChosen == ''}
          >
            B
          </AnsButton>
          <QChoicesContainer>
            <QChoices>
              <MathJax key={qChoices[1]}>{parse2(qChoices[1], true)}</MathJax>{' '}
            </QChoices>
            <QExplain></QExplain>
          </QChoicesContainer>
        </ChoiceSection>
      </WithCrossContainer>
      <WithCrossContainer>
        <ChoiceSection
          selected={optionsChosen == 'C'}
          correct={correctAnswer == 'C'}
          omitted={optionsChosen == ''}
        >
          <AnsButton
            selected={optionsChosen == 'C'}
            correct={correctAnswer == 'C'}
            omitted={optionsChosen == ''}
          >
            C
          </AnsButton>
          <QChoicesContainer>
            <QChoices>
              <MathJax key={qChoices[2]}>{parse2(qChoices[2], true)}</MathJax>
            </QChoices>
            <QExplain></QExplain>
          </QChoicesContainer>
        </ChoiceSection>
      </WithCrossContainer>
      <WithCrossContainer>
        <ChoiceSection
          selected={optionsChosen == 'D'}
          correct={correctAnswer == 'D'}
          omitted={optionsChosen == ''}
        >
          <AnsButton
            selected={optionsChosen == 'D'}
            correct={correctAnswer == 'D'}
            omitted={optionsChosen == ''}
          >
            D
          </AnsButton>
          <QChoicesContainer>
            <QChoices>
              <MathJax key={qChoices[3]}>{parse2(qChoices[3], true)}</MathJax>{' '}
            </QChoices>

            <QExplain></QExplain>
          </QChoicesContainer>
        </ChoiceSection>
      </WithCrossContainer>
    </ChoicesContainer>
  )
}

export default MathReviewRadioButton
