import React, { useState, useEffect } from 'react'
import { useTimer } from 'react-timer-hook'
import useAuth from '../hooks/useAuth'
import { useParams } from 'react-router-dom'
import { axiosPrivate } from '../apis/axios'

function UseTimer({ expiryTimestamp, nextPage, userTestData, startTimer }) {
  const { auth } = useAuth()
  const params = useParams()
  const saveData = async () => {
    try {
      const resp = await axiosPrivate.post('/saveresponses/', {
        headers: { authorization: 'Bearer ' + auth.accessToken },
        responses: userTestData.exam_json,
        public_id: params.id,
      })
      console.log('saved', resp)
    } catch (err) {
      console.log(err)
    }
  }
  useEffect(() => {
    const handleBeforeUnload = (event) => {
      event.preventDefault()
      // saveData({ offsetPointer: 0 })
      return (event.returnValue =
        'Closing will not save your current selected answer. Are you sure you want to close?')

      // Custom logic to handle the refresh
      // Display a confirmation message or perform necessary actions
    }
    window.addEventListener('beforeunload', handleBeforeUnload)

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload)
    }
  }, [])
  const {
    seconds: t_seconds,
    minutes: t_minutes,
    hours: t_hours,
    days: t_days,
    isRunning: t_isRunning,
    start: t_start,
    pause: t_pause,
    resume: t_resume,
    restart: t_restart,
  } = useTimer({
    autoStart: true,
    expiryTimestamp,
    onExpire: () => {
      userTestData.exam_json.finishbreak = true
      // try {
      // await saveData()
      startTimer()
      nextPage()
      console.log('expire')
      // } catch (err) {
      //   console.log(err)
      // }
    },
  })
  return (
    <div>
      {t_minutes}:{t_seconds > 9 ? t_seconds : '0' + t_seconds}
    </div>
  )
}

export default UseTimer
