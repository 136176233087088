import './ReviewModal.css'
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { Modal } from 'react-bootstrap'
import { IoInformationCircleSharp } from 'react-icons/io5'
import ReadingReviewRadioButton from '../components/ReadingReviewRadioButton'
import parse2 from '../components/CustomLatexParser'
import {
  MathJax,
  MathJaxBaseContext,
  MathJaxContext,
} from 'better-react-mathjax'
import MathReviewRadioButton from '../components/MathReviewRadioButton'
import useAuth from '../hooks/useAuth'

// Username, Name of file, up

const ExamContainer = styled.div`
  display: flex;
`
const MathContainer = styled(ExamContainer)`
  display: block;
`
const MathAnswerSolutionDiv = styled.div`
  display: flex;
  justify-content: center;
  padding: 20px;
  gap: 20px;
`
const MathAnswerDiv = styled.div`
  width: 40vw;
`
const MathSolutionDiv = styled.div`
  width: 40vw;
`
const SolutionSpan = styled.div`
  font-weight: 700;
  padding-bottom: 20px;
`

const ExamLeftContainer = styled.div`
  width: 50%;
  padding-right: 20px;
  overflow: auto;
`
const ExamRightContainer = styled.div`
  overflow: auto;
  width: 42vw;
  padding-left: 20px;
`
const HeaderTop = styled.div`
  padding: 10px 0 10px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
  border-bottom: 1px solid rgba(33, 36, 44, 0.16);
`
const HeaderLeft = styled.div`
  display: flex;
  gap: 20px;
  align-items: center;
`
const QNumBox = styled.div`
  background: #2d4a82;
  width: 30px;
  height: 30px;
  color: white;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
`
const HeaderBottom = styled.div``
const ReadingPromptContainer = styled.div`
  padding: 20px 0px 20px 0px;
`
const MathPromptContainer = styled(ReadingPromptContainer)`
  display: flex;
  justify-content: center;
  margin: 0 auto;
`
const DiagramContainer = styled.div`
  border-top: 1px solid #d2d2d2;
  display: flex;
  justify-content: center;
  padding: 20px 0px 20px 0px;
`
const Button = styled.button`
  appearance: none;
  border-radius: 4px;
  height: 30px;
  cursor: pointer;
  font-size: 15px;
  padding: 0px 15px;
  border: none;
  background: #0084ce;
  color: white;
`

const UserInput = styled.input`
  font-size: 20px;
  height: 30px;
  width: 300px;
  border: 1px solid lightgrey;
  border-radius: 3px;

  ${({ error }) =>
    error &&
    `
      border: 1px solid rgb(191, 49, 12);
      outline: none;

      &:focus,
      &:active {
        box-shadow: rgb(244, 129, 116) 0px 0px 2px 1px,
          rgb(251, 178, 174) 0px 0px 0px 3px;
        border: 1px solid rgb(191, 49, 12);
        outline: none;
      }

      /* Autocomplete styles in Chrome*/
      &:-webkit-autofill,
      &:-webkit-autofill:hover,
      &:-webkit-autofill:focus {
        border: 1px solid rgb(191, 49, 12);
      }
    `}
`
const CorrectAnswerDiv = styled.div``
const CorrectAnswerSpan = styled.div`
  font-weight: 700;
`
const TrueFalseContainer = styled.div`
display: flex;
justify-content: center;
`
const TrueFalseDiv = styled.div``

function ReviewModal(props) {
  const [secIncrease, setSecIncrease] = useState(1)
  const [numIncrease, setNumIncrease] = useState(1)
  const { auth } = useAuth()

  useEffect(() => {
    setNumIncrease(1)
  }, [props])

  console.log('props', props)
  const BackButton = () => {
    if (props.qNum + numIncrease == 1) {
      setNumIncrease(1)
      props.setQNum(
        Object.keys(props.examData.exam_json[props.sectionData + secIncrease])
          .length - 1
      )
      props.setSectionData(props.sectionData - 1)
    } else {
      setNumIncrease(numIncrease - 1)
    }
  }
  const NextButton = () => {
    if (props.sectionData + 1 > 5) {
      props.onHide()
    }

    if (
      props.qNum + numIncrease ==
      Object.keys(props.examData.exam_json[props.sectionData + secIncrease])
        .length
    ) {
      setNumIncrease(1)
      props.setQNum(0)
      props.setSectionData(props.sectionData + 1)
    } else {
      setNumIncrease(numIncrease + 1)
    }
  }

  if (props.sectionData < 2) {
    return (
      <Modal
        id='review-modal'
        {...props}
        aria-labelledby='Review-modal'
        centered
        dialogClassName='modal-90w'
      >
        <Modal.Header closeButton>
          <Modal.Title id='Review-modal'>
            Reading and Writing {props.sectionData < 1 ? '1' : '2'}: Question{' '}
            {props.qNum + numIncrease}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body id='Review-modal'>
          <ExamContainer>
            <ExamLeftContainer>
              {props.examGrade.test_data.sections[
                props.sectionData + secIncrease
              ][props.qNum + numIncrease].content.passage.map((data, idx) => {
                let str = ''

                if (idx > 0) {
                  str = '<br/><br/>'
                }
                if (
                  props.examGrade.test_data.sections[
                    props.sectionData + secIncrease
                  ][props.qNum + numIncrease].content.passage.length > 1
                ) {
                  str = str + '<b>Text ' + (idx + 1) + '</b> <br/><br/>'
                }
                if (data.introduction) {
                  str += data.introduction + '<br/><br/>'
                }
                if (data.body) {
                  str += data.body
                }
                return parse2(str)
              })}
            </ExamLeftContainer>
            <ExamRightContainer>
              <HeaderTop className='qheader'>
                <HeaderLeft>
                  <QNumBox>{props.qNum + numIncrease}</QNumBox>
                </HeaderLeft>
              </HeaderTop>
              <HeaderBottom>
                <ReadingPromptContainer>
                  {parse2(
                    props.examGrade.test_data.sections[
                      props.sectionData + secIncrease
                    ][props.qNum + numIncrease].content.prompt
                  )}
                </ReadingPromptContainer>
              </HeaderBottom>

              <ReadingReviewRadioButton
                qChoices={
                  props.examGrade.test_data.sections[
                    props.sectionData + secIncrease
                  ][props.qNum + numIncrease].content.choices
                }
                optionsChosen={
                  props.examGrade.grades.section_responses[
                    props.sectionData + secIncrease
                  ].responses[props.qNum + numIncrease - 1]
                }
                correctAnswer={
                  props.examGrade.test_data.sections[
                    props.sectionData + secIncrease
                  ][props.qNum + numIncrease].content.answer
                }
              />
            </ExamRightContainer>
          </ExamContainer>
        </Modal.Body>
        <Modal.Footer>
          {props.sectionData == 0 && props.qNum + numIncrease == 1 ? null : (
            <Button
              onClick={() => {
                BackButton()
              }}
            >
              Previous
            </Button>
          )}
          {props.section == 'Reading' &&
          props.sectionData == 1 &&
          props.qNum + numIncrease ==
            Object.keys(
              props.examData.exam_json[props.sectionData + secIncrease]
            ).length ? null : (
            <Button
              onClick={() => {
                NextButton()
              }}
            >
              Next
            </Button>
          )}
          <Button onClick={props.onHide}>Close</Button>
        </Modal.Footer>
      </Modal>
    )
  } else if (props.sectionData >= 2) {
    return (
      <Modal
        {...props}
        aria-labelledby='review-modal'
        centered
        id='review-modal'
      >
        <Modal.Header closeButton>
          <Modal.Title id='review-modal'>
            Math {props.sectionData < 3 ? '1' : '2'}: Question{' '}
            {props.qNum + numIncrease}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <MathContainer>
            <HeaderTop className='qheader'>
              <HeaderLeft>
                <QNumBox>{props.qNum + numIncrease}</QNumBox>
              </HeaderLeft>
            </HeaderTop>
            <DiagramContainer>
              {props.examGrade.test_data.sections[
                props.sectionData + secIncrease
              ][props.qNum + numIncrease].content.figure ? (
                props.examGrade.test_data.sections[
                  props.sectionData + secIncrease
                ][props.qNum + numIncrease].content.figure[0]?.body.includes(
                  'xml'
                ) ? (
                  <>
                    {parse2(
                      props.examGrade.test_data.sections[
                        props.sectionData + secIncrease
                      ][props.qNum + numIncrease].content.figure[0]?.body.slice(
                        props.examGrade.test_data.sections[
                          props.sectionData + secIncrease
                        ][
                          props.qNum + numIncrease
                        ].content.figure[0]?.body.indexOf('<svg')
                      ),
                      true
                    )}
                  </>
                ) : (
                  <MathJax hideUntilTypeset={'first'} inline dynamic>
                    {props.examGrade.test_data.sections[
                      props.sectionData + secIncrease
                    ][props.qNum + numIncrease].content.figure[0]?.body ? (
                      parse2(
                        props.examGrade.test_data.sections[
                          props.sectionData + secIncrease
                        ][props.qNum + numIncrease].content.figure[0]?.body,
                        true
                      )
                    ) : (
                      <></>
                    )}
                  </MathJax>
                )
              ) : null}
            </DiagramContainer>
            <HeaderBottom>
              <MathPromptContainer>
                <MathJax
                  hideUntilTypeset={'every'}
                  inline={true}
                  dynamic={true}
                  onTypeset={(e) => {
                    // console.log('typesetting')
                  }}
                  renderMode='post'
                  // id='passage'
                >
                  {parse2(
                    props.examGrade.test_data.sections[
                      props.sectionData + secIncrease
                    ][props.qNum + numIncrease].content.prompt
                  ,true
                  )}
                </MathJax>
              </MathPromptContainer>
              <TrueFalseContainer>
                {props.examGrade.test_data.sections[
                      props.sectionData + secIncrease
                    ][props.qNum + numIncrease].content.true_false ? (
                  <TrueFalseDiv>
                    <MathJax
                      hideUntilTypeset={'every'}
                      inline={true}
                      dynamic={true}
                      onTypeset={(e) => {
                        // console.log('typesetting')
                      }}
                      renderMode='post'
                      // id='passage'
                    >
                      <ol
                        style={{
                          listStyleType: 'upper-roman',
                        }}
                      >
                        {props.examGrade.test_data.sections[
                      props.sectionData + secIncrease
                    ][props.qNum + numIncrease].content.true_false.map((list, index) => {
                          return (
                            <li style={{ padding: '10px' }}>
                              {list.replace('{', '').replace('.}', '.')}
                            </li>
                          )
                        })}
                      </ol>
                      {/* parse(location.state.questions[pointer].content.prompt)} */}
                    </MathJax>
                  </TrueFalseDiv>
                ) : null}
              </TrueFalseContainer>
            </HeaderBottom>
            <MathAnswerSolutionDiv>
              <MathAnswerDiv>
                {props.examGrade?.test_data.sections[
                  props.sectionData + secIncrease
                ][props.qNum + numIncrease].content.choices &&
                props.examGrade?.test_data.sections[
                  props.sectionData + secIncrease
                ][props.qNum + numIncrease].content.choices?.length > 0 ? (
                  <MathReviewRadioButton
                    qChoices={
                      props.examGrade.test_data.sections[
                        props.sectionData + secIncrease
                      ][props.qNum + numIncrease].content.choices
                    }
                    optionsChosen={
                      props.examGrade.grades.section_responses[
                        props.sectionData + secIncrease
                      ].responses[props.qNum]
                    }
                    correctAnswer={
                      props.examGrade.test_data.sections[
                        props.sectionData + secIncrease
                      ][props.qNum + numIncrease].content.answer
                    }
                  />
                ) : (
                  <>
                    <MathJax>
                      {auth.user_type != 'staff_member' ? (
                        <UserInput
                          type='text'
                          readOnly
                          value={
                            props.examGrade?.grades.section_responses[
                              props.sectionData + secIncrease
                            ].responses[props.qNum - 1 + numIncrease]
                          }
                          error={
                            props.examGrade?.grades.section_responses[
                              props.sectionData + secIncrease
                            ].responses[props.qNum - 1 + numIncrease] !=
                            props.examGrade.test_data.sections[
                              props.sectionData + secIncrease
                            ][props.qNum + numIncrease].content.answer
                          }
                          // onChange={(e) => setOptionChosen(e.target.value)}
                        />
                      ) : null}
                      <CorrectAnswerDiv>
                        <CorrectAnswerSpan>Correct Answer:</CorrectAnswerSpan>
			                    <div>{parse2(
                          props.examGrade.test_data.sections[
                            props.sectionData + secIncrease
                          ][props.qNum + numIncrease].content.answer,
                          true
                        )}</div>
                      </CorrectAnswerDiv>
                    </MathJax>
                  </>
                  // <input
                  //   value={
                  //     props.examGrade?.grades.section_responses[
                  //       props.sectionData + secIncrease
                  //     ].responses[props.qNum - 1 + numIncrease]
                  //   }
                  // />
                )}
              </MathAnswerDiv>
              <MathSolutionDiv>
                <SolutionSpan>Solution:</SolutionSpan>
                <MathJax
                  key={'test2-' + numIncrease}
                  hideUntilTypeset={'every'}
                  inline={true}
                  dynamic={true}
                  onTypeset={(e) => {
                    // console.log('typesetting')
                  }}
                  renderMode='post'
                >
                  {/* <div key={'test-' + numIncrease}> */}
                  {props.examGrade.test_data.sections[
                    props.sectionData + secIncrease
                  ][props.qNum + numIncrease].content.solution ? (
                    parse2(
                      props.examGrade.test_data.sections[
                        props.sectionData + secIncrease
                      ][props.qNum + numIncrease].content?.solution,
                      true,
                      true
                    )
                  ) : (
                    <p>Solution not available.</p>
                  )}
                </MathJax>
              </MathSolutionDiv>
            </MathAnswerSolutionDiv>
          </MathContainer>
        </Modal.Body>
        <Modal.Footer>
          {props.qNum + numIncrease == 1 && props.sectionData == 2 ? null : (
            <Button
              onClick={() => {
                BackButton()
              }}
            >
              Previous
            </Button>
          )}
          {props.qNum + numIncrease ==
            Object.keys(
              props.examData.exam_json[props.sectionData + secIncrease]
            ).length && props.sectionData + 1 > 3 ? null : (
            <Button
              onClick={() => {
                NextButton()
              }}
              //   if (
              //     props.qNum + numIncrease ==
              //     Object.keys(
              //       props.examData.exam_json[props.sectionData + secIncrease]
              //     ).length
              //   ) {
              //     setNumIncrease(1)
              //     props.onHide()
              //   } else {
              //     setNumIncrease(numIncrease + 1)
              //   }
              // }}
            >
              Next
            </Button>
          )}
          <Button onClick={props.onHide}>Close</Button>
        </Modal.Footer>
      </Modal>
    )
  }
}
// }

export default ReviewModal
