import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { MathJax } from 'better-react-mathjax'
import aCross from '../Images/a-cross.png'
import bCross from '../Images/b-cross.png'
import cCross from '../Images/c-cross.png'
import dCross from '../Images/d-cross.png'
import parse2 from './CustomLatexParser'

const ChoicesContainer = styled.div`
  // border-top: 1px solid #ccc;
  width: 60vw;
`
const WithCrossContainer = styled.div`
  display: flex;
  align-items: center;
`
const ChoiceSection = styled.div`
  margin: 2px;
  cursor: pointer;
  display: flex;
  gap: 15px;
  align-items: center;
  justify-content: flex-start;

  border-bottom: 1px solid #ccc;
  padding: 20px 16px 20px 16px;
  &:hover {
    background: #d0e6f4;
    outline: 2px solid #1586ca;
  }
  ${(props) =>
    // console.log(props)
    props.cross
      ? `
    width: 55vw;`
      : `width:60vw;`}
  transition: ease-in, 0.5s;
`
const ChoiceSectionA = styled(ChoiceSection)`
  border-top: 1px solid #ccc;
`
const QChoicesContainer = styled.div`
  padding: 20px 0px 20px 0px;
  ${(props) =>
    props.cross
      ? ` text-decoration: line-through; color:#ccc; `
      : `text-decoration: none;`}}
`
const ChoiceCross = styled.div`
  // position: absolute;
  right: 0;
  // top: 0px;
  transition: ease-in, 0.5s;
`
const QChoices = styled.div`
  font-height: 15px;
  font-size: 15px;
`
const QExplain = styled.div``
const AnsButton = styled.div`
  min-width: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #1586ca;
  border: 2px solid #1586ca;
  border-radius: 50%;
  height: 30px;
  width: 30px;
  ${(props) =>
    // console.log(props)
    props.selected
      ? `
    color: white;
    background: #1586ca;`
      : null}
      ${(props) =>
        props.cross
          ? ` text-decoration: line-through; color:#ccc; border-color:#ccc `
          : `text-decoration: none;`}}
`

function MathRadioButton({
  qChoices,
  optionsChosen,
  setChosen,
  userTestData,
  showCross,
  crossDataA,
  crossDataB,
  crossDataC,
  crossDataD,
  crossData,
  setCrossoutDataA,
  setCrossoutDataB,
  setCrossoutDataC,
  setCrossoutDataD,
  setCrossoutData,
}) {
  useEffect(() => {
    if (userTestData != null || userTestData != '') {
      setChosen(userTestData)
    }
  }, [userTestData])

  const selectOption = (e) => {
    if (optionsChosen == e) {
      userTestData = ''
      setChosen('')
    } else {
      setChosen(e)
      userTestData = e
    }
  }
  return (
    <ChoicesContainer>
      <WithCrossContainer>
        <ChoiceSectionA
          cross={showCross}
          selected={userTestData == 'A'}
          onClick={() => selectOption('A')}
        >
          <AnsButton cross={crossDataA} selected={optionsChosen == 'A'}>
            A
          </AnsButton>
          <QChoicesContainer cross={crossDataA}>
            <QChoices>
              <MathJax key={qChoices[0]}>{parse2(qChoices[0], true)}</MathJax>{' '}
            </QChoices>

            <QExplain></QExplain>
          </QChoicesContainer>
        </ChoiceSectionA>
        <ChoiceCross>
          {showCross ? (
            crossDataA == false ? (
              <img
                onClick={() => {
                  setCrossoutDataA(true)
                  setCrossoutData((prev) => {
                    const tmpdata = prev
                    tmpdata.a = true
                    return tmpdata
                  })
                }}
                src={aCross}
                alt='aCross'
                width={30}
              />
            ) : (
              <div
                style={{ textDecoration: 'underline' }}
                onClick={() => {
                  setCrossoutDataA(false)
                  setCrossoutData((prev) => {
                    const tmpdata = prev
                    tmpdata.a = false
                    return tmpdata
                  })
                }}
              >
                Undo
              </div>
            )
          ) : null}
        </ChoiceCross>
      </WithCrossContainer>
      <WithCrossContainer>
        <ChoiceSection
          cross={showCross}
          selected={userTestData == 'B'}
          onClick={() => selectOption('B')}
        >
          <AnsButton cross={crossDataB} selected={optionsChosen == 'B'}>
            B
          </AnsButton>
          <QChoicesContainer cross={crossDataB}>
            <QChoices>
              <MathJax key={qChoices[1]}>{parse2(qChoices[1], true)}</MathJax>{' '}
            </QChoices>
            <QExplain></QExplain>
          </QChoicesContainer>
        </ChoiceSection>
        <ChoiceCross>
          {showCross ? (
            crossDataB == false ? (
              <img
                onClick={() => {
                  setCrossoutDataB(true)
                  setCrossoutData((prev) => {
                    const tmpdata = prev
                    tmpdata.b = true
                    return tmpdata
                  })
                }}
                src={bCross}
                alt='bCross'
                width={30}
              />
            ) : (
              <div
                style={{ textDecoration: 'underline' }}
                onClick={() => {
                  setCrossoutDataB(false)
                  setCrossoutData((prev) => {
                    const tmpdata = prev
                    tmpdata.b = false
                    return tmpdata
                  })
                }}
              >
                Undo
              </div>
            )
          ) : null}
        </ChoiceCross>
      </WithCrossContainer>
      <WithCrossContainer>
        <ChoiceSection
          cross={showCross}
          selected={userTestData == 'C'}
          onClick={() => selectOption('C')}
        >
          <AnsButton cross={crossDataC} selected={optionsChosen == 'C'}>
            C
          </AnsButton>
          <QChoicesContainer cross={crossDataC}>
            <QChoices>
              <MathJax key={qChoices[2]}>{parse2(qChoices[2], true)}</MathJax>
            </QChoices>
            <QExplain></QExplain>
          </QChoicesContainer>
        </ChoiceSection>
        <ChoiceCross>
          {showCross ? (
            crossDataC == false ? (
              <img
                onClick={() => {
                  setCrossoutDataC(true)
                  setCrossoutData((prev) => {
                    const tmpdata = prev
                    tmpdata.c = true
                    return tmpdata
                  })
                }}
                src={cCross}
                alt='cCross'
                width={30}
              />
            ) : (
              <div
                style={{ textDecoration: 'underline' }}
                onClick={() => {
                  setCrossoutDataC(false)
                  setCrossoutData((prev) => {
                    const tmpdata = prev
                    tmpdata.c = false
                    return tmpdata
                  })
                }}
              >
                Undo
              </div>
            )
          ) : null}
        </ChoiceCross>
      </WithCrossContainer>
      <WithCrossContainer>
        <ChoiceSection
          cross={showCross}
          selected={userTestData == 'D'}
          onClick={() => selectOption('D')}
        >
          <AnsButton cross={crossDataD} selected={optionsChosen == 'D'}>
            D
          </AnsButton>
          <QChoicesContainer cross={crossDataD}>
            <QChoices>
              <MathJax key={qChoices[3]}>{parse2(qChoices[3], true)}</MathJax>{' '}
            </QChoices>

            <QExplain></QExplain>
          </QChoicesContainer>
        </ChoiceSection>
        <ChoiceCross>
          {showCross ? (
            crossDataD == false ? (
              <img
                onClick={() => {
                  setCrossoutDataD(true)
                  setCrossoutData((prev) => {
                    const tmpdata = prev
                    tmpdata.d = true
                    return tmpdata
                  })
                }}
                src={dCross}
                alt='dCross'
                width={30}
              />
            ) : (
              <div
                style={{ textDecoration: 'underline' }}
                onClick={() => {
                  setCrossoutDataD(false)
                  setCrossoutData((prev) => {
                    const tmpdata = prev
                    tmpdata.d = false
                    return tmpdata
                  })
                }}
              >
                Undo
              </div>
            )
          ) : null}
        </ChoiceCross>
      </WithCrossContainer>
    </ChoicesContainer>
  )
}

export default MathRadioButton
