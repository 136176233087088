import axios from '../apis/axios'
const xapi = require('../xapi/xapi')

const useXapi = () => {
  let interactions = JSON.parse(window.localStorage.getItem('xapi-SAT'))
  const sendstatements = async () => {
    const response = await axios.post('/xapi/send', {
      withCredentials: true,
      interactions: interactions,
    })
    xapi.createxapijson()
    // console.log(response)
    return response
  }
  return sendstatements
}

export default useXapi
