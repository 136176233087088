import React, { useState, useEffect } from 'react'
import useAuth from '../hooks/useAuth'
import { axiosPrivate } from '../apis/axios'
import TopBar from '../components/Topbar'
import 'react-toastify/dist/ReactToastify.css'
import styled from 'styled-components'
import MySunEditor from '../components/SunEditor'
import { useLocation, useNavigate } from 'react-router-dom'
import { ToastContainer, toast } from 'react-toastify'
import DeveloperSidebar from '../components/DeveloperSidebar'
import { Sidebar } from 'react-pro-sidebar'
import { useProSidebar } from 'react-pro-sidebar'
import { GiHamburgerMenu } from 'react-icons/gi'

const DevLandingContainer = styled.div`
  background: #355799;
  display: flex;
  height: 92vh;
`
const DevLandingDiv = styled.div`
  background: white;
  width: 90vw;
  margin: 20px;
  border-radius: 20px;
`
const NumberOfTickets = styled.div`
  padding: 20px;
`
const SidebarContainer = styled.div`
  display: flex;
  height: 100%;
`
const HamburgerButton = styled.button`
  width: 100px;
  cursor: pointer;
  border: none;
  background: none;
  height: 20px;
`

const TicketDiv = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`

const DevLanding = () => {
  //const [videoData, setVideoData] = useState()
  const { auth } = useAuth()
  const location = useLocation()
  const [report, setReport] = useState('')
  const navigate = useNavigate()
  const [ticketList, setTicketList] = useState()
  const [toggled, setToggled] = useState(false)
  const [broken, setBroken] = useState(false)
  // const { toggleSidebar, broken } = useProSidebar()

  useEffect(() => {
    const getTicketList = async () => {
      try {
        // const resp = await axiosPrivate.get('/dev/tickets/list/', {
        //   headers: { authorization: 'Bearer ' + auth.accessToken },
        // })
        const resp = await axiosPrivate.get('/dev/tickets/list/')
        console.log('tickets:', resp.data)
        setTicketList(resp.data)
      } catch (err) {
        console.log(err)
      }
    }

    getTicketList()
  }, [])
  console.log('DevLanding auth', auth)
  return (
    <>
      <ToastContainer
        position='top-center'
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme='light'
      />
      <TopBar color={'#9AB5CE'} titleName={'Welcome Developer!'} />
      <DevLandingContainer>
        <SidebarContainer>
          <DeveloperSidebar
            setToggled={setToggled}
            toggled={toggled}
            setBroken={setBroken}
            broken={broken}
          />
        </SidebarContainer>
        {broken && (
          <HamburgerButton
            className='sb-button'
            onClick={() => setToggled(true)}
          >
            <GiHamburgerMenu size={40} />
          </HamburgerButton>
        )}
        <DevLandingDiv>
          <NumberOfTickets>
            <h3>Number of Tickets: {ticketList?.length}</h3>
          </NumberOfTickets>
        </DevLandingDiv>
      </DevLandingContainer>
    </>
  )
}

export default DevLanding
