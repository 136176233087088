import axios from '../apis/axios'
import useAuth from './useAuth'

const useRefreshToken = () => {
  const { setAuth, auth } = useAuth()

  const refresh = async () => {
    const response = await axios.get('/refreshtoken', {
      withCredentials: true,
      params: { refresh_token: localStorage.getItem('refresh_token') },
    })
    localStorage.setItem('refresh_token', response.data.tokens.refreshToken)
    setAuth((prev) => {
      // console.log(JSON.stringify(prev))
      // console.log(response.data)
      return {
        ...prev,
        username: response.data.user_username,
        accessToken: response.data.tokens.accessToken,
        UUID: response.data.tokens.user_id,
        user_type: response.data.tokens.user_type,
      }
    })
    return response.data.tokens.accessToken
  }
  return refresh
}

export default useRefreshToken
