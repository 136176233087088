import React, { useRef, useState, useEffect } from 'react'
import SunEditor from 'suneditor-react'
import 'suneditor/dist/css/suneditor.min.css'

const MySunEditorTicket = ({
  value,
  setValue,
  height,
  image,
  alwaysShowToolbar,
  placeholder,
}) => {
  //   const [templateName, setTemplateName] = useState(initialTemplateName)
  //   const [tempVar, setTempVar] = useState(initialTempVar)

  /**
   * @type {React.MutableRefObject<SunEditor>} get type definitions for editor
   */
  const [showToolbar, setShowToolbar] = useState(false)
  const [refresh, setRefresh] = useState(false)
  const editor = useRef()
  // The sunEditor parameter will be set to the core suneditor instance when this function is called
  const getSunEditorInstance = (sunEditor) => {
    editor.current = sunEditor
    editor.current.core.history.stack[0].contents = value
    // editor.current.core.history.reset()
  }
  useEffect(() => {
    setRefresh(!refresh)
  }, [value])

  const handleCreate = () => {}
  let options = {
    buttonList: [
      ['font', 'fontSize', 'formatBlock'],
      ['bold', 'underline', 'italic', 'strike', 'subscript', 'superscript'],
      ['align', 'horizontalRule', 'list', 'table'],
      ['fontColor', 'hiliteColor'],
      ['outdent', 'indent'],
      ['undo', 'redo'],
      ['removeFormat'],
      //   ['outdent', 'indent'],
      //   ['link', 'image'],
      //   ['preview', 'print'],
      //   ['fullScreen', 'showBlocks', 'codeView'],
    ],
  }
  if (image) {
    options.buttonList.push(['image'])
  }
  const handleUpdate = (e) => {
    setValue(e)
  }
  if (value != null) {
    return (
      <SunEditor
        setContents={value}
        // defaultValue={value}
        placeholder={placeholder}
        // onFocus={() => setShowToolbar(true)}
        // onBlur={() =>
        //   setShowToolbar(alwaysShowToolbar ? alwaysShowToolbar : false)
        // }
        onChange={(e) => handleUpdate(e)}
        // hideToolbar={!showToolbar}
        setOptions={options}
        getSunEditorInstance={getSunEditorInstance}
        height={height}
      />
    )
  }
}
export default MySunEditorTicket
