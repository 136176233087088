import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { Modal } from 'react-bootstrap'
import useAuth from '../hooks/useAuth'
import { MathJax } from 'better-react-mathjax'
import Table from 'react-bootstrap/Table'
import './MathModal.css'

import { IoInformationCircleSharp } from 'react-icons/io5'

// Username, Name of file, up

const Button = styled.button`
  appearance: none;
  border-radius: 4px;
  height: 30px;
  cursor: pointer;
  font-size: 15px;
  padding: 0px 15px;
  border: none;
  background: #0084ce;
  color: white;
`
const THead = styled.thead`
  background: #2da0b7;
  color: white;
  // border-radius: 22px 22px 0px 0px;
`
const TableTh = styled.th`
  font-size: ${(prop) =>
    prop.fontSize ? prop.fontSize : `calc(0.8em + 0.6vw)`};
  text-align: center;
  width: ${(prop) => (prop.width ? prop.width : 'max-content')};
`
const TableTh1 = styled(TableTh)``
const TableTh2 = styled(TableTh)``
const TableTd = styled.td`
  text-align: center;
  font-size: calc(0.5em + 0.6vw);
`
const TableTr = styled.tr``

const HomeButtonDiv = styled(Link)`
  font-size: 10px;
`
const BodyDiv = styled.div`
  width: 80vw;
`

function MathDirectionModal(props) {
  return (
    <MathJax hideUntilTypeset={'first'} inline dynamic>
      <Modal
        // className='MathDirection'
        dialogClassName='modal80w'
        {...props}
        aria-labelledby='finshed-exam-modal'
        centered
        scrollable
      >
        <Modal.Header closeButton>
          <Modal.Title id='finshed-exam-modal'>Math Directions</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {/* <BodyDiv> */}
          <p>
            The questions in this section address a number of important math
            skills.
          </p>
          <p>
            Use of a calculator is permitted for all questions. A reference
            sheet, calculator, and these directions can be accessed throughout
            the test.
          </p>
          <p>
            Unless otherwise indicated:
            <li> All variables and expressions represent real numbers.</li>
            <li>Figures provided are drawn to scale.</li>
            <li>All figures lie in a plane.</li>
            <MathJax>
              <li>
                The domain of a given function $f$ is the set of all real
                numbers $x$ for which $f(x)$ is a real number.{' '}
              </li>
            </MathJax>
          </p>
          <p>
            For <b>multiple-choice questions,</b> solve each problem and choose
            the correct answer from the choices provided. Each multiple-choice
            question has a single correct answer.
          </p>
          <p>
            For{' '}
            <b>
              student-produced response questions, solve each problem and enter
              your answer as described below.
            </b>
            <li>
              If you find <b>more than one correct answer,</b> enter only one
              answer.
            </li>
            <li>
              You can enter up to 5 characters for a <b>positive</b> answer and
              up to 6 characters (includling the negative sign) for a{' '}
              <b>negative</b> answer.
            </li>
            <li>
              If your answer is a <b>fraction</b> that doesn't fit in the
              provided space, enter the decimal equivalent.
            </li>
            <li>
              If your answer is a <b>decimal</b> that doesn't fit in the
              provided space, enter it by truncating or rounding at the fourth
              digit.
            </li>
            <li>
              If your answer is a <b>mixed number</b> (such as 3 1/2), enter it
              as an improper fraction (7/2) or its decimal equivalent (3.5).
            </li>
            <li>
              Don't enter <b>symbols</b> such as a percent sign, comma, or
              dollar sign.
            </li>
          </p>
          <Table style={{ margin: 0 }} striped size='sm' bordered>
            <thead>
              <TableTr>
                <TableTh width='40%'>Answer</TableTh>
                <TableTh width='40%' fontSize='calc(0.3em + 0.5vw)'>
                  Acceptable ways to enter answer
                </TableTh>
                <TableTh width='50%' fontSize='calc(0.3em + 0.5vw)'>
                  Unacceptable: will NOT receive credit
                </TableTh>
              </TableTr>
            </thead>
            <tbody>
              <TableTr>
                <TableTd>3.5</TableTd>
                <TableTd>
                  3.5
                  <br />
                  3.50
                  <br />
                  7/2
                </TableTd>
                <TableTd>
                  31/2 <br /> 3 1/2
                </TableTd>
              </TableTr>
              <TableTr>
                <TableTd>
                  <MathJax>`2/3`</MathJax>
                </TableTd>
                <TableTd>
                  2/3
                  <br />
                  .6666
                  <br />
                  .6667
                  <br />
                  0.666
                  <br />
                  0.667
                </TableTd>
                <TableTd>
                  0.66 <br /> .66 <br /> 0.67 <br /> .67
                </TableTd>
              </TableTr>
              <TableTr>
                <TableTd>
                  <MathJax>`-1/3`</MathJax>
                </TableTd>
                <TableTd>
                  -1/3
                  <br />
                  -.3333
                  <br />
                  -0.333
                </TableTd>
                <TableTd>
                  -.33 <br /> -0.33
                </TableTd>
              </TableTr>
            </tbody>
          </Table>
          {/* </BodyDiv> */}
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={props.onHide}>Close</Button>
        </Modal.Footer>
      </Modal>
    </MathJax>
  )
}

export default MathDirectionModal
