import React, { useState, useEffect, useRef, useContext } from 'react'
import styled from 'styled-components'
import { MathJax, MathJaxBaseContext, MathJaxContext } from 'better-react-mathjax'
import { BsBookmark, BsBookmarkFill } from 'react-icons/bs'
import { MdLocationPin } from 'react-icons/md'
import parse2 from '../components/CustomLatexParser'
import parse from 'html-react-parser'
import Mark from 'mark.js'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { useTimer, useStopwatch } from 'react-timer-hook'
import MathRadioButton from '../components/MathRadioButton'
import BookmarkMenu from '../components/BookmarkMenu'
import useAuth from '../hooks/useAuth'
import axios, { axiosPrivate } from '../apis/axios'
import UseTimer from '../components/UseTimer'
import GraphCalcModal from '../Modals/GraphCalcModal'
import { Button, OverlayTrigger, Tooltip, Popover } from 'react-bootstrap'
import MathDirectionModal from '../Modals/MathDirectionModal'
import { AiOutlineDown } from 'react-icons/ai'
import TCrossout from '../Images/crossout-t.png'
import FCrossout from '../Images/crossout-f.png'
import Draggable from 'react-draggable'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import ErrorReportButton from '../components/ErrorReportButton'
import { flushSync } from 'react-dom'
import MathRefModal from '../Modals/MathReferenceModal'
import RefImg from '../Images/reference_2.png'

// import NewQuestion from './multi_true_false.json'

const ExamContainer = styled.div`
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`
const BreakContainer = styled.div`
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`

const ExamHeader = styled.div`
  padding: 10px 0 10px 0;
  display: flex;
  justify-content: space-around;
  border-bottom: 1px solid rgba(33, 36, 44, 0.16);
`
const BreakHeader = styled.div`
  padding: 10px 0 10px 0;
  display: flex;
  justify-content: space-around;
  border-bottom: 1px solid rgba(33, 36, 44, 0.16);
`
const SectionContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`
const SectionTop = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`
const SectionBottom = styled.div``
const DirectionsButton = styled.button`
  background: none;
  color: black;
  border: none;
  &: hover {
    background: none;
    color: black;
  }
`
const RefButton = styled(DirectionsButton)``
const SectionTime = styled.div`
  display: flex;
  flex-direction: column;
`
const BreakTime = styled.div`
  padding: 20px 30px;
`
const Timer = styled.div`
  display: flex;
  align-items: center;
`
const SectionCircle = styled.div`
  height: 30px;
  width: 30px;
  color: white;
  border: 2px solid #1c758a;
  border-radius: 5px;
  background: #1c758a;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-weight: bold;
`
const FTop = styled.div`
  font-size: 15px;
`
const FBot = styled.div`
  font-size: 10px;
`
const SectionTitle = styled.div``
const ReturnButton = styled.button`
  cursor: pointer;
  outline: none;
  background: none;
  border: none;
  font-size: 16px;
  font-weight: bold;
  color: #1586ca;
`
const DirectionContainer = styled.div`
  width: 60vw;
  margin: 0 auto;
  padding: 80px;
`
const ExamContent = styled.div``
const CheckAnswersContainer = styled.div`
  margin: 0 auto;
  height: 60vh;
  width: 90%;
  padding: 10px;
`
const CheckWorkDirection = styled.div`
  text-align: center;
`
const CheckWorkDiv = styled.div`
  padding-top: 20px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
  gap: 20px;
`
const AnswersBoxDiv = styled.div`
  width: 65vw;
  height: 30vh;
  margin: 0 auto;
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.2);
`
const HeaderDiv = styled.div`
  display: flex;
  justify-content: space-between;
`
const HeaderRightDiv = styled.div`
  display: flex;
  flex-direction: row;
  gap: 20px;
  justify-content: center;
`
const UnansweredTextDiv = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: calc(1em + 0.2vw);
`
const ForReviewBookmarkDiv = styled.div`
  font-size: calc(1em + 0.2vw);
`
const CheckQuestionBox = styled.div`
cursor:pointer;
display:flex;
flex-direction:column;
justify-content:center;
align-items: center;
width: 35px;
height:35px;
border 1px solid black;
${(props) => (!props.answered ? `background:#355799; color:white;` : ``)}
`
const CheckQuestionStatusBox = styled(CheckQuestionBox)`
  height: 25px;
  width: 25px;
`
const CurrentQuestionDiv = styled.div`
  margin-top: -21px;
  padding-bottom: 1px;
  opacity: ${(props) => (props.current ? `50` : `0`)};
`
const BookmarkDiv = styled.div`
  position: relative;
  top: -18px;
  right: -10px;
  ${(props) => (props.show ? `opacity:100;` : `opacity:0;`)}
`
const QuestionNumberText = styled.div`
  margin-top: -23px;
`
const TestContainer = styled.div`
  margin: 0 auto;
  height: 80vh;
  overflow: auto;
  width: 90%;
  padding: 10px;
  width: 65vw;
`
const QuestionHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
  padding: 50px 0 10px 0;
`
const QuestionNumDiv = styled.div`
  background: #2d4a82;
  color: white;
  height: 30px;
  width: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
`
const HeaderLeft = styled.div`
  display: flex;
  gap: 20px;
  align-items: center;
`
const CrossoutImageCont = styled.div`
  padding-left: 30px;
`
const DiagramContainer = styled.div`
  border-top: 1px solid #d2d2d2;
  display: flex;
  justify-content: center;
  padding: 20px 0px 20px 0px;
`
const QuestionContainer = styled.div`
  display: flex;
  justify-content: center;
  padding: 20px 0px 20px 0px;
`
const TrueFalseContainer = styled.div``
const TrueFalseDiv = styled.div``
const ChoicesContainer = styled.div``
const UserInputContainer = styled.div`
  margin: 22px 0px;
  font-weight: bold;
`
const UserInput = styled.input`
  font-size: 20px;
  height: 30px;
  width: 300px;
  border: 1px solid lightgrey;
  border-radius: 3px;

  &:focus,
  &:active {
    box-shadow: rgb(210, 213, 217) 0px 0px 2px 1px, rgb(227, 230, 232) 0px 0px 0px 3px;
    border: 1px solid rgb(26, 33, 43);
    outline: none;
  }
  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus {
    background-color: white;
    border: 1px solid lightgrey;
    box-shadow: 0 0 0px 1000px #fff inset;
    -webkit-box-shadow: 0 0 0px 1000px #fff inset;
    transition: background-color 5000s ease-in-out 0s;
    -webkit-text-fill-color: black;
  }

  ${({ valid }) =>
    valid &&
    `
      border: 1px solid rgb(0, 156, 38);

      &:focus,
      &:active {
        border: 1px solid rgb(0, 156, 38);
        box-shadow: rgb(106, 237, 97) 0px 0px 2px 1px,
          rgb(177, 247, 160) 0px 0px 0px 3px;
        outline: none;
      }

      /* Autocomplete styles in Chrome*/
      &:-webkit-autofill,
      &:-webkit-autofill:hover,
      &:-webkit-autofill:focus {
        border: 1px solid rgb(0, 156, 38);
      }
    `}
  ${({ error }) =>
    error &&
    `
      border: 1px solid rgb(191, 49, 12);
      outline: none;

      &:focus,
      &:active {
        box-shadow: rgb(244, 129, 116) 0px 0px 2px 1px,
          rgb(251, 178, 174) 0px 0px 0px 3px;
        border: 1px solid rgb(191, 49, 12);
        outline: none;
      }

      /* Autocomplete styles in Chrome*/
      &:-webkit-autofill,
      &:-webkit-autofill:hover,
      &:-webkit-autofill:focus {
        border: 1px solid rgb(191, 49, 12);
      }
    `}
`
const TestStatusContainer = styled.div``
const TestStatusBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`
const TestStatusHeader = styled.div`
  font-size: calc(1em + 1vw);
  font-weight: bold;
  padding-bottom: 10px;
`
const TestStatusLegend = styled.div`
  border-top: 1px solid black;
  display: flex;
  gap: 20px;
  padding: 10px;
`
const CheckStatusQuestionsContainer = styled.div`
  border-top: 1px solid black;
  width: 40vw;
`
const CurrentQuestionLegendDiv = styled.div`
  font-size: calc(1em + 0.2vw);
`
const QuestionNumberButton = styled.button`
  appearance: none;
  border-radius: 25px;
  cursor: pointer;
  font-size: 15px;
  padding: 15px;
  border: none;
  background: #2d4a82;
  color: white;
`

const ExamFooter = styled.div`
  padding: 10px 50px 10px 0;
  display: flex;
  justify-content: flex-end;
  gap: 20px;
  border-top: 1px solid rgba(33, 36, 44, 0.16);
`
const StartTimerButton = styled.button`
  appearance: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 15px;
  padding: 15px;
  border: none;
  background: #0084ce;
  color: white;
`

const QuestionButton = styled.button`
  appearance: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 15px;
  padding: 15px;
  border: none;
  background: #0084ce;
  color: white;
`
const GraphCalcDiv = styled.div`
  background: #ccc;
  padding: 0px 20px;
  position: fixed;
  overflow: hidden;
  transition: max-height, ease-in, 0.2s;
  z-index: 999;

  ${(props) => (props.show ? `height:55vh` : `height:0px;`)}
`
const RefDiv = styled.div`
  background: #ffffff;
  padding: 0px 0px;
  position: fixed;
  overflow: hidden;
  transition: max-height, ease-in, 0.2s;
  z-index: 999;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.3);
  ${(props) => (props.show ? `height:740px;` : `height:0px;`)}
`
const ErrorMessage = styled.div`
  margin-top: 10px;
  padding-left: 10px;
  background-color: #ffe0e0;
  color: #560000;
  height: 25px;
  width: 300px;
  font-size: 15px;
  line-height: 25px;
`
const ErrorContainer = styled.div`
  position: absolute;
  right: 10px;
`
const MATH_REGEX = /^[0-9./-]+$/
const LENGTH_REGEX = /^[0-9./-]{1,5}$/
const LENGTH_REGEX2 = /^[0-9./-]{1,6}$/

function MathExam() {
  const location = useLocation()
  const [RunPrompt, setRunPrompt] = useState(true)
  const [pageIndex, setPageIndex] = useState(location.state.newPageIndex ? location.state.newPageIndex : 0)
  const [buffering, setBuffering] = useState(false)
  const [userTestData, setUserTestData] = useState(location.state.testData)
  const [userTestKey, setUserTestKey] = useState(
    location.state.testData.exam_section
    // location.state.testData.exam_section == 2 ? 'm1' : 'm2'
  )
  const [questionLength, setCurrentQuestionNum] = useState(Object.keys(location.state.questions).length)
  const [breakOver, setBreakover] = useState(userTestData?.exam_json.finishbreak ? userTestData?.exam_json.finishbreak : false)
  const [pointer, setPointer] = useState(
    userTestData ? (userTestData.exam_json.pointer ? (Object.keys(userTestData.exam_json[userTestKey]).length > userTestData.exam_json.pointer ? userTestData.exam_json.pointer : 1) : 1) : 1
  )
  const [optionChosen, setOptionChosen] = useState()
  const [errorMessage, setErrorMessage] = useState('')
  const [touched, setTouched] = useState(false)
  const [questionBookmark, setQuestionBookmark] = useState([])
  const [showStatusErrPopup, setShowStatusErrPopup] = useState(false)
  const [showGCalc, setShowGCalc] = useState(false)
  const [showRefModal, setShowRefModal] = useState(false)
  const [showDirectionModal, setShowDirecitonModal] = useState(location.state.testData.exam_section == 3 && pageIndex == 1 ? true : false)
  const [showCrosout, setStartCrossout] = useState(false)
  const [answerCrossout, setAnswerCrossout] = useState(
    userTestData
      ? userTestData.exam_json[userTestKey][pointer].crossData
        ? userTestData.exam_json[userTestKey][pointer].crossData
        : { a: false, b: false, c: false, d: false }
      : { a: false, b: false, c: false, d: false }
  )
  const [answerCrossoutA, setAnswerCrossoutA] = useState(answerCrossout.a)
  const [answerCrossoutB, setAnswerCrossoutB] = useState(answerCrossout.b)
  const [answerCrossoutC, setAnswerCrossoutC] = useState(answerCrossout.c)
  const [answerCrossoutD, setAnswerCrossoutD] = useState(answerCrossout.d)
  const [newAnnnotationInfo, setNewAnnotationInfo] = useState()

  const [annotationobject, setAnnotationObject] = useState(
    userTestData ? (userTestData.exam_json[userTestKey][pointer] ? (userTestData.exam_json[userTestKey][pointer].annotation ? userTestData.exam_json[userTestKey][pointer].annotation : []) : []) : []
  )
  const [annlength, setAnnLength] = useState(
    userTestData
      ? userTestData.exam_json[userTestKey][pointer]
        ? userTestData.exam_json[userTestKey][pointer].annotation
          ? userTestData.exam_json[userTestKey][pointer].annotation.length
          : 0
        : 0
      : 0
  )
  const [passagetext, setPassageText] = useState('')
  const [annstate, setAnnState] = useState(-1)

  const { auth } = useAuth()
  const params = useParams()
  const time = useRef({ startTime: new Date() })

  const CloseGCalc = () => setShowGCalc(false)
  const CloseModal = () => setShowDirecitonModal(false)
  const CloseRefModal = () => setShowRefModal(false)
  const navigate = useNavigate()

  // console.log('testdata', location.state.testData, 'new', NewQuestion)
  useEffect(() => {
    setAnswerCrossout(userTestData?.exam_json[userTestKey][pointer].crossData ? userTestData.exam_json[userTestKey][pointer].crossData : { a: false, b: false, c: false, d: false })
    setAnswerCrossoutA(userTestData.exam_json[userTestKey][pointer].crossData ? userTestData.exam_json[userTestKey][pointer].crossData.a : false)
    setAnswerCrossoutB(userTestData.exam_json[userTestKey][pointer].crossData ? userTestData.exam_json[userTestKey][pointer].crossData.b : false)
    setAnswerCrossoutC(userTestData.exam_json[userTestKey][pointer].crossData ? userTestData.exam_json[userTestKey][pointer].crossData.c : false)
    setAnswerCrossoutD(userTestData.exam_json[userTestKey][pointer].crossData ? userTestData.exam_json[userTestKey][pointer].crossData.d : false)
  }, [pointer])
  useEffect(() => {
    renderPassage()
  }, [userTestData, annotationobject, pointer, userTestData])
  useEffect(() => {
    setAnnState(-1)
    setAnnotationObject(userTestData ? (userTestData.exam_json[userTestKey][pointer].annotation ? userTestData.exam_json[userTestKey][pointer].annotation : []) : [])

    renderPassage()
  }, [pointer, passagetext])
  useEffect(() => {
    for (const q in userTestData.exam_json[userTestKey]) {
      if (userTestData.exam_json[userTestKey][q].bookmark) {
        setQuestionBookmark(function (previousState, currentProps) {
          const bookmark = [...previousState]

          bookmark[q] = !bookmark[q]
          return bookmark
        })
      }
    }
  }, [userTestKey])
  useEffect(() => {
    const getExam = async () => {
      const resp = await axiosPrivate.get('/examdata/', {
        headers: { authorization: 'Bearer ' + auth.accessToken },
        params: { public_id: params.id },
      })
      setUserTestData(resp.data)

      // setPointer(27)
      // resp.data.exma_json
      let tmppointer = 1
      if (resp.data.exam_json.pointer <= Object.keys(resp.data.exam_json[resp.data.exam_section]).length) {
        tmppointer = resp.data.exam_json.pointer ? resp.data.exam_json.pointer : 1
      }
      setPointer(tmppointer)
      setOptionChosen(resp.data.exam_json[userTestKey][tmppointer ? tmppointer : 1].response)
    }
    if (auth.username != 'demo') {
      setUserTestData(null)
      getExam()
    }
  }, [])
  useEffect(() => {
    const handleBeforeUnload = (event) => {
      event.preventDefault()
      // saveData({ offsetPointer: 0 })
      return (event.returnValue = 'Closing will not save your current selected answer. Are you sure you want to close?')

      // Custom logic to handle the refresh
      // Display a confirmation message or perform necessary actions
    }
    window.addEventListener('beforeunload', handleBeforeUnload)

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload)
    }
  }, [])
  const handleNext = () => {
    setPageIndex(pageIndex + 1)
    setShowDirecitonModal(true)
  }
  const resumeExamEarly = async () => {
    // console.log('resumeearly', sec, min)
    try {
      userTestData.exam_json.finishbreak = true
      await saveData({ offsetPointer: 0 })
      setBreakover(true)
      setShowDirecitonModal(true)

      setPageIndex(pageIndex + 1)
      t_start()
    } catch (err) {
      toast.error('Issue saving break over')
      setBuffering(false)
    }
  }
  const handleSelectChange = (event) => {
    const value = event.target.value
    setOptionChosen(value)
    userTestData.exam_json[userTestKey][pointer].response = value
  }
  const handleStartExam = async () => {
    if (userTestData.exam_section == 2) {
      userTestData.exam_json.m1started = true
    }
    try {
      const resp = await axiosPrivate.post('/savedemoresponses/', {
        headers: { authorization: 'Bearer ' + auth.accessToken },
        exam_json: userTestData.exam_json,
        exam_id: userTestData.exam_id,
      })
    } catch (err) {
      console.log(err)
    }

    setPageIndex(1)
    // t_restart(time)
    // sw_start()
  }

  const mjContext = useContext(MathJaxBaseContext)
  const mathBlock = useRef(null)

  const renderPassage = () => {
    // if (passagetext == location.state.questions[pointer].content.prompt) {
    // console.log('aij resetting passage text')
    setPassageText(location.state.questions[pointer].content.prompt)
    // }
    // if (mjContext) {
    //   mjContext.promise.then((mathJax) => { }).then(() => {
    var context = document.getElementById('passage')
    var instance = new Mark(context)
    instance.unmark()
    let ranges = []
    for (const ann in userTestData?.exam_json[userTestKey][pointer].annotation) {
      // ranges.push({
      //   start: userTestData.exam_json[userTestKey][pointer].annotation[ann].start,
      //   length: userTestData.exam_json[userTestKey][pointer].annotation[ann].end - userTestData.exam_json[userTestKey][pointer].annotation[ann].start
      // })
      console.log('marking', userTestData.exam_json[userTestKey][pointer].annotation[ann].annotated_text, userTestData.exam_json[userTestKey][pointer].annotation[ann].same)
      if (userTestData.exam_json[userTestKey][pointer].annotation[ann].same) {
        instance.mark(userTestData.exam_json[userTestKey][pointer].annotation[ann].annotated_text, {
          acrossElements: true,
          separateWordSearch: false,
          className: ann,
          exclude: ['.MathJax_Preview'],
        })
      } else if (userTestData.exam_json[userTestKey][pointer].annotation[ann].same === false) {
        // instance.markRegExp(`/${userTestData.exam_json[userTestKey][pointer].annotation[ann].ann_start_text}.*${userTestData.exam_json[userTestKey][pointer].annotation[ann].ann_end_text}/gmi`, {
        let rx = new RegExp(`${userTestData.exam_json[userTestKey][pointer].annotation[ann].ann_start_text}.*?${userTestData.exam_json[userTestKey][pointer].annotation[ann].ann_end_text}`)
        //let rx = new RegExp(`a`)
        console.log('rx', rx)
        instance.markRegExp(rx, {
          exclude: ['.MathJax_Preview'],
          acrossElements: true,
          separateWordSearch: false,
          className: ann,
          // filter: function (textNode, foundTerm, totalCounter, counter) {
          //   // textNode is the text node which contains the found term
          //   // foundTerm is the found search term
          //   // totalCounter is a counter indicating the total number of all marks
          //   //  at the time of the function call
          //   console.log('aij totalcounter', counter)
          //   if (counter > 0) {
          //     return false;
          //   } // must return either true or false
          //   return true;
          // },
        })
      }
      // console.log('aij', ranges)
    }
    //   })
    // }
    // instance.markRanges(ranges);
  }

  const saveData = async ({ offsetPointer }) => {
    setBuffering(true)
    userTestData.exam_json.expireTime = t_minutes * 60 + t_seconds

    userTestData.exam_json[userTestKey][pointer].crossData = answerCrossout
    userTestData.exam_json.pointer = pointer + offsetPointer

    const resp = await axiosPrivate.post('/saveresponses/', {
      headers: { authorization: 'Bearer ' + auth.accessToken },
      responses: userTestData.exam_json,
      public_id: params.id,
    })
    console.log('saved', resp)
    setBuffering(false)
  }
  const nextQuestion = async () => {
    try {
      if (errorMessage == '') {
        if (userTestData.completed == 1) {
          setShowStatusErrPopup(true)
        } else {
          userTestData.exam_json[userTestKey][pointer].response = optionChosen
          setOptionChosen(userTestData.exam_json[userTestKey][pointer + 1].response)
          await saveData({ offsetPointer: 1 })
        }

        setTouched(false)
        flushSync(() => {
          setOptionChosen(userTestData.exam_json[userTestKey][pointer + 1].response)
        })
        setPointer(pointer + 1)
        setErrorMessage('')
      } else {
        toast.error('Please Enter A Valid Response')
      }
    } catch (err) {
      toast.error('Response may not have saved')
      setBuffering(false)
    }
  }

  const prevQuestion = async () => {
    try {
      if (errorMessage == '') {
        userTestData.exam_json[userTestKey][pointer].response = optionChosen
        await saveData({ offsetPointer: -1 })
        setOptionChosen(userTestData.exam_json[userTestKey][pointer - 1].response)
        // setPassageText('')
        setPointer(pointer - 1)
        setErrorMessage('')
      } else {
        toast.error('Please Enter A Valid Response')
      }
    } catch (err) {
      toast.error('Response may not have saved')
      setBuffering(false)
    }
  }

  const prevQuestionCheck = () => {
    // setPointer(pointer - 1)
    setPageIndex(1)
  }

  const goBacktoQuestion = async (qnum) => {
    try {
      if (errorMessage == '') {
        setBuffering(true)
        if (optionChosen != '' || userTestData.exam_json[userTestKey][pointer].response != optionChosen) {
          userTestData.exam_json[userTestKey][pointer].response = optionChosen
        }
        await saveData({ offsetPointer: 0 })
        setBuffering(false)
        setPointer(parseInt(qnum))
        setOptionChosen(userTestData?.exam_json[userTestKey][parseInt(qnum)].response)
        setPageIndex(1)
        document.body.click()
      } else {
        toast.error('Please Enter A Valid Response')
      }
    } catch (err) {
      toast.error('Responses may not have been saved')
      console.log(err)
      setBuffering(false)
    }
  }

  const finishModule = async () => {
    try {
      if (errorMessage == '') {
        setBuffering(true)
        if (optionChosen != '' || userTestData.exam_json[userTestKey][pointer].response != optionChosen) {
          userTestData.exam_json[userTestKey][pointer].response = optionChosen
        }
        await saveData({ offsetPointer: 0 })
        setBuffering(false)
        setPageIndex(pageIndex + 1)
        setPointer(Object.keys(location.state.questions).length)
        setOptionChosen(userTestData.exam_json[userTestKey][Object.keys(location.state.questions).length].response)
      } else {
        toast.error('Please Enter A Valid Response')
      }
    } catch (err) {
      toast.error('Responses may not have been saved')
      console.log(err)
      setBuffering(false)
    }
  }
  const SubmitModule = async () => {
    const tmpsection = userTestData.exam_section
    userTestData.exam_json[userTestKey][pointer].response = optionChosen

    // userTestData.exam_json[userTestKey][pointer].crossData = answerCrossout

    if (userTestData.exam_section == 4) {
      let allowed = false
      for (const sec in userTestData.exam_json) {
        for (const q in userTestData.exam_json[sec]) {
          console.log(sec, q, userTestData.exam_json[sec][q])
          if (userTestData.exam_json[sec][q]?.response != null && userTestData.exam_json[sec][q]?.response != '') {
            allowed = true
          }
        }
      }
      if (allowed) {
        console.log('allowed to finish exam')
        await finishExam()
      } else {
        toast.error('Please enter at least one question')
        console.log('Please enter at least one question')
      }
      allowed = false
    } else {
      userTestData.exam_json.pointer = 1

      if (userTestData.exam_section == 3) {
        userTestData.exam_section = 4
      } else {
        userTestData.exam_section = 5
      }
      delete userTestData.exam_json.expireTime

      try {
        let tmplogs = null
        if (time.current?.message) {
          tmplogs = time.current
        }
        setBuffering(true)
        const resp = await axiosPrivate.post('/saveresponses/', {
          headers: { authorization: 'Bearer ' + auth.accessToken },
          responses: userTestData.exam_json,
          public_id: params.id,
          exam_section: userTestData.exam_section,
          logs: tmplogs,
        })

        setBuffering(false)
        navigate('../', {
          state: userTestData,
          replace: true,
        })
      } catch (err) {
        userTestData.exam_section = tmpsection
        toast.error('Response may not have saved')
        setBuffering(false)
      }
    }
  }
  const finishExam = async () => {
    try {
      await saveData({ offsetPointer: 0 })
      if (userTestData.exam_section == 1) {
        finishModule()
      }
      setRunPrompt(false)
      const resp = await axiosPrivate.post('/submitexam/', {
        headers: { authorization: 'Bearer ' + auth.accessToken },
        public_id: params.id,
      })
      console.log('submit resp', resp)
      if (resp.status == 200) {
        if (resp.data.is_pending) {
          if (auth.username == 'demo') {
            navigate('/', {
              replace: true,
            })
          } else {
            navigate('/', {
              replace: true,
            })
          }
        } else {
          if (auth.username == 'demo') {
            navigate('/exam/' + 'demoexamreview' + '/review', {
              replace: true,
            })
          } else {
            navigate('/exam/' + params.id + '/review', { replace: true })
          }
        }
      } else if (resp.status == 400) {
        toast.error('Test has been submitted already', {
          position: 'top-right',
          autoClose: 5000,
          onClose: () => {
            if (resp.data.is_pending) {
              if (auth.username == 'demo') {
                navigate('../', {
                  replace: true,
                })
              } else {
                navigate('../', {
                  replace: true,
                })
              }
            } else {
              if (auth.username == 'demo') {
                navigate('/exam/' + 'demoexamreview' + '/review', {
                  replace: true,
                })
              } else {
                navigate('/exam/' + params.id + '/review', {
                  replace: true,
                })
              }
            }
          },
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
        })
      }
    } catch (err) {
      console.log(err)
      toast.error('Response may not have saved, please retry')
      setBuffering(false)
    }
  }

  const setBookmark = () => {
    userTestData.exam_json[userTestKey][pointer].bookmark = !userTestData.exam_json[userTestKey][pointer].bookmark
    setQuestionBookmark(function (previousState, currentProps) {
      const bookmark = [...previousState]

      bookmark[pointer] = !bookmark[pointer]
      return bookmark
    })
  }
  const {
    seconds: t_seconds,
    minutes: t_minutes,
    hours: t_hours,
    days: t_days,
    isRunning: t_isRunning,
    start: t_start,
    pause: t_pause,
    resume: t_resume,
    restart: t_restart,
  } = useTimer({
    autoStart: false,
    expiryTimestamp: location.state.expiryTimestamp,
    onExpire: () => {
      TimerExpire()
    },
  })

  const TimerExpire = async () => {
    let tmpTime = { ...time.current }
    tmpTime.endTime = new Date()
    tmpTime.message = 'Timer Ran Out: Section ' + userTestData.exam_section
    console.log('tmptime', tmpTime)
    time.current = tmpTime
    // setTime((prev) => {
    //   prev.endTime = new Date()
    //   prev.message = 'Timer Ran Out: Section ' + userTestData.exam_section
    //   return prev
    // })
    console.log('newtime', time)
    // userTestData['timer ' + userTestData.exam_section] = 'timer ran out'
    await SubmitModule()
  }

  const validateAnswer = (values) => {
    if (values.target.value.length == 0) {
      setErrorMessage('')
    } else if (!MATH_REGEX.test(values.target.value)) {
      setErrorMessage('Invalid character used')
    } else if (values.target.value.startsWith('-')) {
      setErrorMessage('')
      if (!LENGTH_REGEX2.test(values.target.value)) {
        setErrorMessage('Too many characters')
      }
    } else if (!LENGTH_REGEX.test(values.target.value)) {
      setErrorMessage('Too many characters')
    } else {
      setErrorMessage('')
    }
    handleSelectChange(values)
  }
  // t_restart(breakTime)

  const MathSwitch = (index) => {
    switch (index) {
      case 0:
        return breakOver ? (
          handleNext()
        ) : (
          <BreakContainer>
            <BreakHeader></BreakHeader>
            <BreakTime>
              <UseTimer expiryTimestamp={location.state.breakTimestamp} nextPage={resumeExamEarly} userTestData={userTestData} startTimer={t_start} />
            </BreakTime>
            <DirectionContainer>
              <h2> Take a Break</h2>
              <div>
                <p>You may leave the room, but do not disturb students who are still testing.</p>
                <p>Do not exit the app or close your laptop.</p>
                <p>Testing won't resume until you return.</p>
                <p>
                  <b>Follow these rules during the break:</b>
                  <ol>
                    <li>Do not access your phone, textbooks, notes, or the internet.</li>
                    <li>Do not eat or drink in the test room.</li>
                    <li>Do not speak in the test room; outside the test room, do not discuss the exam with anyone.</li>
                  </ol>
                </p>
              </div>
            </DirectionContainer>
            <ExamFooter>
              <StartTimerButton onClick={resumeExamEarly}>Resume Exam</StartTimerButton>
            </ExamFooter>
          </BreakContainer>
        )

      case 1:
        if (!t_isRunning) {
          t_start()
        }

        return (
          <ExamContainer>
            <ExamHeader>
              <SectionContainer>
                <SectionTop>
                  <SectionTime>
                    <SectionCircle>
                      <FTop>35</FTop>
                      <FBot>min</FBot>
                    </SectionCircle>
                  </SectionTime>
                  <Button
                    onClick={() => {
                      setShowGCalc(true)
                    }}
                  >
                    GCalc{' '}
                  </Button>
                  {userTestData.exam_section === 3 ? <SectionTitle> Mathematics Module 1 </SectionTitle> : <SectionTitle> Mathematics Module 2</SectionTitle>}
                </SectionTop>
                <SectionBottom>
                  <DirectionsButton
                    onClick={() => {
                      setShowDirecitonModal(true)
                    }}
                  >
                    Directions <AiOutlineDown />
                  </DirectionsButton>
                  <RefButton
                    onClick={() => {
                      setShowRefModal(true)
                    }}
                  >
                    Reference <AiOutlineDown />
                  </RefButton>
                </SectionBottom>
              </SectionContainer>

              <Timer>
                {t_minutes}:{t_seconds > 9 ? t_seconds : '0' + t_seconds}
              </Timer>

              <ReturnButton onClick={finishModule}>Finish this section</ReturnButton>

              <ErrorContainer>
                <ErrorReportButton
                  id='Topbar'
                  telemetry={{
                    exam_name: location.state.testData.test_data.form_code,
                    section: userTestData.exam_section,
                    page: pageIndex,
                  }}
                />
              </ErrorContainer>
            </ExamHeader>
            <Draggable>
              <GraphCalcDiv show={showGCalc}>
                <GraphCalcModal close={CloseGCalc} />
              </GraphCalcDiv>
            </Draggable>
            <Draggable>
              <RefDiv show={showRefModal}>
                <MathRefModal close={CloseRefModal} />
              </RefDiv>
            </Draggable>
            <TestContainer>
              <QuestionHeader>
                <HeaderLeft>
                  <QuestionNumDiv>{pointer}</QuestionNumDiv>
                  {userTestData.exam_json[userTestKey][pointer].bookmark ? (
                    <>
                      <BsBookmarkFill cursor={'pointer'} onClick={setBookmark} color={'#0084ce'} />
                      <div>Marked for Review</div>
                    </>
                  ) : (
                    <>
                      <BsBookmark cursor={'pointer'} onClick={setBookmark} color={'#0084ce'} />
                      <div>Mark for Review</div>
                    </>
                  )}
                </HeaderLeft>

                {location.state.questions[pointer].content.choices ? (
                  <OverlayTrigger placement='bottom' overlay={<Tooltip id='button-tooltip-2'>Cross out answers you think are wrong.</Tooltip>}>
                    {({ ref, ...triggerHandler }) =>
                      showCrosout ? (
                        <CrossoutImageCont>
                          <img
                            alt='crossout-true'
                            onClick={() => setStartCrossout(!showCrosout)}
                            ref={ref}
                            {...triggerHandler}
                            className='d-inline-flex align-items-center'
                            src={TCrossout}
                            width={30}
                          />
                        </CrossoutImageCont>
                      ) : (
                        <CrossoutImageCont>
                          <img
                            alt='crossout-false'
                            onClick={() => setStartCrossout(!showCrosout)}
                            ref={ref}
                            {...triggerHandler}
                            className='d-inline-flex align-items-center'
                            src={FCrossout}
                            width={30}
                          />
                        </CrossoutImageCont>
                      )
                    }
                  </OverlayTrigger>
                ) : null}
              </QuestionHeader>
              <DiagramContainer>
                {location.state.questions[pointer].content.figure ? (
                  location.state.questions[pointer].content.figure[0]?.body.includes('xml') ? (
                    <>{parse2(location.state.questions[pointer].content.figure[0]?.body.slice(location.state.questions[pointer].content.figure[0]?.body.indexOf('<svg')), true)}</>
                  ) : (
                    <MathJax hideUntilTypeset={'first'} inline dynamic>
                      {location.state.questions[pointer].content.figure[0]?.body ? parse2(location.state.questions[pointer].content.figure[0]?.body, true) : <></>}
                    </MathJax>
                  )
                ) : null}
              </DiagramContainer>
              <QuestionContainer id='passage'>
                {/* {console.log('parsing',typeof parse(passagetext, {
                    htmlparser2: {
                      lowerCaseTags: false,
                    }
                  }).join() ? parse(passagetext, {
                    htmlparser2: {
                      lowerCaseTags: false,
                    }
                  }).join() : '')} */}
                {passagetext != '' ? (
                  <MathJax
                    hideUntilTypeset={'every'}
                    inline={true}
                    dynamic={true}
                    onTypeset={(e) => {
                      // console.log('typesetting')
                    }}
                    renderMode='post'
                    // id='passage'
                  >
                    {parse2(passagetext, true)}
                    {/* parse(location.state.questions[pointer].content.prompt)} */}
                  </MathJax>
                ) : (
                  <></>
                )}
              </QuestionContainer>
              <TrueFalseContainer>
                {location.state.questions[pointer].content.true_false ? (
                  <TrueFalseDiv>
                    <MathJax
                      hideUntilTypeset={'every'}
                      inline={true}
                      dynamic={true}
                      onTypeset={(e) => {
                        // console.log('typesetting')
                      }}
                      renderMode='post'
                      // id='passage'
                    >
                      <ol
                        style={{
                          listStyleType: 'upper-roman',
                        }}
                      >
                        {location.state.questions[pointer].content.true_false.map((list, index) => {
                          return <li style={{ padding: '10px' }}>{list.replace('{', '').replace('.}', '.')}</li>
                        })}
                      </ol>
                      {/* parse(location.state.questions[pointer].content.prompt)} */}
                    </MathJax>
                  </TrueFalseDiv>
                ) : null}
              </TrueFalseContainer>
              <ChoicesContainer>
                {location.state.questions[pointer].content.choices ? (
                  location.state.questions[pointer].content.choices.length != 0 ? (
                    <MathRadioButton
                      qChoices={location.state.questions[pointer].content.choices}
                      userTestData={userTestData.exam_json[userTestKey][pointer].response ? userTestData.exam_json[userTestKey][pointer].response : ''}
                      optionsChosen={optionChosen}
                      setChosen={setOptionChosen}
                      showCross={showCrosout}
                      crossData={answerCrossout}
                      setCrossoutData={setAnswerCrossout}
                      crossDataA={answerCrossoutA}
                      crossDataB={answerCrossoutB}
                      crossDataC={answerCrossoutC}
                      crossDataD={answerCrossoutD}
                      setCrossoutDataA={setAnswerCrossoutA}
                      setCrossoutDataB={setAnswerCrossoutB}
                      setCrossoutDataC={setAnswerCrossoutC}
                      setCrossoutDataD={setAnswerCrossoutD}
                    />
                  ) : (
                    <UserInputContainer>
                      <UserInput
                        type='text'
                        // value={optionChosen}
                        value={optionChosen == null ? '' : optionChosen}
                        // onchange={(e) => handleSelectChange(e)}
                        onChange={validateAnswer}
                        onBlur={() => setTouched(true)}
                        onLoad={(e) => {
                          validateAnswer(e)
                          setTouched(true)
                        }}
                        valid={touched && !errorMessage}
                        error={touched && errorMessage}
                        // onChange={(e) => setOptionChosen(e.target.value)}
                      />
                      {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
                    </UserInputContainer>
                  )
                ) : (
                  <UserInputContainer>
                    <UserInput
                      type='text'
                      value={optionChosen == null ? '' : optionChosen}
                      // onchange={(e) => handleSelectChange(e)}
                      onChange={validateAnswer}
                      onBlur={() => setTouched(true)}
                      valid={touched && !errorMessage}
                      error={touched && errorMessage}
                      // onChange={(e) => setOptionChosen(e.target.value)}
                    />
                    {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
                  </UserInputContainer>
                )}
              </ChoicesContainer>
            </TestContainer>
            {/* <AnnotationContainer annstate={annstate}>
              <AnnotationTitle>Annotation:</AnnotationTitle>

              <AnnoDiv>
                <AnnotateTextDiv>
                  {
                    annstate > -1 ? (
                      <TextArea
                        id={'annotationta'}
                        onChange={(e) =>
                          (userTestData.exam_json[userTestKey][
                            pointer
                          ].annotation[annstate].user_note = e.target.value)
                        }

                        //defaultValue={userTestData.exam_json[userTestKey][pointer].annotation[annstate].user_note}
                        //value={userTestData.exam_json[userTestKey][pointer].annotation[annstate].user_note}
                      />
                    ) : null
                    // <textarea onChange={(e) => { userTestData.exam_json[userTestKey][pointer].annotation[annstate].user_note = e.target.value }} value={userTestData.exam_json[userTestKey][pointer].annotation[annstate].user_note}>{userTestData.exam_json[userTestKey][pointer].annotation[annstate].user_note}</textarea> : <></>
                    // {userTestData.exam_json[userTestKey][pointer].annotation[annstate].user_note}
                  }
                </AnnotateTextDiv>
                <AnnotateButtonGroup>
                  {annstate > -1 ? (
                    <AnnotateButton onClick={() => deleteAnnotation()}>
                      Delete Annotation
                    </AnnotateButton>
                  ) : null}
                  {annstate > -1 ? (
                    <AnnotateButton
                      onClick={() => saveData({ offsetPointer: 0 })}
                    >
                      Save Annotation
                    </AnnotateButton>
                  ) : null}
                </AnnotateButtonGroup>
              </AnnoDiv>
            </AnnotationContainer> */}
            {!buffering ? (
              <ExamFooter>
                {/* <AnnotateButton
                onClick={() =>
                  annstate < 0 ? addAnnotation() : setAnnState(-1)
                }
              >
                {annstate < 0 ? 'Add Annotation' : 'Close Annotations'}
              </AnnotateButton>  */}
                <OverlayTrigger
                  trigger='click'
                  key={'top'}
                  placement={'top'}
                  rootClose={true}
                  rootCloseEvent='click'
                  overlay={
                    <Popover style={{ maxWidth: 'max-content', padding: 20 }} id={'popover-positioned-top'}>
                      <TestStatusContainer>
                        <TestStatusBox>
                          <TestStatusHeader>
                            {userTestData.exam_section === 3 ? <SectionTitle> Mathematics Module 1 </SectionTitle> : <SectionTitle> Mathematics Module 2</SectionTitle>}
                          </TestStatusHeader>
                          <TestStatusLegend>
                            <CurrentQuestionLegendDiv>
                              <MdLocationPin /> Current Question
                            </CurrentQuestionLegendDiv>
                            <UnansweredTextDiv>
                              <div
                                style={{
                                  border: '1px solid black',
                                  height: 15,
                                  width: 15,
                                }}
                              />
                              Unanswered
                            </UnansweredTextDiv>
                            <ForReviewBookmarkDiv>
                              <BsBookmarkFill color={'#0084ce'} /> For Review
                            </ForReviewBookmarkDiv>
                          </TestStatusLegend>
                          <CheckStatusQuestionsContainer>
                            <CheckWorkDiv>
                              {Object.values(userTestData?.exam_json[userTestData.exam_section]).map((questions, index) => {
                                return (
                                  <>
                                    <CheckQuestionStatusBox
                                      answered={questions.response == null || questions.response == ''}
                                      onClick={() => {
                                        goBacktoQuestion(questions.q_id)
                                      }}
                                    >
                                      <CurrentQuestionDiv current={index + 1 == pointer}>
                                        <MdLocationPin color={'#0084ce'} />
                                      </CurrentQuestionDiv>
                                      <BookmarkDiv show={questions.bookmark == true}>
                                        <BsBookmarkFill color={'#0084ce'} />
                                      </BookmarkDiv>
                                      <QuestionNumberText>{questions.q_id}</QuestionNumberText>
                                    </CheckQuestionStatusBox>
                                  </>
                                )
                              })}
                            </CheckWorkDiv>
                          </CheckStatusQuestionsContainer>
                        </TestStatusBox>
                      </TestStatusContainer>
                    </Popover>
                  }
                >
                  <QuestionNumberButton>
                    Question {pointer} of {questionLength}
                  </QuestionNumberButton>
                </OverlayTrigger>
                {/* <BookmarkMenu
                  bookmarkData={questionBookmark}
                  setBookmarkPointer={setPointer}
                /> */}

                {pointer > 1 ? <QuestionButton onClick={prevQuestion}>Previous Question</QuestionButton> : null}
                {pointer <= questionLength ? (
                  optionChosen === '' || optionChosen == null ? (
                    pointer == questionLength ? (
                      <QuestionButton onClick={finishModule}>Finish Module</QuestionButton>
                    ) : (
                      <QuestionButton onClick={nextQuestion}>Skip Question</QuestionButton>
                    )
                  ) : pointer == questionLength ? (
                    <QuestionButton onClick={finishModule}>Finish Module</QuestionButton>
                  ) : (
                    <QuestionButton onClick={nextQuestion}>Next Question</QuestionButton>
                  )
                ) : (
                  <QuestionButton onClick={finishModule}>Finish Module</QuestionButton>
                )}
              </ExamFooter>
            ) : (
              <></>
            )}
          </ExamContainer>
        )
      case 2:
        return (
          <ExamContainer>
            <ExamHeader>
              <SectionContainer>
                <SectionTop>
                  <SectionTime>
                    <SectionCircle>
                      <FTop>35</FTop>
                      <FBot>min</FBot>
                    </SectionCircle>
                  </SectionTime>
                  <Button
                    onClick={() => {
                      setShowGCalc(true)
                    }}
                  >
                    GCalc{' '}
                  </Button>
                  {userTestData.exam_section === 3 ? <SectionTitle> Mathematics Module 1 </SectionTitle> : <SectionTitle> Mathematics Module 2</SectionTitle>}
                </SectionTop>
                <SectionBottom>
                  <DirectionsButton
                    onClick={() => {
                      setShowDirecitonModal(true)
                    }}
                  >
                    Directions <AiOutlineDown />
                  </DirectionsButton>
                  <RefButton
                    onClick={() => {
                      setShowRefModal(true)
                    }}
                  >
                    Reference <AiOutlineDown />
                  </RefButton>
                </SectionBottom>
              </SectionContainer>

              <Timer>
                {t_minutes}:{t_seconds > 9 ? t_seconds : '0' + t_seconds}
              </Timer>

              <ErrorContainer>
                <ErrorReportButton
                  id='Topbar'
                  telemetry={{
                    exam_name: location.state.testData.test_data.form_code,
                    section: userTestData.exam_section,
                    page: pageIndex,
                  }}
                />
              </ErrorContainer>
            </ExamHeader>
            <CheckAnswersContainer>
              <CheckWorkDirection>
                <h3>Check Your Work</h3>
                <p>On test day, you won't be able to move on to the next module until time expires. </p>
                <p>
                  For these practice questions, you can click <b>Next</b> when you're ready to move on.
                </p>
              </CheckWorkDirection>
              <AnswersBoxDiv>
                <HeaderDiv>
                  {userTestData.exam_section === 3 ? <SectionTitle> Mathematics Module 1 </SectionTitle> : <SectionTitle> Mathematics Module 2</SectionTitle>}
                  <HeaderRightDiv>
                    <UnansweredTextDiv>
                      <div
                        style={{
                          border: '1px solid black',
                          height: 15,
                          width: 15,
                        }}
                      />
                      Unanswered
                    </UnansweredTextDiv>
                    <ForReviewBookmarkDiv>
                      <BsBookmarkFill color={'#0084ce'} /> For Review
                    </ForReviewBookmarkDiv>
                  </HeaderRightDiv>
                </HeaderDiv>
                <div style={{ borderBottom: '1px solid black', height: 20 }} />
                <CheckWorkDiv>
                  {Object.values(userTestData?.exam_json[userTestData.exam_section]).map((questions) => {
                    return (
                      <>
                        <CheckQuestionBox
                          answered={questions.response == null || questions.response == ''}
                          onClick={() => {
                            goBacktoQuestion(questions.q_id)
                          }}
                        >
                          <BookmarkDiv show={questions.bookmark != null}>
                            <BsBookmarkFill color={'#0084ce'} />
                          </BookmarkDiv>
                          <QuestionNumberText>{questions.q_id}</QuestionNumberText>
                        </CheckQuestionBox>
                      </>
                    )
                  })}
                </CheckWorkDiv>
              </AnswersBoxDiv>
            </CheckAnswersContainer>

            {!buffering ? (
              <ExamFooter>
                {pointer > 1 ? <QuestionButton onClick={prevQuestionCheck}>Previous Question</QuestionButton> : null}

                <QuestionButton onClick={SubmitModule}>Next</QuestionButton>
              </ExamFooter>
            ) : (
              <></>
            )}
          </ExamContainer>
        )
    }
  }
  return userTestData ? (
    <ExamContainer>
      <ToastContainer position='top-center' autoClose={500} hideProgressBar={false} newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover theme='light' />
      <ExamContent>{MathSwitch(pageIndex)}</ExamContent>
      <GraphCalcModal show={showGCalc} onHide={CloseGCalc} />
      <MathDirectionModal show={showDirectionModal} onHide={CloseModal} />
      <MathRefModal show={showRefModal} onHide={CloseRefModal} />
      {/* <ToastContainer
        position='top-right'
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme='light'
      /> */}
      {/* Same as */}
      {/* <ToastContainer /> */}
    </ExamContainer>
  ) : (
    <></>
  )
}

export default MathExam
