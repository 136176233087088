import React, { useState, useEffect } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import styled from 'styled-components'
import ResultsDataPlot from '../components/ResultsDataPlot'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

const ReviewContainer = styled.div`
  height: 100vh;
  display: flex;
  flex-direction: column;
`
const ReviewHeader = styled.div`
  padding: 10px 0 10px 0;
  display: flex;
  justify-content: space-around;
  border-bottom: 1px solid rgba(33, 36, 44, 0.16);
`
const SectionContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`
const SectionTime = styled.div`
  display: flex;
  flex-direction: column;
`
const SectionCircle = styled.div`
  height: 30px;
  width: 30px;
  color: white;
  border: 2px solid #644172;
  border-radius: 5px;
  background: #644172;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-weight: bold;
`
const SectionTitle = styled.div``
const ReturnButton = styled.button`
  cursor: pointer;
  outline: none;
  background: none;
  border: none;
  font-size: 16px;
  font-weight: bold;
  color: #1586ca;
`

const ExamResultsContainer = styled.div`
  flex: 1 1 auto;
  margin: 0 auto;
  color: #555;
  margin-top: 80px;
  width: 65%;
`
const ReviewPlotContainer = styled.div`
  display: flex;
  justify-content: center;
  padding-top: 20px;
`
const ReadingHeader = styled.span`
  text-transform: uppercase;
`

const ReadingResultsHeader = styled.div`
  font-size: 24px;
  margin-top: 20px;
`
const ReadingExamScoreContainer = styled.div`
  //   width: 300px;
  color: #7e5f8e;
`
const ReadingExamScores = styled.div`
  //   background: #efecf0;
  height: 100px;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: space-evenly;
`
const MathExamScoreContainer = styled.div`
  //   width: 300px;
  color: #1c758a;
`
const MathExamScores = styled.div`
  //   border: 1px solid #644172;
  //   background: #1c758a;
  //   padding-top: 25px;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  wrap-direction: column;
  gap: 20px;
  justify-content: space-evenly;
`
const CorrectDiv = styled.div`
  width: 200px;
  border-left: 1px solid rgba(33, 36, 44, 0.16);
  border-right: 1px solid rgba(33, 36, 44, 0.16);
  text-align: center;
`
const RTop = styled.div`
  font-size: 44px;
  color: ${(props) => props.color};
`
const RBot = styled.div`
  text-transform: uppercase;
  font-weight: bold;
  color: ${(props) => props.color};
`
const IncorrectDiv = styled.div`
  width: 200px;
  text-align: center;
  border-left: 1px solid rgba(33, 36, 44, 0.16);
  border-right: 1px solid rgba(33, 36, 44, 0.16);
`

const ExamScoreContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  justify-content: center;
  padding-top: 50px;
`
const ExamCategoryHeader = styled.div`
  font-size: 1.8em;
`

const ExamCategorySpan = styled.div`
  font-size: 1.3em;
  // width: 200px;
`

const NADiv = styled.div`
  width: 200px;
  text-align: center;
  border-left: 1px solid rgba(33, 36, 44, 0.16);
  border-right: 1px solid rgba(33, 36, 44, 0.16);
`
const ResultsFooterContainer = styled.div`
  display: flex;
  justify-content: space-around;
  border-top: 1px solid rgba(33, 36, 44, 0.16);
  vertical-align: baseline;
`
const ReviewButton = styled.button`
  background: #1586ca;
  font-size: 15px;
  border-radius: 4px;
  border: none;
  color: white;
  padding: 10px 20px;
  margin: 10px 0 10px 0;
  cursor: pointer;
`

const ShowHideReadingDetailsButtonDiv = styled.div`
  margin-left: auto;
  //   position: absolute;
`
const ShowHideMathDetailsButtonDiv = styled.div`
  margin-left: auto;
  //   position: absolute;
`
const ShowHideDetailsButton = styled.button`
  text-decoration: underline;
  border: 0px;
  color: #999;
  background: white;
  cursor: pointer;
  font-size: 16px;
`
const ResultsDetailsContainer = styled.div`
  //   margin-top: 50px;
  margin-left: 30px;
  transition: all 0.2s;
  height: ${(props) => (props.show ? `100%` : `0%`)};
  visibility: ${(props) => (props.show ? `visible` : `hidden`)};
`

const SubscoreHeader = styled.div`
  margin: 0;
  margin-right: 70px;
  display: flex;
  justify-content: space-between;
`

const SubscoreTitle = styled.div``
const SubscoreRange = styled.div``
const SubscoreContainer = styled.div`
  background: #f7f7f7;
  border-radius: 5px;
  margin: 0;
  margin-right: 70px;
`
const SubscoreContent = styled.div`
  display: flex;
  justify-content: space-between;
  font-weight: bold;
  text-transform: uppercase;
  padding: 25px 20px 20px 20px;
`
const SubscoreGeneralTitle = styled.div``
const SubscoreGeneral = styled.div``
const SubscoreMathTitle = styled.div`
  color: #1c758a;
`
const SubscoreMath = styled.div`
  color: #1c758a;
`
const SubscoreReadingTitle = styled.div`
  color: #7e5f8e;
`
const SubscoreReading = styled.div`
  color: #7e5f8e;
`

function ExamResults() {
  const location = useLocation()
  const navigate = useNavigate()

  const [showReadingDetails, setShowReadingDetails] = useState(false)
  const [showMathDetails, setShowMathDetails] = useState(false)
  const reviewExam = (test) => {
    navigate('../exam/' + 'assessment' + '/review', { state: test })
  }
  return (
    <ReviewContainer>
      <ReviewHeader>
        <ReturnButton onClick={() => navigate('../exam')}>
          Return to the list of tests
        </ReturnButton>
      </ReviewHeader>

      <ExamResultsContainer>
        <ExamCategoryHeader>Modules</ExamCategoryHeader>

        <ExamScoreContainer>
          <ReadingExamScoreContainer>
            <ExamCategorySpan>Reading Modules</ExamCategorySpan>
            <ReadingExamScores>
              <CorrectDiv>
                <RTop color={'#0084ce'}>
                  {location.state.exam_score.readingcorrect}
                </RTop>
                <RBot color={'#0084ce'}>Correct</RBot>
              </CorrectDiv>
              <IncorrectDiv>
                <RTop color={'#900'}>
                  {location.state.exam_score.module.m1NA +
                    location.state.exam_score.module.m2NA -
                    location.state.exam_score.readingcorrect}
                </RTop>
                <RBot color={'#900'}>Incorrect</RBot>
              </IncorrectDiv>
              <NADiv>
                <RTop color={'#555'}>
                  {location.state.exam_score.module.m1NA +
                    location.state.exam_score.module.m2NA}
                </RTop>
                <RBot color={'#555'}>Not Answered</RBot>
              </NADiv>
            </ReadingExamScores>
          </ReadingExamScoreContainer>
          {showReadingDetails ? (
            <>
              <ShowHideMathDetailsButtonDiv>
                <ShowHideDetailsButton
                  onClick={() => {
                    setShowReadingDetails(!showReadingDetails)
                  }}
                >
                  Hide Score Breakdown
                </ShowHideDetailsButton>
              </ShowHideMathDetailsButtonDiv>
              <ResultsDetailsContainer show={showReadingDetails}>
                <SubscoreHeader>
                  <SubscoreTitle>Test Scores</SubscoreTitle>
                  <SubscoreRange>0-27 Score Range</SubscoreRange>
                </SubscoreHeader>
                <SubscoreContainer>
                  <SubscoreContent>
                    <SubscoreReadingTitle>
                      Reading Module 1
                    </SubscoreReadingTitle>
                    <SubscoreMath>
                      {location.state.exam_score.module.m1correct}
                    </SubscoreMath>
                  </SubscoreContent>
                  <SubscoreContent>
                    <SubscoreReadingTitle>
                      Reading Module 2
                    </SubscoreReadingTitle>
                    <SubscoreMath>
                      {location.state.exam_score.module.m2correct}
                    </SubscoreMath>
                  </SubscoreContent>
                </SubscoreContainer>
              </ResultsDetailsContainer>
              {/* <ResultsDetailsContainer>
                <SubscoreHeader>
                  <SubscoreTitle>Subscore</SubscoreTitle>
                  <SubscoreRange>1-15 Score Range</SubscoreRange>
                </SubscoreHeader>
                <SubscoreContainer>
                  <SubscoreContent>
                    <SubscoreMathTitle>Heart of Algebra</SubscoreMathTitle>
                    <SubscoreMath></SubscoreMath>
                  </SubscoreContent>
                  <SubscoreContent>
                    <SubscoreReadingTitle>
                      Command of Evidence
                    </SubscoreReadingTitle>
                    <SubscoreReading></SubscoreReading>
                  </SubscoreContent>
                </SubscoreContainer>
              </ResultsDetailsContainer> */}
            </>
          ) : (
            <ShowHideReadingDetailsButtonDiv>
              <ShowHideDetailsButton
                onClick={() => {
                  setShowReadingDetails(!showReadingDetails)
                }}
              >
                Show Reading Score Breakdown
              </ShowHideDetailsButton>
            </ShowHideReadingDetailsButtonDiv>
          )}
          <MathExamScoreContainer>
            <ExamCategorySpan>Math Modules</ExamCategorySpan>
            <MathExamScores>
              <CorrectDiv>
                <RTop color={'#0084ce'}>
                  {location.state.exam_score.mathcorrect}
                </RTop>
                <RBot color={'#0084ce'}>Correct</RBot>
              </CorrectDiv>
              <IncorrectDiv>
                <RTop color={'#900'}>
                  {location.state.exam_score.module.m3NA +
                    location.state.exam_score.module.m4NA -
                    location.state.exam_score.mathcorrect}
                </RTop>
                <RBot color={'#900'}>Incorrect</RBot>
              </IncorrectDiv>
              <NADiv>
                <RTop color={'#555'}>
                  {location.state.exam_score.module.m3NA +
                    location.state.exam_score.module.m4NA}
                </RTop>
                <RBot color={'#555'}>Not Answered</RBot>
              </NADiv>
            </MathExamScores>
          </MathExamScoreContainer>
          {showMathDetails ? (
            <>
              <ShowHideMathDetailsButtonDiv>
                <ShowHideDetailsButton
                  onClick={() => {
                    setShowMathDetails(!showMathDetails)
                  }}
                >
                  Hide Score Breakdown
                </ShowHideDetailsButton>
              </ShowHideMathDetailsButtonDiv>
              <ResultsDetailsContainer show={showMathDetails}>
                <SubscoreHeader>
                  <SubscoreTitle>Test Scores</SubscoreTitle>
                  <SubscoreRange>0-22 Score Range</SubscoreRange>
                </SubscoreHeader>
                <SubscoreContainer>
                  <SubscoreContent>
                    <SubscoreMathTitle>Math Module 1</SubscoreMathTitle>
                    <SubscoreMath>
                      {location.state.exam_score.module.m3correct}
                    </SubscoreMath>
                  </SubscoreContent>
                  <SubscoreContent>
                    <SubscoreMathTitle>Math Module 2</SubscoreMathTitle>
                    <SubscoreMath>
                      {location.state.exam_score.module.m4correct}
                    </SubscoreMath>
                  </SubscoreContent>
                </SubscoreContainer>
              </ResultsDetailsContainer>
              {/* <ResultsDetailsContainer>
                <SubscoreHeader>
                  <SubscoreTitle>Cross-Test Scores</SubscoreTitle>
                  <SubscoreRange>10-40 Score Range</SubscoreRange>
                </SubscoreHeader>
                <SubscoreContainer>
                  <SubscoreContent>
                    <SubscoreGeneralTitle>
                      Analysis in History/Social Studies
                    </SubscoreGeneralTitle>
                    <SubscoreGeneral></SubscoreGeneral>
                  </SubscoreContent>
                  <SubscoreContent>
                    <SubscoreGeneralTitle>
                      Analysis in Science
                    </SubscoreGeneralTitle>
                    <SubscoreGeneral></SubscoreGeneral>
                  </SubscoreContent>
                </SubscoreContainer>
              </ResultsDetailsContainer>
              <ResultsDetailsContainer>
                <SubscoreHeader>
                  <SubscoreTitle>Subscore</SubscoreTitle>
                  <SubscoreRange>1-15 Score Range</SubscoreRange>
                </SubscoreHeader>
                <SubscoreContainer>
                  <SubscoreContent>
                    <SubscoreMathTitle>Heart of Algebra</SubscoreMathTitle>
                    <SubscoreMath></SubscoreMath>
                  </SubscoreContent>
                  <SubscoreContent>
                    <SubscoreReadingTitle>
                      Command of Evidence
                    </SubscoreReadingTitle>
                    <SubscoreReading></SubscoreReading>
                  </SubscoreContent>
                </SubscoreContainer>
              </ResultsDetailsContainer> */}
            </>
          ) : (
            <ShowHideMathDetailsButtonDiv>
              <ShowHideDetailsButton
                onClick={() => {
                  setShowMathDetails(!showMathDetails)
                }}
              >
                Show Math Score Breakdown
              </ShowHideDetailsButton>
            </ShowHideMathDetailsButtonDiv>
          )}
        </ExamScoreContainer>

        <ReviewPlotContainer>
          <ResultsDataPlot test={location.state} />
        </ReviewPlotContainer>
      </ExamResultsContainer>

      <ResultsFooterContainer>
        <ToastContainer
          position='top-center'
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          // pauseOnFocusLoss
          draggable
          // pauseOnHover
          theme='light'
        />
        <ReviewButton
          onClick={(reviewTest) => {
            reviewTest = location.state
            reviewTest.reviewModule = 1
            // console.log(reviewTest)
            reviewExam(reviewTest)
          }}
        >
          Review your answers
        </ReviewButton>
      </ResultsFooterContainer>
    </ReviewContainer>
  )
}

export default ExamResults
