import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { Modal } from 'react-bootstrap'
import useAuth from '../hooks/useAuth'
import { IoInformationCircleSharp } from 'react-icons/io5'

// Username, Name of file, up

const FormContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-height: 20vh;
`
const Form = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 90%;
`
const UploadHeader = styled.div`
  height: 40px;
  font-size: 30px;
`
const UserInputContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: left;
`
const UserSpan = styled.span`
  padding-bottom: 10px;
`
const UserInput = styled.input`
  font-size: 20px;
`
const ButtonDiv = styled.div`
  display: flex;
  justify-content: center;
`
const Button = styled.button`
  appearance: none;
  border-radius: 4px;
  height: 30px;
  cursor: pointer;
  font-size: 15px;
  padding: 0px 15px;
  border: none;
  background: #0084ce;
  color: white;
`

const HomeButtonDiv = styled(Link)`
  font-size: 10px;
`

function NoAnswerErrorMessage(props) {
  return (
    <Modal {...props} aria-labelledby='finshed-exam-modal' centered>
      <Modal.Header closeButton>
        <Modal.Title id='finshed-exam-modal'>Exam with no answers</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>This exam was finished with no questions being answered. </p>
        <p>Please choose a different exam.</p>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={props.onHide}>Close</Button>
      </Modal.Footer>
    </Modal>
  )
}

export default NoAnswerErrorMessage
