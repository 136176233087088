import axios from '../apis/axios'
import useAuth from './useAuth'

const useLogout = () => {
  const { setAuth } = useAuth()

  const logout = async () => {
    const response = await axios.delete('/auth/logout', {
      withCredentials: true,
      credentials: 'include',
    })
    // console.log(response)
    localStorage.removeItem('refresh_token')
    setAuth('')
    return response
  }
  return logout
}

export default useLogout
