import React, { useEffect, useState } from 'react'
import TopBar from '../components/Topbar'
import DropdownButton from 'react-bootstrap/DropdownButton'
import Dropdown from 'react-bootstrap/Dropdown'
import { axiosPrivate } from '../apis/axios'
import { ButtonGroup, ToggleButton, Button } from 'react-bootstrap'
import useAuth from '../hooks/useAuth'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import styled from 'styled-components'

const SubIntervalDiv = styled.div`
  display: flex;
  justify-content: center;
  padding-bottom: 20px;
`
const SubscriptionContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: 20px;
`
const SubscriptionDiv = styled.div`
  background: #355799;
  color: white;
  border-radius: 20px;
  padding: 20px 30px;
`
function AccountSubscription() {
  const { auth } = useAuth()
  const [subscription_key, updateSubscriptionKey] = useState('')
  const [availableSubscriptions, setAvailableSubscriptions] = useState()
  const [selectedSubscriptionType, setSelectedSubscriptionType] = useState()
  const [selectSubInterval, setSelectSubInterval] = useState('month')
  useEffect(() => {
    const getTypes = async () => {
      console.log('getting subscriptions:')
      const resp = await axiosPrivate.get('/subscription/types/', {
        headers: { authorization: 'Bearer ' + auth.accessToken },
      })
      console.log('available subscriptions:', resp.data)
      setAvailableSubscriptions(resp.data.types)
      setSelectedSubscriptionType(resp.data.current)
      return true
    }
    console.log('getting type')
    if (!availableSubscriptions) {
      getTypes()
    }
  }, [availableSubscriptions])
  const createCheckoutSession = async (price_id) => {
    try {
      console.log('creating checkout session: ', price_id)
      const resp = await axiosPrivate.post('/create-checkout-session', {
        price_id: price_id,
      })
      window.open(resp.data)
    } catch (err) {
      console.log(err)
    }
  }
  const changeSubscription = async (new_price_id) => {
    try {
      if (
        window.confirm(
          'Changing your plan will affect the amount you are billed. Are you sure you want to change your plan?'
        )
      ) {
        const resp = await axiosPrivate.post('/change-subscription', {
          new_price_id: new_price_id,
        })
        toast.success('Updated plan! (Refresh to see the update)')
      }
    } catch (err) {
      console.log(err)
    }
  }
  const createPortalSession = async () => {
    try {
      const resp = await axiosPrivate.post('/create-portal-session', {})
      window.open(resp.data)
    } catch (err) {
      console.log(err)
    }
  }
  const saveSubscriptionType = async () => {
    try {
      console.log('saving: ', selectedSubscriptionType, subscription_key)
      const resp = await axiosPrivate.post('/account/changesubscription/', {
        subscription_uuid: selectedSubscriptionType.subscription_uuid,
        subscription_key: subscription_key,
      })
    } catch (err) {
      console.log(err)
    }
  }

  return (
    <div>
      <TopBar />
      <ToastContainer
        position='top-right'
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme='light'
      />
      {/* Same as */}
      <ToastContainer />
      <p>Choose a subscription type:</p>
      {/* <stripe-pricing-table
        pricing-table-id='prctbl_1OMoh2BbpvlB7EbzIcDcmrkP'
        publishable-key='pk_test_51OMGx3BbpvlB7Ebz1kCEUcCUtHJUNQ2cXXKETbNfUrOg5JLPIhHIC0UA5Gwc8akIcf54cUtLbbg9JQ1kjTLErXCu0091jdzaP8'
        client-reference-id='TEST123'
      ></stripe-pricing-table> */}
      <SubIntervalDiv>
        <ButtonGroup>
          <ToggleButton
            key={'month'}
            type='radio'
            variant='secondary'
            name='radio'
            value={'monthly'}
            checked={selectSubInterval === 'month'}
            onClick={() => setSelectSubInterval('month')}
          >
            Monthly
          </ToggleButton>
          <ToggleButton
            key={'year'}
            type='radio'
            variant='secondary'
            name='radio'
            value={'yearly'}
            checked={selectSubInterval === 'year'}
            onClick={() => setSelectSubInterval('year')}
          >
            Yearly
          </ToggleButton>
        </ButtonGroup>
      </SubIntervalDiv>
      <SubscriptionContainer>
        {availableSubscriptions ? (
          availableSubscriptions
            .sort((a, b) => a.name.localeCompare(b.name))
            .map((i, idx) => {
              if (i.interval == selectSubInterval) {
                return (
                  <SubscriptionDiv>
                    <div className='product'>
                      <div className='description'>
                        <h3>{i.name}</h3>
                      </div>
                    </div>
                    <h5>
                      ${i.price.toFixed(2)}/ {i.interval}
                    </h5>
                    {selectedSubscriptionType?.name ? (
                      selectedSubscriptionType.price_id != i.price_id ? (
                        <Button
                          id='checkout-and-portal-button'
                          onClick={() => changeSubscription(i.price_id)}
                        >
                          Change to this plan
                        </Button>
                      ) : (
                        <>This is your current plan</>
                      )
                    ) : (
                      <Button
                        id='checkout-and-portal-button'
                        onClick={() => createCheckoutSession(i.price_id)}
                      >
                        Checkout
                      </Button>
                    )}
                    <div>_________</div>
                  </SubscriptionDiv>
                )
              }
            })
        ) : (
          <></>
        )}
      </SubscriptionContainer>
      {/* <div>{'Current: ' + (selectedSubscriptionType != null ? selectedSubscriptionType?.name + ' (' + selectedSubscriptionType?.interval + 'ly)': 'No active subscription' )}</div> */}
      <div
        style={{
          paddingTop: '20px',
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <Button
          id='checkout-and-portal-button'
          onClick={() => createPortalSession()}
        >
          Manage your billing information
        </Button>
      </div>
      {/* {selectedSubscriptionType ? (
        <div>
          <DropdownButton
            as={ButtonGroup}
            title={'Current: ' + selectedSubscriptionType.name}
            id='bg-nested-dropdown'
            className='mb-2'
          >
            {availableSubscriptions?.map((d, idx) => (
              <Dropdown.Item
                key={idx}
                id={d}
                value={d}
                onClick={() => setSelectedSubscriptionType(d)}
              >
                {d.name}: {d.description}
              </Dropdown.Item>
            ))}
          </DropdownButton>
          <input
            type='text'
            value={subscription_key}
            onChange={(e) => updateSubscriptionKey(e.target.value)}
          />
          <button onClick={() => saveSubscriptionType()}>
            Save subscription type
          </button>
        </div>
      ) : (
        <>Loading...</>
      )} */}
    </div>
  )
}

export default AccountSubscription
