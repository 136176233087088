import React, { useState } from 'react'
import styled from 'styled-components'
import NavBar from './NavBar'
import { useNavigate } from 'react-router-dom'
import { Sidebar, Menu, MenuItem, SubMenu } from 'react-pro-sidebar'
import { AiFillDashboard } from 'react-icons/ai'
import './Sidebar.css'
import { TbReportAnalytics } from 'react-icons/tb'
import { PiPencilCircleFill } from 'react-icons/pi'

const SideContainer = styled.div``
const HamburgerButton = styled.div``
const SideButton = styled.button``
const SidebarContainer = styled.div`
  display: flex;
  height: 100%;
`

function DeveloperSidebar({
  customBP,
  setToggled,
  toggled,
  setBroken,
  broken,
}) {
  const navigate = useNavigate()
  const [customBreak, setCustomBreak] = useState(customBP ? customBP : '800px')

  const menuItemStyles = {
    root: {
      backgroundColor: '#354455',
      fontSize: '1.2em',
      fontWeight: 400,
      color: 'white',
    },
    SubMenuExpandIcon: {
      color: '#b6b7b9',
    },

    button: {
      '&:hover': {
        backgroundColor: '#546b85',
        // color: 'green',
      },
      [`&.active`]: {
        backgroundColor: '#13395e',
        color: '#b6c8d9',
      },
    },
  }

  return (
    <SidebarContainer>
      <Sidebar
        id={'AdminSidebar'}
        customBreakPoint={customBreak}
        backgroundColor='#354455'
        onBackdropClick={() => setToggled(!toggled)}
        toggled={toggled}
        onBreakPoint={setBroken}
      >
        <Menu menuItemStyles={menuItemStyles}>
          <MenuItem
            active={window.location.pathname === '/dev'}
            icon={<AiFillDashboard color='white' />}
            onClick={() => navigate('/dev')}
          >
            Home
          </MenuItem>
        </Menu>
        <Menu
          // rootStyles={{ color: 'white' }}

          menuItemStyles={menuItemStyles}
        >
          <MenuItem
            active={window.location.pathname === '/dev/tickets'}
            icon={<TbReportAnalytics color='white' />}
            onClick={() => navigate('/dev/tickets')}
          >
            Tickets
          </MenuItem>
          <MenuItem
            active={window.location.pathname === '/createticket'}
            icon={<PiPencilCircleFill />}
            onClick={() => navigate('/createticket')}
          >
            Create Ticket
          </MenuItem>
        </Menu>
      </Sidebar>
    </SidebarContainer>
  )
}
export default DeveloperSidebar
