import parse from 'html-react-parser'
const parse2 = (text, insideMathJax, NotDefaultMath) => {
  if (!text) {
    text = ''
  }
  // console.log('text before', text)
  let textnew = text
  var regex = /\\Tuple{/gi,
    result,
    indices = []
  while ((result = regex.exec(textnew))) {
    indices.push(result.index)
  }
  let offset = 0
  for (const index in indices) {
    console.log(indices, index)
    let a = textnew.substring(0, indices[index] + offset) + '\\('
    let id = indices[index] + 7 + offset
    let nests = 0
    while (nests >= 0 && id <= textnew.length) {
      id += 1
      if (textnew[id] == '{') {
        nests = nests + 1
        console.log('adding nest')
      } else if (textnew[id] == '}') {
        nests -= 1
        console.log('ending nest')
      }
    }
    let b = textnew.substring(indices[index] + offset, id + 1)
    let c = '\\)' + textnew.substring(id + 1)
    textnew = a + b + c

    offset += 4
  }

  var regex2 = /\\Set{/gi,
    result,
    indices = []
  while ((result = regex2.exec(textnew))) {
    indices.push(result.index)
  }
  offset = 0
  for (const index in indices) {
    console.log(indices, index)
    let a = textnew.substring(0, indices[index] + offset) + '\\('
    let id = indices[index] + 5 + offset
    let nests = 0
    while (nests >= 0 && id <= textnew.length) {
      id += 1
      if (textnew[id] == '{') {
        nests = nests + 1
        console.log('adding nest')
      } else if (textnew[id] == '}') {
        nests -= 1
        console.log('ending nest')
      }
    }
    let b = textnew.substring(indices[index] + offset, id + 1)
    let c = '\\)' + textnew.substring(id + 1)
    textnew = a + b + c

    offset += 4
  }

  var regex3 = /\\uline{/gi,
    result,
    indices = []
  while ((result = regex3.exec(textnew))) {
    indices.push(result.index)
  }
  offset = 7
  for (const index in indices) {
    let a = textnew.substring(0, indices[index] + offset)
    let id = indices[index] + offset - 1
    let nests = 0
    while (nests >= 0 && id <= textnew.length) {
      id += 1
      if (textnew[id] == '{') {
        nests = nests + 1
        console.log('adding nest')
      } else if (textnew[id] == '}') {
        nests -= 1
        console.log('ending nest')
      }
    }
    let b = textnew.substring(indices[index] + offset, id)
    let c = '</u>' + textnew.substring(id + 1)
    // console.log('uline', indices, index, a)
    textnew =
      a.replace(
        '\\uline{',
        '<u style="text-underline-offset: 4px;text-decoration-skip-ink: none;">'
      ) +
      b +
      c
    offset += 66
  }
  if (insideMathJax) {
    textnew = textnew
      .replaceAll('\\%', '\\(\\%\\)')
      .replaceAll(/\\Ang{([^}*]+)}/g, '\\num{$1}\\degree')
  } else {
    textnew = textnew
      .replaceAll('\\%', '%')
      .replaceAll(/\\sqrt{([^*]+)}/g, '\\(\\sqrt{$1}\\)')
  }
  textnew = textnew
    .replaceAll('\\QuestionError{}', '________')
    .replaceAll('\\questeq', '≟')
    .replaceAll(/\\num{([^}*?]+)}/g, (e, p1) => {
      //   console.log('replacing num', e, p1)
      try {
        if (insideMathJax) {
          return (
            '\\(\\num{\\(' +
            p1.toString().replace(/\B(?!\.\d*)(?=(\d{3})+(?!\d))/g, '{,}') +
            '\\)}\\)'
          )
        } else {
          return p1.toString().replace(/\B(?!\.\d*)(?=(\d{3})+(?!\d))/g, ',')
        }
      } catch (err) {
        console.log(err)
        return p1.toString()
      }
    })
    // .replaceAll(/\\num{([^}*?]+)}/g, (e, p1) => { console.log('replacing num', e, p1); return p1.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ","); })
    // .replaceAll(/\\num{([^}*?]+)}/g, "$1")
    .replaceAll(/\\TextChoice\{1\}/g, '(A)')
    .replaceAll(/\\TextChoice\{2\}/g, '(B)')
    .replaceAll(/\\TextChoice\{3\}/g, '(C)')
    .replaceAll(/\\TextChoice\{4\}/g, '(D)')
    .replaceAll(/\\TextChoice\{5\}/g, '(E)')
    .replaceAll(/\\bigskip/g, '\n')
    .replaceAll(/\\Ellipsis/g, '&#8230;')
    // .replaceAll(
    //   /\\uline{([^*]+)}/g,
    //   '<u style="text-underline-offset: 4px;text-decoration-skip-ink: none;">$1</u>'
    // )
    // .replaceAll(/\\sqrt{([^*]+)}/g, '\\(\\sqrt{$1}\\)')
    .replaceAll(/\n\n/g, '<br/>')
    .replaceAll(/~/g, '&nbsp')
    .replaceAll(
      /\\QuestionError{([^*}]+)}/g,
      '<u style="text-underline-offset: 4px;text-decoration-skip-ink: none;">$1</u>'
    )
    .replaceAll(/\\item/g, '<br/>\\item')
    // .replaceAll(/\\item([^*]+)<br\/>/g, "<li>$1</li>")
    .replaceAll(/<br\/>\\item/g, '</li><li>')
    .replace(/<\/li>/, '')
    .replaceAll(/<br\/>\\item/g, '<li>')
    .replaceAll(/<\/ul>/g, '</li></ul>')
    // .replaceAll(/\\item([^*]+)[^<br/>$]/g, "<li>$1</li>")
    .replaceAll(/\\begin{itemize}([^*]+)\\end{itemize}/g, '<ul>$1</ul>')
    .replaceAll(/\\begin{enumerate}([^*]+)\\end{enumerate}/g, '<ol>$1</ol>')
  // textnew = textnew.replaceAll(
  //   /\\begin{center}([^*]+)\\end{center}/g,
  //   '<span>$1</span>'
  // )
  textnew = textnew
    .replaceAll(
      /\\begin{center}/g,
      `<div style="display:flex; justify-content:center">`
    )
    .replaceAll(/\\end{center}/g, '</div>')
    // .replaceAll(/\\Segment\[bar\]{([^.*]+)}/g, "$\\overrightarrow{$1}\$ ")
    // .replaceAll(/\\Ang{([^}*]+)}/g, "$\\Ang{$1}$")
    .replaceAll(/\\Segment\[bar\]{([^}*?]+)}/g, '\\(\\Segment{$1}\\)')
    // .replaceAll(/\\Tuple{([^}*?]+)}/g, '\\(\\Tuple{$1}\\)')

    .replaceAll(/\\Arc{([^}*?]+)}/g, '$\\overparen{$1}$')
    // .replaceAll(/\\degree/g, "&deg")
    .replaceAll(/\\Si{([^}*?]+)}{([^}*?]+)}/g, '$1\\text{ $2}')
    .replaceAll(/\\kilo/g, 'k')
    .replaceAll(/\\gram/g, 'g')
    .replaceAll(/\\meter/g, 'm')
    .replaceAll(/\\si{([^*}?]+)}/g, '$ $1 $')
    .replaceAll(/\\textsubscript{([^}*]+)}/g, '<sub>$1</sub>')
    .replaceAll(/\\emph{([^}*]+)}/g, '<i>$1</i>')

  // .replaceAll('\\%', '%')
  // if (insideMathJax) {
  //   textnew = textnew
  //     .replaceAll('\\%', '\\(\\%\\)')
  //     // .replaceAll(/\\Ang{([^}*]+)}/g, '$1\\degree')
  // } else {
  //   textnew = textnew.replaceAll('\\%', '%')
  // }
  if (textnew.includes('\\begin{tabular}')) {
    textnew = textnew
      .replaceAll(/{tabular}/g, '{array}')
      // textnew = textnew.replaceAll(/{array}{[^\\*?]+/g, "{array}")
      // .replaceAll(/\\multicolumn{[^$*?]+/g, "{")
      // .replaceAll(/\\multicolumn{[^$*)&\\?]+/g, "{")
      .replaceAll(/\\multicolumn{[^}*?]}{[^{*?]+/g, '')
      .replaceAll(/{\$/g, '{')
      .replaceAll(/\$}/g, '}')
      .replaceAll(/\\hhline{[^*}}?]+/g, '\\hline{')
      .replaceAll(/{}/g, '')
      .replaceAll(/\\hline/g, '\\hline \\text{')
      .replaceAll(/\&/g, '}&\\text{')
      .replaceAll(/\\\\/g, '}\\\\')
      .replaceAll(/\\text{[^ ?]\\end/g, '\\end')
      .replaceAll(/\\text{[ \r\n?]+{/g, '\\text{')
      .replaceAll(/}[ \r\n?]+}/g, '}')
    // .replaceAll(/}[^ ?]}/g, "}")
    // .replaceAll(/\\hline/g, "\\hline$")
    // .replaceAll(/\&/g, "$$&$")
    // .replaceAll(/\\\\/g, "$\\\\")
    // textnew = textnew.replaceAll(/\\begin{array}/g, "\\begin{array}{c}")
    // textnew = textnew.replaceAll(/\\end{array}/g, "}\\end{array}")
    // textnew = textnew.replaceAll(/\\end{array}/g, "\\end{array}")
  }

  // .replaceAll(/\\begin{center}([^*]+)\\end{center}/g, "<b>$1</b>")
  if (
    (textnew.includes('\\left') ||
      textnew.includes('\\right') ||
      textnew.includes('^') ||
      textnew.includes('\\frac{') ||
      textnew.includes('\\leq')) &&
    !textnew.includes('$') &&
    !NotDefaultMath
  ) {
    textnew = '$' + textnew + '$'
  }
  if (textnew.startsWith('{') && textnew.endsWith('}')) {
    textnew = textnew.substring(1, textnew.length - 1)
  }
  if (!insideMathJax || !textnew.includes('$')) {
    textnew = textnew
      .replaceAll('\\$', '$')
      .replaceAll('\\&', '&')
      .replaceAll('\\\\', '<br/>')
  }
  // textnew = textnew.replaceAll('\\degree ','&deg')
  textnew = textnew
    .replaceAll('\\degree', '&deg')
    .replaceAll('\\$', '\\(\\$\\)')
  textnew = textnew
    .replaceAll('$?', '?$')
    .replaceAll('$.', '.$')
    .replaceAll('$!', '!$')
    .replaceAll('$,', ',$')

  // console.log('text after', textnew)

  return parse(textnew)
}

export default parse2
