import { React, useRef, useState, useEffect } from 'react'
import { Link } from 'react-router-dom'

// import useAuth from '../hooks/useAuth'
import styled from 'styled-components'
import axios from '../apis/axios'
import { useLocation, useNavigate } from 'react-router-dom'
import useAuth from '../hooks/useAuth'
import DropdownButton from 'react-bootstrap/DropdownButton'
import Dropdown from 'react-bootstrap/Dropdown'

const FormContainer = styled.div`
  margin: auto;
  border: 1px black;
  font-family: 'Roboto';
  display: flex;
  justify-content: center;
  align-items: center;
  width: 90%;
  min-height: 100vh;
`
const Form = styled.form`
  box-shadow: 0 1px 8px rgba(0, 0, 0, 0.3);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
  border-radius: 10px;
  padding: 50px;
  width: max-content;
`
const LoginHeader = styled.div`
  height: 40px;
  font-size: 30px;
`
const LogInErrorMessage = styled.div`
  margin-top: 10px;
  padding-left: 10px;
  background-color: #ffe0e0;
  color: #560000;
  height: 25px;
  width: 300px;
  font-size: 15px;
  line-height: 25px;
`
const UserInputContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: left;
`
const UserSpan = styled.span`
  padding-bottom: 5px;
`
const UserInput = styled.input`
  font-size: 20px;
  height: 30px;
  width: 300px;
  border: 1px solid lightgrey;
  border-radius: 3px;

  &:focus,
  &:active {
    box-shadow: rgb(210, 213, 217) 0px 0px 2px 1px,
      rgb(227, 230, 232) 0px 0px 0px 3px;
    border: 1px solid rgb(26, 33, 43);
    outline: none;
  }
  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus {
    background-color: white;
    border: 1px solid lightgrey;
    box-shadow: 0 0 0px 1000px #fff inset;
    -webkit-box-shadow: 0 0 0px 1000px #fff inset;
    transition: background-color 5000s ease-in-out 0s;
    -webkit-text-fill-color: black;
  }

  ${({ valid }) =>
    valid &&
    `
    border: 1px solid rgb(0, 156, 38);

    &:focus,
    &:active {
      border: 1px solid rgb(0, 156, 38);
      box-shadow: rgb(106, 237, 97) 0px 0px 2px 1px,
        rgb(177, 247, 160) 0px 0px 0px 3px;
      outline: none;
    }

    /* Autocomplete styles in Chrome*/
    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus {
      border: 1px solid rgb(0, 156, 38);
    }
  `}
  ${({ error }) =>
    error &&
    `
    border: 1px solid rgb(191, 49, 12);
    outline: none;

    &:focus,
    &:active {
      box-shadow: rgb(244, 129, 116) 0px 0px 2px 1px,
        rgb(251, 178, 174) 0px 0px 0px 3px;
      border: 1px solid rgb(191, 49, 12);
      outline: none;
    }

    /* Autocomplete styles in Chrome*/
    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus {
      border: 1px solid rgb(191, 49, 12);
    }
  `}
`
const PasswordInputContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: left;
`
const PasswordSpan = styled.span`
  padding-bottom: 5px;
`
const PasswordInput = styled.input`
  font-size: 20px;
  height: 30px;
  width: 300px;
  border: 1px solid lightgrey;
  border-radius: 3px;

  &:focus,
  &:active {
    box-shadow: rgb(210, 213, 217) 0px 0px 2px 1px,
      rgb(227, 230, 232) 0px 0px 0px 3px;
    border: 1px solid rgb(26, 33, 43);
    outline: none;
  }
  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus {
    background-color: white;
    border: 1px solid lightgrey;
    box-shadow: 0 0 0px 1000px #fff inset;
    -webkit-box-shadow: 0 0 0px 1000px #fff inset;
    transition: background-color 5000s ease-in-out 0s;
    -webkit-text-fill-color: black;
  }

  ${({ valid }) =>
    valid &&
    `
    border: 1px solid rgb(0, 156, 38);

    &:focus,
    &:active {
      border: 1px solid rgb(0, 156, 38);
      box-shadow: rgb(106, 237, 97) 0px 0px 2px 1px,
        rgb(177, 247, 160) 0px 0px 0px 3px;
      outline: none;
    }

    /* Autocomplete styles in Chrome*/
    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus {
      border: 1px solid rgb(0, 156, 38);
    }
  `}
  ${({ error }) =>
    error &&
    `
    border: 1px solid rgb(191, 49, 12);
    outline: none;

    &:focus,
    &:active {
      box-shadow: rgb(244, 129, 116) 0px 0px 2px 1px,
        rgb(251, 178, 174) 0px 0px 0px 3px;
      border: 1px solid rgb(191, 49, 12);
      outline: none;
    }

    /* Autocomplete styles in Chrome*/
    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus {
      border: 1px solid rgb(191, 49, 12);
    }
  `}
`
const ToSignUp = styled(Link)``
const ButtonDiv = styled.div`
  display: flex;
  gap: 20px;
`
const LoginButton = styled.button`
  color: black;
  text-decoration: none;
  font-size: 20px;
  line-height: 16px;
  min-height: 40px;
  border: 1px solid rgba(0, 0, 0, 0.3);
  border-radius: 6px;
  box-shadow: rgba(0, 0, 0, 0.1) 1px 2px 4px;
  box-sizing: border-box;
  cursor: pointer;
  display: inline-block;
  font-family: roboto;
  &:hover {
    border-radius: 6px;
    color: white;
    background: linear-gradient(145deg, #9cc5ff, #2b81f7, #2b81f7);
    box-shadow: 0 1px 8px rgba(0, 0, 0, 0.3);
    transition: box-shadow 0.4s ease;
  }
`
const SignUpButton = styled(LoginButton)`
  &:hover {
    border-radius: 6px;
    color: white;
    background: linear-gradient(145deg, #9cc5ff, #2b81f7, #2b81f7);
    box-shadow: 0 1px 8px rgba(0, 0, 0, 0.3);
    transition: box-shadow 0.4s ease;
  }
`

const Button = styled.button`
  height: 30px;
  font-size: 20px;
  margin: 20px auto;
`

function Login() {
  const navigate = useNavigate()
  console.log('hostname', window.location.hostname, window.location.host)
  const [loginType, setLoginType] = useState(
    window.location.hostname == 'digital-sat.eliteprep.com'
      ? 'Elite USA'
      : window.location.hostname == 'practice-tests.eliteprep.com'
      ? 'Other'
      : 'Other'
  )
  const userRef = useRef()
  const location = useLocation()
  const from = location.state?.from?.pathname || '/'

  const { auth, setAuth } = useAuth()
  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')
  const [errorMessage, setErrorMessage] = useState('')

  useEffect(() => {
    userRef.current.focus()
  }, [])

  // console.log(auth.username)
  // useEffect(() => {
  //   // console.log('navigate log')
  //   if (auth.username) {
  //     console.log(auth.username)
  //     navigate('/')
  //   }
  // }, [])

  const handleSubmit = async (e) => {
    e.preventDefault()
    try {
      const response = await axios.post(
        '/auth/login',
        {
          user_username: username,
          user_password: password,
          nonElite: loginType == 'Other',
        },
        { withCredentials: true }
      )
      console.log('login', response.data)
      const accessToken = response?.data.accessToken
      const UUID = response?.data.user_id
      const user_type = response?.data.user_type
      localStorage.setItem('refresh_token', response.data.refreshToken)
      setAuth({ username, UUID, accessToken, user_type })

      setUsername('')
      setPassword('')
      setErrorMessage('')
      navigate(from, { replace: true })
      if (user_type == 'Individual' || user_type == 'individual') {
        navigate('/exam')
      }
      if (user_type == 'Dev') {
        navigate('/dev')
      }
    } catch (err) {
      console.log(err.response.data.errorMessage)
      setErrorMessage(err.response.data.errorMessage)
    }

    //setErrorMessage('')
  }
  console.log('usertype', auth)

  return (
    <>
      <FormContainer>
        <Form onSubmit={handleSubmit}>
          <LoginHeader>Log in</LoginHeader>
          {errorMessage && (
            <LogInErrorMessage>{errorMessage}</LogInErrorMessage>
          )}
          <UserInputContainer>
            <UserSpan>Username</UserSpan>
            <UserInput
              ref={userRef}
              name='username'
              placeholder='Enter Username'
              autoComplete='off'
              onChange={(e) => setUsername(e.target.value)}
              value={username}
              Required
              error={errorMessage}
              // valid={errorMessage == ''}
            />
          </UserInputContainer>
          <PasswordInputContainer>
            <PasswordSpan>Password</PasswordSpan>
            <PasswordInput
              name='password'
              type='password'
              placeholder='Enter Password'
              autoComplete='off'
              onChange={(e) => setPassword(e.target.value)}
              value={password}
              Required
              error={errorMessage}
              // valid={errorMessage == ''}
            />
          </PasswordInputContainer>
          <ButtonDiv>
            <LoginButton type='submit'>Log In</LoginButton>

            {window.location.hostname != 'digital-sat.eliteprep.com' ? (
              <SignUpButton onClick={() => navigate('/signup')}>
                Sign up
              </SignUpButton>
            ) : (
              <></>
            )}
            {window.location.hostname != 'digital-sat.eliteprep.com' &&
            window.location.hostname != 'practice-tests.eliteprep.com' ? (
              <>
                <DropdownButton
                  title={'Login Type: ' + loginType}
                  id='bg-nested-dropdown'
                  className='mb-2'
                >
                  <Dropdown.Item
                    onClick={() => {
                      setLoginType('Elite USA')
                    }}
                    id='Elite USA'
                    type='radio'
                    variant='secondary'
                    name='radio1'
                    checked={loginType === 'Elite USA'}
                    value={1}
                  >
                    Elite USA
                  </Dropdown.Item>
                  <Dropdown.Item
                    onClick={() => {
                      setLoginType('Other')
                    }}
                    id='Other'
                    type='radio'
                    variant='secondary'
                    name='radio1'
                    checked={loginType === 'Other'}
                    value={2}
                  >
                    Other
                  </Dropdown.Item>
                </DropdownButton>
              </>
            ) : (
              <></>
            )}
            {/* <ToSignUp to={'/signup'}>
              <SignUpButton>Sign Up</SignUpButton>
            </ToSignUp> */}
          </ButtonDiv>
        </Form>
      </FormContainer>
    </>
  )
}

export default Login
