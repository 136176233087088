import React, { useState, useEffect, useRef } from 'react'
import styled from 'styled-components'
import { MathJax } from 'better-react-mathjax'
import { AiOutlineDown } from 'react-icons/ai'
import { BsBookmark, BsBookmarkFill } from 'react-icons/bs'
import { MdLocationPin } from 'react-icons/md'
import parse from 'html-react-parser'
import Mark from 'mark.js'
import parse2 from '../components/CustomLatexParser'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { useTimer, useStopwatch } from 'react-timer-hook'
import ReadingRadioButton from '../components/ReadingRadioButton'
import BookmarkMenu from '../components/BookmarkMenu'
import useAuth from '../hooks/useAuth'
import axios, { axiosPrivate } from '../apis/axios'
import ReadingDirectionModal from '../Modals/ReadingDirectionModal'
import { flushSync } from 'react-dom'
import { Button, OverlayTrigger, Tooltip, Popover } from 'react-bootstrap'
import TCrossout from '../Images/crossout-t.png'
import FCrossout from '../Images/crossout-f.png'
import { ToastContainer, toast } from 'react-toastify'
import ErrorReportButton from '../components/ErrorReportButton'

const ExamContainer = styled.div``
const ExamHeader = styled.div`
  padding: 10px 0 10px 0;
  display: flex;
  justify-content: space-around;
  border-bottom: 1px solid rgba(33, 36, 44, 0.16);
`
const SectionContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`
const SectionTop = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`
const SectionBottom = styled.div``
const DirectionsButton = styled.button`
  background: none;
  color: black;
  border: none;
  &: hover {
    background: none;
    color: black;
  }
`
const SectionTime = styled.div`
  display: flex;
  flex-direction: column;
`
const Timer = styled.div`
  display: flex;
  align-items: center;
`
const SectionCircle = styled.div`
  height: 33px;
  width: 33px;
  color: white;
  border: 2px solid #644172;
  border-radius: 5px;
  background: #644172;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-weight: bold;
`
const FTop = styled.div`
  font-size: 15px;
`
const FBot = styled.div`
  font-size: 10px;
`
const SectionTitle = styled.div``
const ReturnButton = styled.button`
  cursor: pointer;
  outline: none;
  background: none;
  border: none;
  font-size: 16px;
  font-weight: bold;
  color: #1586ca;
`
const ExamContent = styled.div``
const CheckAnswersContainer = styled.div`
  margin: 0 auto;
  height: 60vh;
  width: 90%;
  padding: 10px;
`
const CheckWorkDirection = styled.div`
  text-align: center;
`
const CheckWorkDiv = styled.div`
  padding-top: 20px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
  gap: 20px;
`
const AnswersBoxDiv = styled.div`
  width: 65vw;
  height: 30vh;
  margin: 0 auto;
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.2);
`
const HeaderDiv = styled.div`
  display: flex;
  justify-content: space-between;
`
const HeaderRightDiv = styled.div`
  display: flex;
  flex-direction: row;
  gap: 20px;
  justify-content: center;
`
const UnansweredTextDiv = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: calc(1em + 0.2vw);
`
const ForReviewBookmarkDiv = styled.div`
  font-size: calc(1em + 0.2vw);
`
const CheckQuestionBox = styled.div`
cursor:pointer;
display:flex;
flex-direction:column;
justify-content:center;
align-items: center;
width: 35px;
height:35px;
border 1px solid black;
${(props) => (!props.answered ? `background:#355799; color:white;` : ``)}
`
const CheckQuestionStatusBox = styled(CheckQuestionBox)`
  height: 25px;
  width: 25px;
`
const CurrentQuestionDiv = styled.div`
  margin-top: -21px;
  padding-bottom: 1px;
  opacity: ${(props) => (props.current ? `50` : `0`)};
`
const BookmarkDiv = styled.div`
  position: relative;
  top: -18px;
  right: -10px;
  ${(props) => (props.show ? `opacity:100;` : `opacity:0;`)}
`
const QuestionNumberText = styled.div`
  margin-top: -23px;
`
const TestContainer = styled.div`
  margin: 0 auto;
  height: 60vh;
  width: 90%;
  padding: 10px;
`
const QuestionOverallContainer = styled.div`
  display: flex;
  padding-top: 20px;
`
const QuestionHeader = styled.div`
  padding: 0px 0 10px 0;
  position: sticky;
  top: 0;
  background: white;
`
const QNumBox = styled.div`
  background: #2d4a82;
  width: 30px;
  height: 30px;
  color: white;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
`
const HeaderTop = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
  border-bottom: 1px solid black;
`
const HeaderLeft = styled.div`
  display: flex;
  gap: 20px;
  align-items: center;
`
const HeaderBottom = styled.div``
const CrossoutImageCont = styled.div`
  padding-left: 30px;
`
const QuestionDiv = styled.div`
  // display: flex;
  // justify-content: center;
  padding: 20px 0px 20px 0px;
`
const PassageContainer = styled.div`
  width: 50%;
  padding-right: 20px;
  @media (max-height: 1000px) {
    height: 72vh;
  }
  @media (max-height: 550px) {
    height: 55vh;
  }
  overflow: auto;
`
const QuestionContainer = styled.div`
  padding-left: 25px;
  border-left: 1px solid black;
  width: 50%;
  // height: 80vh;
  // overflow: scroll;
`
const ChoicesContainer = styled.div`
  transition: ease-in, 0.5s;
  @media (max-height: 800px) {
    height: 50vh;
  }
  @media (max-height: 600px) {
    height: 33vh;
  }
  height: 60vh;
  overflow: auto;
`
const TestStatusContainer = styled.div``
const TestStatusBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`
const TestStatusHeader = styled.div`
  font-size: calc(1em + 1vw);
  font-weight: bold;
  padding-bottom: 10px;
`
const TestStatusLegend = styled.div`
  border-top: 1px solid black;
  display: flex;
  gap: 20px;
  padding: 10px;
`
const CheckStatusQuestionsContainer = styled.div`
  border-top: 1px solid black;
  width: 40vw;
`
const CurrentQuestionLegendDiv = styled.div`
  font-size: calc(1em + 0.2vw);
`
const ExamFooter = styled.div`
  position: absolute;
  bottom: 0;
  right: 0;
  width: 100vw;
  background: white;
  padding: 10px 50px 10px 0;
  display: flex;
  justify-content: flex-end;
  gap: 20px;
  border-top: 1px solid rgba(33, 36, 44, 0.16);
  height: 70px;
`
const QuestionButton = styled.button`
  appearance: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 15px;
  padding: 15px;
  border: none;
  background: #0084ce;
  color: white;
`
const QuestionNumberButton = styled.button`
  appearance: none;
  border-radius: 25px;
  cursor: pointer;
  font-size: 15px;
  padding: 15px;
  border: none;
  background: #2d4a82;
  color: white;
`

const AnnotateButtonGroup = styled.div`
  height: 60px;
  padding-left: 20px;
  display: flex;
  align-items: center;
  gap: 20px;
`
const AnnotateButton = styled(QuestionButton)`
  background: #fab400;
  color: black;
`
const AnnotationContainer = styled.div`
  display: flex;
  flex-direction: column;
  z-index: 20;
  position: absolute;
  bottom: 70px;
  margin: 0 auto;
  width: 100vw;
  background: #0b496e;
  overflow: hidden;
  transition: all 0.2s ease-in;
  transform-origin: 0 100%;
  ${(props) => (props.annstate > -1 ? `height:200px;` : `height:0px;`)}
`
const AnnotationTitle = styled.div`
  padding: 10px 20px;
  color: white;
`
const AnnoDiv = styled.div`
  width: 80vw;
  display: flex;
  height: 200px;
  margin: 0 auto;
  align-items: center;
`
const AnnotateTextDiv = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 auto;
`
const TextArea = styled.textarea`
  width: 60vw;
  height: 130px;
`
const ErrorContainer = styled.div`
  position: absolute;
  right: 10px;
`

function ReadingExam() {
  const location = useLocation()
  const [pageIndex, setPageIndex] = useState(0)
  const [buffering, setBuffering] = useState(false)
  const [questionLength, setCurrentQuestionNum] = useState(
    Object.keys(location.state.questions).length
  )
  const [userTestData, setUserTestData] = useState(location.state.testData)
  const [userTestKey, setUserTestKey] = useState(
    location.state.testData.exam_section
  )
  const [pointer, setPointer] = useState(
    userTestData
      ? userTestData.exam_json.pointer
        ? userTestData.exam_json.pointer
        : 1
      : 1
  )
  const [optionChosen, setOptionChosen] = useState('')
  const [errorMessage, setErrorMessage] = useState('')
  const [questionBookmark, setQuestionBookmark] = useState([])
  const [showStatusErrPopup, setShowStatusErrPopup] = useState(false)
  const [showDirectionModal, setShowDirecitonModal] = useState(
    location.state.testData.exam_section == 1 ? true : false
  )
  const [showCrosout, setStartCrossout] = useState(false)
  const [answerCrossout, setAnswerCrossout] = useState(
    userTestData
      ? userTestData.exam_json[userTestKey][pointer]?.crossData
        ? userTestData.exam_json[userTestKey][pointer].crossData
        : { a: false, b: false, c: false, d: false }
      : { a: false, b: false, c: false, d: false }
  )
  const [answerCrossoutA, setAnswerCrossoutA] = useState(answerCrossout.a)
  const [answerCrossoutB, setAnswerCrossoutB] = useState(answerCrossout.b)
  const [answerCrossoutC, setAnswerCrossoutC] = useState(answerCrossout.c)
  const [answerCrossoutD, setAnswerCrossoutD] = useState(answerCrossout.d)

  const [newAnnnotationInfo, setNewAnnotationInfo] = useState()

  const [annotationobject, setAnnotationObject] = useState(
    userTestData
      ? userTestData.exam_json[userTestKey][pointer]
        ? userTestData.exam_json[userTestKey][pointer].annotation
          ? userTestData.exam_json[userTestKey][pointer].annotation
          : []
        : []
      : []
  )
  const [annlength, setAnnLength] = useState(
    userTestData
      ? userTestData.exam_json[userTestKey][pointer]
        ? userTestData.exam_json[userTestKey][pointer].annotation
          ? userTestData.exam_json[userTestKey][pointer].annotation.length
          : 0
        : 0
      : 0
  )
  const [passagetext, setPassageText] = useState('')
  const [annstate, setAnnState] = useState(-1)
  const time = useRef({ startTime: new Date() })
  const expiretime = new Date()
  const { auth } = useAuth()
  const params = useParams()

  const navigate = useNavigate()
  const {
    seconds: t_seconds,
    minutes: t_minutes,
    hours: t_hours,
    days: t_days,
    isRunning: t_isRunning,
    start: t_start,
    pause: t_pause,
    resume: t_resume,
    restart: t_restart,
  } = useTimer({
    autoStart: true,
    expiryTimestamp: location.state.expiryTimestamp,
    onExpire: () => {
      TimerExpire()
    },
  })
  var date = new Date()

  const TimerExpire = async () => {
    let tmpTime = { ...time.current }
    tmpTime.endTime = new Date()
    tmpTime.message = 'Timer Ran Out: Section ' + userTestData.exam_section
    console.log('tmptime', tmpTime)
    time.current = tmpTime
    // setTime((prev) => {
    //   prev.endTime = new Date()
    //   prev.message = 'Timer Ran Out: Section ' + userTestData.exam_section
    //   return prev
    // })
    console.log('newtime', time)
    // userTestData['timer ' + userTestData.exam_section] = 'timer ran out'
    await SubmitModule()
  }
  const handleNext = () => {
    setPageIndex(pageIndex + 1)
  }

  const handleCloseDirectionModal = () => setShowDirecitonModal(false)

  useEffect(() => {
    setAnswerCrossout(
      userTestData.exam_json[userTestKey][pointer].crossData
        ? userTestData.exam_json[userTestKey][pointer].crossData
        : { a: false, b: false, c: false, d: false }
    )
    setAnswerCrossoutA(
      userTestData.exam_json[userTestKey][pointer].crossData
        ? userTestData.exam_json[userTestKey][pointer].crossData.a
        : false
    )
    setAnswerCrossoutB(
      userTestData.exam_json[userTestKey][pointer].crossData
        ? userTestData.exam_json[userTestKey][pointer].crossData.b
        : false
    )
    setAnswerCrossoutC(
      userTestData.exam_json[userTestKey][pointer].crossData
        ? userTestData.exam_json[userTestKey][pointer].crossData.c
        : false
    )
    setAnswerCrossoutD(
      userTestData.exam_json[userTestKey][pointer].crossData
        ? userTestData.exam_json[userTestKey][pointer].crossData.d
        : false
    )
  }, [pointer])

  const CrossOutReset = () => {
    setAnswerCrossout(
      userTestData.exam_json[userTestKey][pointer].crossData
        ? userTestData.exam_json[userTestKey][pointer].crossData
        : { a: false, b: false, c: false, d: false }
    )
    setAnswerCrossoutA(
      userTestData.exam_json[userTestKey][pointer].crossData
        ? userTestData.exam_json[userTestKey][pointer].crossData.a
        : false
    )
    setAnswerCrossoutB(
      userTestData.exam_json[userTestKey][pointer].crossData
        ? userTestData.exam_json[userTestKey][pointer].crossData.b
        : false
    )
    setAnswerCrossoutC(
      userTestData.exam_json[userTestKey][pointer].crossData
        ? userTestData.exam_json[userTestKey][pointer].crossData.c
        : false
    )
    setAnswerCrossoutD(
      userTestData.exam_json[userTestKey][pointer].crossData
        ? userTestData.exam_json[userTestKey][pointer].crossData.d
        : false
    )
  }
  const saveData = async ({ offsetPointer }, finish) => {
    setBuffering(true)
    if (!finish) {
      userTestData.exam_json.expireTime = t_minutes * 60 + t_seconds
    }
    // console.log('answercrossoutsave', answerCrossout)
    userTestData.exam_json[userTestKey][pointer].crossData = answerCrossout
    userTestData.exam_json.pointer = pointer + offsetPointer

    let tmplogs = null
    if (time.current?.message) {
      tmplogs = time.current
    }
    console.log('save time', JSON.stringify(time, null, ' '), tmplogs)

    const resp = await axiosPrivate.post('/saveresponses/', {
      headers: { authorization: 'Bearer ' + auth.accessToken },
      responses: userTestData.exam_json,
      public_id: params.id,
      logs: tmplogs,
    })
    setBuffering(false)
    console.log('saved', resp)
  }
  const nextQuestion = async () => {
    setAnnState(-1)
    try {
      if (userTestData.completed == 1) {
        //console.log("Shouldn't be here....")
        setShowStatusErrPopup(true)
      } else {
        if (
          userTestData.exam_json[userTestKey][pointer].response != null ||
          userTestData.exam_json[userTestKey][pointer].response != ''
        ) {
          setOptionChosen(
            userTestData.exam_json[userTestKey][pointer + 1].response
          )
          console.log(
            'pointer',
            pointer + 1,
            'optionchosen',
            userTestData.exam_json[userTestKey][pointer + 1].response
          )
        }
        userTestData.exam_json[userTestKey][pointer].response = optionChosen

        await saveData({ offsetPointer: 1 })
      }

      setPointer(pointer + 1)
    } catch (err) {
      toast.error('Response may not be saved')
      setBuffering(false)
    }
  }
  useEffect(() => {
    const getExam = async () => {
      const resp = await axiosPrivate.get('/examdata/', {
        headers: { authorization: 'Bearer ' + auth.accessToken },
        params: { public_id: params.id },
      })
      setUserTestData(resp.data)

      if (
        resp.data.exam_json.pointer >=
        resp.data.exam_json[resp.data.exam_section].length
      ) {
        setPointer(1)
      } else {
        setPointer(
          resp.data.exam_json.pointer ? resp.data.exam_json.pointer : 1
        )
      }
      renderPassage()
      setOptionChosen(
        resp.data.exam_json[userTestKey][
          resp.data.exam_json.pointer ? resp.data.exam_json.pointer : 1
        ].response
      )
    }
    if (auth.username != 'demo') {
      setUserTestData(null)
      getExam()
    }
  }, [])
  useEffect(() => {
    for (const q in userTestData.exam_json[userTestKey]) {
      if (userTestData.exam_json[userTestKey][q].bookmark) {
        setQuestionBookmark(function (previousState, currentProps) {
          const bookmark = [...previousState]

          bookmark[q] = !bookmark[q]
          return bookmark
        })
      }
    }
  }, [userTestKey])

  useEffect(() => {
    renderPassage()
  }, [annotationobject, pointer, userTestData])
  useEffect(() => {
    setAnnState(-1)
    setAnnotationObject(
      userTestData.exam_json[userTestKey][pointer].annotation
        ? userTestData.exam_json[userTestKey][pointer].annotation
        : []
    )
    renderPassage()
  }, [pointer])
  useEffect(() => {
    const handleBeforeUnload = (event) => {
      event.preventDefault()
      // saveData({ offsetPointer: 0 })
      return (event.returnValue =
        'Closing will not save your current selected answer. Are you sure you want to close?')

      // Custom logic to handle the refresh
      // Display a confirmation message or perform necessary actions
    }
    window.addEventListener('beforeunload', handleBeforeUnload)

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload)
    }
  }, [])
  const prevQuestion = async () => {
    setAnnState(-1)
    try {
      if (
        optionChosen != '' ||
        userTestData.exam_json[userTestKey][pointer].response != optionChosen
      ) {
        userTestData.exam_json[userTestKey][pointer].response = optionChosen
      }

      await saveData({ offsetPointer: -1 })
      setPointer(pointer - 1)
      setOptionChosen(userTestData.exam_json[userTestKey][pointer - 1].response)
      console.log(
        optionChosen,
        userTestData.exam_json[userTestKey][pointer - 1].response
      )
    } catch (err) {
      console.log('error saving response')
      toast.error('Response may not be saved')
      setBuffering(false)
    }
  }
  const prevQuestionCheck = () => {
    // setPointer(pointer - 1)
    setPageIndex(0)
  }
  const goBacktoQuestion = async (qnum) => {
    try {
      setBuffering(true)
      if (
        optionChosen != '' ||
        userTestData.exam_json[userTestKey][pointer].response != optionChosen
      ) {
        userTestData.exam_json[userTestKey][pointer].response = optionChosen
      }
      await saveData({ offsetPointer: 0 })
      setBuffering(false)
      setPointer(parseInt(qnum))
      setOptionChosen(
        userTestData?.exam_json[userTestKey][parseInt(qnum)].response
      )
      setPageIndex(0)
      document.body.click()
    } catch (err) {
      toast.error('Responses may not have been saved')
      console.log(err)
      setBuffering(false)
    }
  }
  const finishModule = async () => {
    try {
      setBuffering(true)
      if (
        optionChosen != '' ||
        userTestData.exam_json[userTestKey][pointer].response != optionChosen
      ) {
        userTestData.exam_json[userTestKey][pointer].response = optionChosen
      }
      await saveData({ offsetPointer: 0 })
      setBuffering(false)
      setPageIndex(pageIndex + 1)
      setPointer(Object.keys(location.state.questions).length)
      setOptionChosen(
        userTestData.exam_json[userTestKey][
          Object.keys(location.state.questions).length
        ].response
      )
    } catch (err) {
      toast.error('Responses may not have been saved')
      console.log(err)
      setBuffering(false)
    }
  }
  const SubmitModule = async () => {
    const tmpsection = userTestData.exam_section
    userTestData.exam_json[userTestKey][pointer].response = optionChosen
    userTestData.exam_json[userTestKey][pointer].crossData = answerCrossout
    userTestData.exam_json.pointer = 1

    // var testResp
    // exam_section
    if (userTestData.exam_section == 1) {
      userTestData.exam_section = 2
    } else {
      userTestData.exam_section = 3
    }
    delete userTestData.exam_json.expireTime
    try {
      // await saveData({ offsetPointer: 0 }, true)
      let tmplogs = null
      if (time.current?.message) {
        tmplogs = time.current
      }
      setBuffering(true)
      const resp = await axiosPrivate.post('/saveresponses/', {
        headers: { authorization: 'Bearer ' + auth.accessToken },
        responses: userTestData.exam_json,
        public_id: params.id,
        exam_section: userTestData.exam_section,
        logs: tmplogs,
      })

      setBuffering(false)
      navigate('../', {
        state: userTestData,
        replace: true,
      })
    } catch (err) {
      userTestData.exam_section = tmpsection
      toast.error('Responses may not have been saved')
      console.log(err)
      setBuffering(false)
    }
  }

  const setBookmark = () => {
    userTestData.exam_json[userTestKey][pointer].bookmark =
      !userTestData.exam_json[userTestKey][pointer].bookmark
    setQuestionBookmark(function (previousState, currentProps) {
      const bookmark = [...previousState]

      bookmark[pointer] = !bookmark[pointer]
      // console.log(bookmark.filter((value) => value === true).length)
      return bookmark
    })
  }

  window.addEventListener('mouseup', () => {
    let selected = window.getSelection()
    if (selected.anchorNode?.parentNode.nodeName == 'MARK') {
      if (document.getElementById('annotationta')) {
        document.getElementById('annotationta').value =
          userTestData.exam_json[userTestKey][pointer].annotation[
            selected.anchorNode.parentNode.className
          ].user_note
      }
      setAnnState(selected.anchorNode.parentNode.className)
    } else if (selected.focusNode?.parentElement.closest('mark')) {
      setAnnState(selected.focusNode.parentElement.closest('mark').className)
    }
    setNewAnnotationInfo(selected)
  })

  const deleteAnnotation = () => {
    if (annstate < 0) {
      return 'cannot delete negative index'
    } else {
      userTestData.exam_json[userTestKey][pointer].annotation.splice(
        annstate,
        1
      )
      setAnnotationObject(
        userTestData.exam_json[userTestKey][pointer].annotation
      )
      //setAnnotationObject((prev) => { delete prev[annstate]; return prev })//userTestData.exam_json[userTestKey][pointer].annotation)
      setAnnState(-1)
      setAnnLength(annlength - 1)
      renderPassage()
      setPassageText('')
    }
  }
  const addAnnotation = () => {
    if (!userTestData.exam_json[userTestKey][pointer].annotation) {
      userTestData.exam_json[userTestKey][pointer].annotation = []
    }
    let start = newAnnnotationInfo.anchorOffset
    let end = newAnnnotationInfo.extentOffset
    if (
      document
        .getElementById('passage')
        .innerHTML.indexOf(newAnnnotationInfo.toString()) !=
      document
        .getElementById('passage')
        .innerHTML.lastIndexOf(newAnnnotationInfo.toString())
    ) {
      console.log('cannot differentiate multiple same highlights')
    } else {
      if (newAnnnotationInfo.anchorNode === newAnnnotationInfo.focusNode) {
        userTestData.exam_json[userTestKey][pointer].annotation.push({
          div: 'passage',
          start: start,
          same: true,
          end: end,
          annotated_text: newAnnnotationInfo.toString(),
          user_note: '',
        })
        setAnnotationObject((prev) => [
          ...prev,
          {
            div: 'passage',
            start: start,
            end: end,
            same: true,
            annotated_text: newAnnnotationInfo.toString(),
            ann_end_text: newAnnnotationInfo.focusNode.data.substring(
              0,
              newAnnnotationInfo.focusOffset
            ),
            user_note: '',
          },
        ])
        setAnnState(
          userTestData.exam_json[userTestKey][pointer].annotation.length - 1
        )
      } else if (
        !newAnnnotationInfo.anchorNode.isMathJax &&
        !newAnnnotationInfo.focusNode.isMathJax
      ) {
        if (
          newAnnnotationInfo.anchorNode.compareDocumentPosition(
            newAnnnotationInfo.focusNode
          ) == 4
        ) {
          userTestData.exam_json[userTestKey][pointer].annotation.push({
            div: 'passage',
            start: start,
            same: false,
            end: end,
            annotated_text: newAnnnotationInfo.toString(),
            ann_start_text: newAnnnotationInfo.anchorNode.data.substring(
              newAnnnotationInfo.anchorOffset
            ),
            ann_end_text: newAnnnotationInfo.focusNode.data.substring(
              0,
              newAnnnotationInfo.focusOffset
            ),
            user_note: '',
          })
          setAnnotationObject((prev) => [
            ...prev,
            {
              div: 'passage',
              start: start,
              end: end,
              same: false,
              annotated_text: newAnnnotationInfo.toString(),
              ann_start_text: newAnnnotationInfo.anchorNode.data.substring(
                newAnnnotationInfo.anchorOffset
              ),
              ann_end_text: newAnnnotationInfo.focusNode.data.substring(
                0,
                newAnnnotationInfo.focusOffset
              ),
              user_note: '',
            },
          ])
          setAnnState(
            userTestData.exam_json[userTestKey][pointer].annotation.length - 1
          )
        } else {
          userTestData.exam_json[userTestKey][pointer].annotation.push({
            div: 'passage',
            start: start,
            same: false,
            end: end,
            annotated_text: newAnnnotationInfo.toString(),
            ann_end_text: newAnnnotationInfo.anchorNode.data.substring(
              0,
              newAnnnotationInfo.anchorOffset
            ),
            ann_start_text: newAnnnotationInfo.focusNode.data.substring(
              newAnnnotationInfo.focusOffset
            ),
            user_note: '',
          })
          setAnnotationObject((prev) => [
            ...prev,
            {
              div: 'passage',
              start: start,
              end: end,
              same: false,
              annotated_text: newAnnnotationInfo.toString(),
              ann_end_text: newAnnnotationInfo.anchorNode.data.substring(
                newAnnnotationInfo.anchorOffset
              ),
              user_note: '',
            },
          ])
          setAnnState(
            userTestData.exam_json[userTestKey][pointer].annotation.length - 1
          )
        }
      }

      setPassageText('')
    }
  }
  const addAnnotation2 = () => {
    if (annstate > -1) {
      setAnnState(-1)
    } else {
      if (!userTestData.exam_json[userTestKey][pointer].annotation) {
        userTestData.exam_json[userTestKey][pointer].annotation = []
      }

      if (
        newAnnnotationInfo.anchorNode.parentElement.id == 'passage' ||
        newAnnnotationInfo.anchorNode.parentElement.parentElement.id ==
          'passage'
      ) {
        let start = 0
        let end = 0
        if (newAnnnotationInfo.anchorOffset < newAnnnotationInfo.extentOffset) {
          start = newAnnnotationInfo.anchorOffset
          end = newAnnnotationInfo.extentOffset
        } else if (
          newAnnnotationInfo.anchorOffset > newAnnnotationInfo.extentOffset
        ) {
          start = newAnnnotationInfo.extentOffset
          end = newAnnnotationInfo.anchorOffset
        }

        let allowed = true

        if (start != end && newAnnnotationInfo.anchorNode) {
          if (
            newAnnnotationInfo.anchorNode.nextSibling == null ||
            newAnnnotationInfo.anchorNode.nextSibling.outerHTML == '<br>' ||
            newAnnnotationInfo.anchorNode.nextSibling.outerHTML == '</u>' ||
            newAnnnotationInfo.anchorNode.nextSibling.outerHTML.includes(
              '<mark'
            )
          ) {
            if (newAnnnotationInfo.anchorNode != newAnnnotationInfo.focusNode) {
              allowed = false
            }
            if (
              passagetext.indexOf(newAnnnotationInfo.anchorNode.data) !=
              passagetext.lastIndexOf(newAnnnotationInfo.anchorNode.data)
            ) {
              allowed = false
            }
            if (allowed) {
              userTestData.exam_json[userTestKey][pointer].annotation.push({
                div: 'passage',
                start: start,
                end: end,
                annotated_text: newAnnnotationInfo.toString(),
                nodevalue: newAnnnotationInfo.anchorNode.data,
                user_note: '',
              })
              setAnnotationObject((prev) => [
                ...prev,
                {
                  div: 'passage',
                  start: start,
                  end: end,
                  annotated_text: newAnnnotationInfo.toString(),
                  nodevalue: newAnnnotationInfo.anchorNode.data,
                  user_note: '',
                },
              ])
              setAnnState(
                userTestData.exam_json[userTestKey][pointer].annotation.length -
                  1
              )
            }
          } else {
            let offset = passagetext.indexOf(
              newAnnnotationInfo.anchorNode.data +
                newAnnnotationInfo.anchorNode.nextSibling.innerHTML
            )

            for (const ann in annotationobject) {
              let offset2 = passagetext.indexOf(annotationobject[ann].nodevalue)
              if (
                (offset + start > offset2 + annotationobject[ann].end + 7 &&
                  offset + end > offset2 + annotationobject[ann].end + 7) ||
                (offset + start < offset2 + annotationobject[ann].start - 23 &&
                  offset + end < offset2 + annotationobject[ann].start - 23)
              ) {
                allowed = false
                console.log('not allowed...')
              }
            }
            if (allowed) {
              //console.log('previous sibling', newAnnnotationInfo.anchorNode.nextSibling.outerHTML)
              userTestData.exam_json[userTestKey][pointer].annotation.push({
                div: 'passage',
                start: start,
                end: end,
                annotated_text: newAnnnotationInfo.toString(),
                nodevalue:
                  newAnnnotationInfo.anchorNode.data +
                  newAnnnotationInfo.anchorNode.nextSibling.outerHTML,
                user_note: '',
              })
              setAnnotationObject((prev) => [
                ...prev,
                {
                  div: 'passage',
                  start: start,
                  end: end,
                  annotated_text: newAnnnotationInfo.toString(),
                  nodevalue:
                    newAnnnotationInfo.anchorNode.data +
                    newAnnnotationInfo.anchorNode.nextSibling.innerHTML,
                  user_note: '',
                },
              ])
            }
          }
        }
      } else if (newAnnnotationInfo.anchorNode.parentElement.id == 'prompt') {
      }
    }
  }
  const renderPassage = () => {
    setPassageText(location.state.questions[pointer].content.passage.body)
    var context = document.getElementById('passage')
    var instance = new Mark(context)
    instance.unmark()
    let ranges = []
    for (const ann in userTestData?.exam_json[userTestKey][pointer]
      .annotation) {
      if (userTestData.exam_json[userTestKey][pointer].annotation[ann].same) {
        instance.mark(
          userTestData.exam_json[userTestKey][pointer].annotation[ann]
            .annotated_text,
          {
            acrossElements: true,
            separateWordSearch: false,
            className: ann,
            exclude: ['.MathJax_Preview', '.qheader'],
          }
        )
      } else if (
        userTestData.exam_json[userTestKey][pointer].annotation[ann].same ===
        false
      ) {
        let rx = new RegExp(
          `${userTestData.exam_json[userTestKey][pointer].annotation[ann].ann_start_text}.*?${userTestData.exam_json[userTestKey][pointer].annotation[ann].ann_end_text}`
        )
        instance.markRegExp(rx, {
          exclude: ['.MathJax_Preview', '.qheader *'],
          acrossElements: true,
          separateWordSearch: false,
          className: ann,
        })
      }
    }
  }
  const renderPassage2 = () => {
    console.log('renderingPassage')
    let ogtext = `${location.state.questions[pointer].content.passage[0]?.introduction} <br/>
        ${location.state.questions[pointer].content.passage[0].body}`
    console.log('before', ogtext)
    //userTestData.exam_json[userTestKey][pointer].annotation=userTestData.exam_json[userTestKey][pointer].annotation.sort((a,b) => {return a.start=b.start})
    for (const annotation in userTestData?.exam_json[userTestKey][pointer]
      .annotation) {
      //let offset = ogtext.indexOf(userTestData.exam_json[userTestKey][pointer].annotation[annotation].nodevalue)
      let offset = ogtext.indexOf(
        userTestData.exam_json[userTestKey][pointer].annotation[annotation]
          .nodevalue
      )
      // let offset = userTestData.exam_json[userTestKey][pointer].annotation[annotation].additionaloffset + ogtext.indexOf(userTestData.exam_json[userTestKey][pointer].annotation[annotation].nodevalue)
      console.log(
        'inserting annotation',
        userTestData.exam_json[userTestKey][pointer].annotation[annotation]
      )
      ogtext =
        ogtext.slice(
          0,
          offset +
            userTestData.exam_json[userTestKey][pointer].annotation[annotation]
              .start
        ) +
        '<mark style="padding:0;" id="' +
        annotation +
        '">' +
        ogtext.slice(
          offset +
            userTestData.exam_json[userTestKey][pointer].annotation[annotation]
              .start,
          offset +
            userTestData.exam_json[userTestKey][pointer].annotation[annotation]
              .end
        ) +
        '</mark>' +
        ogtext.slice(
          offset +
            userTestData.exam_json[userTestKey][pointer].annotation[annotation]
              .end,
          ogtext.length
        )
    }
    // console.log('after', ogtext)
    setPassageText(ogtext)
    //return <>{ogtext}</>
  }

  // window.addEventListener('beforeunload', (ev) => {
  //   // saveData({ offsetPointer: 0 })

  //   ev.preventDefault()
  //   return (ev.returnValue =
  //     'Closing will not save your current selected answer. Are you sure you want to close?')
  // })

  // console.log(
  //   'lost',
  //   location.state.testData.test_data.form_code,
  //   'pageindex',
  //   pageIndex
  // )
  const ReadingSwitch = (index) => {
    switch (index) {
      case 0:
        return (
          <ExamContainer>
            <ExamHeader>
              <SectionContainer>
                <SectionTop>
                  <SectionTime>
                    <SectionCircle>
                      <FTop>32</FTop>
                      <FBot>min</FBot>
                    </SectionCircle>
                  </SectionTime>
                  {userTestData.exam_section === 1 ? (
                    <SectionTitle> Reading and Writing Module 1 </SectionTitle>
                  ) : (
                    <SectionTitle> Reading and Writing Module 2</SectionTitle>
                  )}
                </SectionTop>
                <SectionBottom>
                  <DirectionsButton
                    onClick={() => {
                      setShowDirecitonModal(true)
                    }}
                  >
                    Directions <AiOutlineDown />
                  </DirectionsButton>
                </SectionBottom>
              </SectionContainer>
              <Timer>
                {t_minutes}:{t_seconds > 9 ? t_seconds : '0' + t_seconds}
              </Timer>

              <ReturnButton onClick={finishModule}>
                Finish this section
              </ReturnButton>
              <ErrorContainer>
                <ErrorReportButton
                  id='Topbar'
                  telemetry={{
                    exam_name: location.state.testData.test_data.form_code,
                    section: userTestData.exam_section,
                    page: pageIndex,
                  }}
                />
              </ErrorContainer>
            </ExamHeader>
            {/* {currentQuestionNum?.length > pointer ? ( */}
            <TestContainer>
              <QuestionOverallContainer id='passage2'>
                <PassageContainer id='passage' key={'passage-'+pointer}>
                  {/* <DiagramContainer></DiagramContainer> */}
                  {/* {parse(passagetext)} */}
                  {location.state.questions[pointer].content.passage.map(
                    (data, idx) => {
                      let str = ''

                      if (idx > 0) {
                        str = '<br/><br/>'
                      }
                      if (
                        location.state.questions[pointer].content.passage
                          .length > 1
                      ) {
                        str = str + '<b>Text ' + (idx + 1) + '</b> <br/><br/>'
                      }
                      if (data.introduction) {
                        str += data.introduction + '<br/><br/>'
                      }
                      if (data.body) {
                        str += data.body
                      }
                      return parse2(str)
                    }
                  )}

                  {/* {parse2(
                    location.state.questions[pointer].content.passage[0]
                      ?.introduction
                  )}
                  {parse2(
                    location.state.questions[pointer].content.passage[0].body
                  )} */}

                  {/* {questions[pointer].content.passage[1] !== null ? (
                    <div>
                      Passage 2 <br />
                      {parse(questions[pointer].content.passage[1].body)}
                    </div>
                  ) : null} */}
                </PassageContainer>

                <QuestionContainer>
                  <QuestionHeader>
                    <HeaderTop className='qheader'>
                      <HeaderLeft>
                        <QNumBox>{pointer}</QNumBox>
                        {userTestData.exam_json[userTestKey][pointer]
                          ?.bookmark ? (
                          <div>
                            <BsBookmarkFill
                              cursor={'pointer'}
                              onClick={setBookmark}
                              color={'#0084ce'}
                            />
                            Mark to Remove
                          </div>
                        ) : (
                          <div>
                            <BsBookmark
                              cursor={'pointer'}
                              onClick={setBookmark}
                              color={'#0084ce'}
                            />{' '}
                            Mark for Review
                          </div>
                        )}
                      </HeaderLeft>
                      <OverlayTrigger
                        placement='bottom'
                        overlay={
                          <Tooltip id='button-tooltip-2'>
                            Cross out answers you think are wrong.
                          </Tooltip>
                        }
                      >
                        {({ ref, ...triggerHandler }) =>
                          showCrosout ? (
                            <CrossoutImageCont>
                              <img
                                alt='crossout-true'
                                onClick={() => setStartCrossout(!showCrosout)}
                                ref={ref}
                                {...triggerHandler}
                                className='d-inline-flex align-items-center'
                                src={TCrossout}
                                width={30}
                              />
                            </CrossoutImageCont>
                          ) : (
                            <CrossoutImageCont>
                              <img
                                alt='crossout-false'
                                onClick={() => setStartCrossout(!showCrosout)}
                                ref={ref}
                                {...triggerHandler}
                                className='d-inline-flex align-items-center'
                                src={FCrossout}
                                width={30}
                              />
                            </CrossoutImageCont>
                          )
                        }
                      </OverlayTrigger>
                    </HeaderTop>
                    <HeaderBottom>
                      <QuestionDiv>
                        {parse2(
                          location.state.questions[pointer].content.prompt
                        )}
                      </QuestionDiv>
                    </HeaderBottom>
                  </QuestionHeader>

                  <ChoicesContainer>
                    <ReadingRadioButton
                      qChoices={
                        location.state.questions[pointer].content.choices
                      }
                      userTestData={
                        userTestData.exam_json[userTestKey][pointer].response
                      }
                      optionsChosen={optionChosen}
                      setChosen={setOptionChosen}
                      showCross={showCrosout}
                      crossData={answerCrossout}
                      setCrossoutData={setAnswerCrossout}
                      crossDataA={answerCrossoutA}
                      crossDataB={answerCrossoutB}
                      crossDataC={answerCrossoutC}
                      crossDataD={answerCrossoutD}
                      setCrossoutDataA={setAnswerCrossoutA}
                      setCrossoutDataB={setAnswerCrossoutB}
                      setCrossoutDataC={setAnswerCrossoutC}
                      setCrossoutDataD={setAnswerCrossoutD}
                    />
                  </ChoicesContainer>
                </QuestionContainer>
              </QuestionOverallContainer>
            </TestContainer>
            {/* ) : (
              <TestContainer>
                <QuestionDiv>
                  You have reached the end of the exam. Press Finish to get your
                  score.
                </QuestionDiv>
              </TestContainer>
            )} */}
            <AnnotationContainer annstate={annstate}>
              <AnnotationTitle>Annotation:</AnnotationTitle>

              <AnnoDiv>
                <AnnotateTextDiv>
                  {annstate > -1 ? (
                    <TextArea
                      id={'annotationta'}
                      onChange={(e) =>
                        (userTestData.exam_json[userTestKey][
                          pointer
                        ].annotation[annstate].user_note = e.target.value)
                      }
                    />
                  ) : null}
                </AnnotateTextDiv>
                <AnnotateButtonGroup>
                  {annstate > -1 ? (
                    <AnnotateButton onClick={() => deleteAnnotation()}>
                      Delete Annotation
                    </AnnotateButton>
                  ) : null}
                  {annstate > -1 ? (
                    <AnnotateButton
                      onClick={async () => {
                        try {
                          await saveData({ offsetPointer: 0 })
                        } catch (err) {
                          toast.error('Annotation may not have saved')
                          setBuffering(false)
                        }
                      }}
                    >
                      Save Annotation
                    </AnnotateButton>
                  ) : null}
                </AnnotateButtonGroup>
              </AnnoDiv>
            </AnnotationContainer>
            {!buffering ? (
              <ExamFooter>
                <AnnotateButton
                  onClick={() =>
                    annstate < 0 ? addAnnotation() : setAnnState(-1)
                  }
                >
                  {annstate < 0 ? 'Add Annotation' : 'Close Annotations'}
                </AnnotateButton>
                <OverlayTrigger
                  trigger='click'
                  key={'top'}
                  placement={'top'}
                  rootClose={true}
                  rootCloseEvent='click'
                  overlay={
                    <Popover
                      style={{ maxWidth: 'max-content', padding: 20 }}
                      id={'popover-positioned-top'}
                    >
                      <TestStatusContainer>
                        <TestStatusBox>
                          <TestStatusHeader>
                            {userTestData.exam_section === 1 ? (
                              <SectionTitle>
                                {' '}
                                Reading and Writing Module 1{' '}
                              </SectionTitle>
                            ) : (
                              <SectionTitle>
                                {' '}
                                Reading and Writing Module 2
                              </SectionTitle>
                            )}
                          </TestStatusHeader>
                          <TestStatusLegend>
                            <CurrentQuestionLegendDiv>
                              <MdLocationPin /> Current Question
                            </CurrentQuestionLegendDiv>
                            <UnansweredTextDiv>
                              <div
                                style={{
                                  border: '1px solid black',
                                  height: 15,
                                  width: 15,
                                }}
                              />
                              Unanswered
                            </UnansweredTextDiv>
                            <ForReviewBookmarkDiv>
                              <BsBookmarkFill color={'#0084ce'} /> For Review
                            </ForReviewBookmarkDiv>
                          </TestStatusLegend>
                          <CheckStatusQuestionsContainer>
                            <CheckWorkDiv>
                              {Object.values(
                                userTestData?.exam_json[
                                  userTestData.exam_section
                                ]
                              ).map((questions, index) => {
                                return (
                                  <>
                                    <CheckQuestionStatusBox
                                      answered={
                                        questions.response == null ||
                                        questions.response == ''
                                      }
                                      onClick={() => {
                                        goBacktoQuestion(questions.q_id)
                                      }}
                                    >
                                      <CurrentQuestionDiv
                                        current={index + 1 == pointer}
                                      >
                                        <MdLocationPin color={'#0084ce'} />
                                      </CurrentQuestionDiv>
                                      <BookmarkDiv
                                        show={questions.bookmark == true}
                                      >
                                        <BsBookmarkFill color={'#0084ce'} />
                                      </BookmarkDiv>
                                      <QuestionNumberText>
                                        {questions.q_id}
                                      </QuestionNumberText>
                                    </CheckQuestionStatusBox>
                                  </>
                                )
                              })}
                            </CheckWorkDiv>
                          </CheckStatusQuestionsContainer>
                        </TestStatusBox>
                      </TestStatusContainer>
                    </Popover>
                  }
                >
                  <QuestionNumberButton>
                    Question {pointer} of {questionLength}
                  </QuestionNumberButton>
                </OverlayTrigger>
                {/* <BookmarkMenu
                  bookmarkData={questionBookmark}
                  setBookmarkPointer={setPointer}
                /> */}
                {pointer > 1 ? (
                  <QuestionButton onClick={prevQuestion}>
                    Previous Question
                  </QuestionButton>
                ) : null}

                {pointer <= questionLength ? (
                  optionChosen === '' || optionChosen == null ? (
                    pointer == questionLength ? (
                      <QuestionButton onClick={finishModule}>
                        Finish Module
                      </QuestionButton>
                    ) : (
                      <QuestionButton onClick={nextQuestion}>
                        Skip Question
                      </QuestionButton>
                    )
                  ) : pointer == questionLength ? (
                    <QuestionButton onClick={finishModule}>
                      Finish Module
                    </QuestionButton>
                  ) : (
                    <QuestionButton onClick={nextQuestion}>
                      Next Question
                    </QuestionButton>
                  )
                ) : (
                  <QuestionButton onClick={finishModule}>
                    Finish Module
                  </QuestionButton>
                )}
              </ExamFooter>
            ) : (
              <></>
            )}
            <ReadingDirectionModal
              show={showDirectionModal}
              onHide={handleCloseDirectionModal}
            />
          </ExamContainer>
        )
      case 1:
        return (
          <ExamContainer>
            <ExamHeader>
              <SectionContainer>
                <SectionTop>
                  <SectionTime>
                    <SectionCircle>
                      <FTop>32</FTop>
                      <FBot>min</FBot>
                    </SectionCircle>
                  </SectionTime>
                  {userTestData.exam_section === 1 ? (
                    <SectionTitle> Reading and Writing Module 1 </SectionTitle>
                  ) : (
                    <SectionTitle> Reading and Writing Module 2</SectionTitle>
                  )}
                </SectionTop>
                <SectionBottom>
                  <DirectionsButton
                    onClick={() => {
                      setShowDirecitonModal(true)
                    }}
                  >
                    Directions <AiOutlineDown />
                  </DirectionsButton>
                </SectionBottom>
              </SectionContainer>
              <Timer>
                {t_minutes}:{t_seconds > 9 ? t_seconds : '0' + t_seconds}
              </Timer>

              <ErrorContainer>
                <ErrorReportButton
                  id='Topbar'
                  telemetry={{
                    exam_name: location.state.testData.test_data.form_code,
                    section: userTestData.exam_section,
                    page: pageIndex,
                  }}
                />
              </ErrorContainer>
            </ExamHeader>
            <CheckAnswersContainer>
              <CheckWorkDirection>
                <h3>Check Your Work</h3>
                <p>
                  On test day, you won't be able to move on to the next module
                  until time expires.{' '}
                </p>
                <p>
                  For these practice questions, you can click <b>Next</b> when
                  you're ready to move on.
                </p>
              </CheckWorkDirection>
              <AnswersBoxDiv>
                <HeaderDiv>
                  {userTestData.exam_section === 1 ? (
                    <SectionTitle> Reading and Writing Module 1 </SectionTitle>
                  ) : (
                    <SectionTitle> Reading and Writing Module 2</SectionTitle>
                  )}
                  <HeaderRightDiv>
                    <UnansweredTextDiv>
                      <div
                        style={{
                          border: '1px solid black',
                          height: 15,
                          width: 15,
                        }}
                      />
                      Unanswered
                    </UnansweredTextDiv>
                    <ForReviewBookmarkDiv>
                      <BsBookmarkFill color={'#0084ce'} /> For Review
                    </ForReviewBookmarkDiv>
                  </HeaderRightDiv>
                </HeaderDiv>
                <div style={{ borderBottom: '1px solid black', height: 20 }} />
                <CheckWorkDiv>
                  {Object.values(
                    userTestData?.exam_json[userTestData.exam_section]
                  ).map((questions) => {
                    return (
                      <>
                        <CheckQuestionBox
                          answered={
                            questions.response == null ||
                            questions.response == ''
                          }
                          onClick={() => {
                            goBacktoQuestion(questions.q_id)
                          }}
                        >
                          <BookmarkDiv show={questions.bookmark != null}>
                            <BsBookmarkFill color={'#0084ce'} />
                          </BookmarkDiv>
                          <QuestionNumberText>
                            {questions.q_id}
                          </QuestionNumberText>
                        </CheckQuestionBox>
                      </>
                    )
                  })}
                </CheckWorkDiv>
              </AnswersBoxDiv>
            </CheckAnswersContainer>

            {!buffering ? (
              <ExamFooter>
                {pointer > 1 ? (
                  <QuestionButton onClick={prevQuestionCheck}>
                    Previous Question
                  </QuestionButton>
                ) : null}

                <QuestionButton onClick={SubmitModule}>Next</QuestionButton>
              </ExamFooter>
            ) : (
              <></>
            )}
            <ReadingDirectionModal
              show={showDirectionModal}
              onHide={handleCloseDirectionModal}
            />
          </ExamContainer>
        )
    }
  }
  if (
    // currentQuestionNum &&
    userTestData &&
    pointer != null &&
    pageIndex != null
  ) {
    return (
      <ExamContainer>
        <ToastContainer
          position='top-center'
          autoClose={500}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme='light'
        />
        <ExamContent>{ReadingSwitch(pageIndex)}</ExamContent>
      </ExamContainer>
    )
  }
}

export default ReadingExam
