import axios from 'axios'

export default axios.create({
  // baseURL: 'http://localhost:3001/',
  baseURL: 'https://elitesat-srv.azurewebsites.net',
})
export const axiosPrivate = axios.create({
  // baseURL: 'http://localhost:3001/',
  baseURL: 'https://elitesat-srv.azurewebsites.net',
  headers: { 'Content-Type': 'application/json' },
  withCredentials: true,
})
