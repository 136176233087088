function addxapi(verbid, objectid, result, context) {
  let xapi = JSON.parse(window.localStorage.getItem('xapi-SAT'))
  let date = new Date()
  let interaction = {
    verb: verbid,
    object: objectid,
    result: result,
    context: context,
    timestamp: date.toISOString(),
  }
  xapi.interactions.push(interaction)
  window.localStorage.setItem('xapi-SAT', JSON.stringify(xapi))
  // console.log("added to xapi file:", JSON.stringify(xapi))
}

// user and mbox should be personalized!!!!!
function createxapijson() {
  const startup = {
    user: 'testuser',
    mbox: 'mailto:user@example.com',
    interactions: [],
  }
  window.localStorage.setItem('xapi-SAT', JSON.stringify(startup))
  // console.log("created xapi file:", JSON.stringify(startup))
}

module.exports = {
  createxapijson,
  addxapi,
}
