import Plotly from 'plotly.js-basic-dist'
import createPlotlyComponent from 'react-plotly.js/factory'
import React from 'react'

function ReviewDataPlot({ test }) {
  const Plot = createPlotlyComponent(Plotly)
  // console.log(test)
  var y0 =
    (test.exam_score.module.mcorrect /
      (test.exam_score.module.mcorrect + test.exam_score.module.mincorrect)) *
    100

  if (test.moduleNum == 1) {
    var plotdata = [
      {
        x: ['Reading Module 1'],
        y: [y0],
        ylabel: 'score',
        type: 'bar',

        marker: {
          color: ['rgb(0,132,206)'],
        },
      },
    ]
  } else if (test.moduleNum == 2) {
    var plotdata = [
      {
        x: ['Reading Module 2'],
        y: [y0],
        ylabel: 'score',
        type: 'bar',

        marker: {
          color: ['rgb(251,206,20)'],
        },
      },
    ]
  } else if (test.moduleNum == 3) {
    var plotdata = [
      {
        x: ['Math Module 1'],
        y: [y0],
        ylabel: 'score',
        type: 'bar',

        marker: {
          color: ['rgb(206,74,0)'],
        },
      },
    ]
  } else {
    var plotdata = [
      {
        x: ['Math Module 2'],
        y: [y0],
        ylabel: 'score',
        type: 'bar',

        marker: {
          color: ['rgb(206,74,10)'],
        },
      },
    ]
  }
  return (
    <Plot
      data={plotdata}
      layout={{
        font: { size: 16 },
        width: 800,
        height: 400,
        title: 'Analysis',
        xaxis: { title: 'Level' },
        yaxis: { title: 'Scores (%)', range: [0, 100] },
      }}
    />
  )
}

export default ReviewDataPlot
