import React, { useState, useEffect } from 'react'
import {
  IoChevronForwardOutline,
  IoLockClosed,
  IoInformationCircleSharp,
} from 'react-icons/io5'
import { AiOutlineClockCircle } from 'react-icons/ai'
import { BsCheckLg } from 'react-icons/bs'
import parse from 'html-react-parser'
import { axiosPrivate } from '../apis/axios'
import { useLocation, useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import TopBar from '../components/Topbar'
import NavBar from '../components/NavBar'
import useAuth from '../hooks/useAuth'
import { Popover, OverlayTrigger, Tooltip, Button } from 'react-bootstrap'
import DeleteExamModal from '../Modals/DeleteExamModal'
import NoAnswerErrorMessage from '../Modals/NoAnswerErrorMessage'

const xapi = require('../xapi/xapi')

const StartTestContainer = styled.div`
  padding: 50px 0 0 30px;
  overflow: scroll;
`
const ReturnTestContainer = styled.div`
  padding: 50px 0 0 30px;
  overflow: scroll;
`
const StartTestHeader = styled.div`
  font-size: 18px;
  font-weight: bold;
`
const ReturnTestHeader = styled(StartTestHeader)``
const StartTestFlexBox = styled.div`
display: flex;
flex-direction: column;
width: 90%;
border: 1px solid black;
// background-image: linear-gradient(
  to bottom,
  #7e5f8e 60%,
  rgba(255, 255, 255, 0) 40%
);`
const ReturnTestFlexBox = styled(StartTestFlexBox)``
const ReadingContainer = styled.div`
  height: 150px;
`
const SectionMinutesContainer = styled.div`
  padding-left: 50px;
  display: flex;
  align-items: center;
  height: 100%;
  gap: 50px;
  ${(props) => {
    if (props.number === 0 && props.section === 'reading1')
      return `background:#7e5f8e;`
    if (props.number === 3 && props.section === 'math2')
      return `background:#1c758a;`
    if (props.number === 2 && props.section === 'math1')
      return 'background:#1c758a;'
    if (props.number === 1 && props.section === 'reading2')
      return 'background:#7e5f8e;'
    if (props.number < 2 && props.section === 'math1') {
      return `cursor: not-allowed; opacity:0.5;`
    }
    if (props.number <= 2 && props.section === 'math2') {
      return `cursor: not-allowed; opacity:0.5;`
    }
    if (props.number < 1 && props.section === 'reading2')
      return `cursor: not-allowed; opacity:0.5;`
  }}
`
const SectionCircle = styled.div`
  border: 1px solid black;
  width: 70px;
  height: 70px;
  border-radius: 11px;
  ${(props) => {
    if (props.number === 0 && props.section === 'reading1') {
      return `background:#7e5f8e; border-color:white;`
    } else if (props.number === 0 && props.section === 'reading2') {
      return `background:#7e5f8e; border-color:white;`
    } else if (props.number === 0 && props.section === 'math1') {
      return `background: #1c758a; border-color: white;`
    }
    if (props.number === 1 && props.section === 'reading2') {
      return `border-color:white;`
    } else if (props.number === 2 && props.section === 'math1') {
      return `border-color:white;`
    } else if (props.number === 1 && props.section === 'math1') {
      return `background: #1c758a; border-color: white`
    } else if (props.number <= 3 && props.section === 'reading1') {
      return `border-color:#7e5f8e`
    } else if (props.number <= 3 && props.section === 'reading2') {
      return `border-color:#7e5f8e`
    } else if (props.number <= 3 && props.section === 'math1') {
      return `border-color:#1c758a`
    } else if (props.number <= 3 && props.section === 'math2') {
      return `background: #1c758a; border-color: white;`
    }
  }}
`

const StartButton = styled.button`
  background: none;
  color:#2D4A82;
  border: 1px solid #2D4A82;
  cursor: pointer;
  font-size: 15px;
  padding 10px 16px;
  border-radius: 4px;
  &:hover {
    transition-duration: 0.4s;
    color: white;
    background: #2D4A82;
  }
`
const ReviewButton = styled(StartButton)`
  border-color: #f0b73f;
  color: black;
  background: none;
  &:hover {
    transition-duration: 0.4s;
    color: black;
    background: #f0b73f;
  }
`
const TestTotalContainer = styled.div`
  color: #999;
  padding: 10px;
`
const TestTotalHeader = styled.div``
const TestTotalScore = styled.div`
  padding-top: 0px;
  color: #555;
  font-size: calc(2em + 1vw);
  font-weight: bold;
`
const TestSectionContainer = styled.div`
  display: flex;
  flex-direction: column;
  color: #999;
  padding: 10px;
`
const TestSectionDiv = styled.div``
const TestSectionHeader = styled.div``
const TestSectionScore = styled.div`
  padding-top: 0px;
  color: #555;
  font-size: calc(1em + 1vw);
  font-weight: bold;
`

const FullTestContainer = styled.div`
  // border-top: 1px #8e9193 solid;
  height: 100vh;
  // display: flex;
`
const WelcomeHeader = styled.div`
  font-size: calc(1em + 1vw);
  text-align: center;
  background: #e6f8fc;
`
const ListHeader = styled.div`
  padding-top: 50px;
  width: 80vw;
  margin: 0 auto;
`
const ListOfTestContainer = styled.div`
  background: #f7f7f7;
  overflow: scroll;
  height: 92vh;
  // width: 30vw;
  // min-width: 350px;
  // height: 100vh;
`

const ListDiv = styled.div`
  // margin-right: 0px;
  // padding-top: 25px;
  // display: flex;
  // padding-left: 0px;
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  width: 80vw;
  margin: 0 auto;
  padding-bottom: 20px;
`
const ListIndividualDiv = styled.div`
  margin: 0 auto;
  height: 200px;
  width: 400px;
  border-radius: 20px;
  box-shadow: 1px 1px 9px 0px rgba(0, 0, 0, 0.3);
  font-size: 20px;
`
const FinishListIndividualDiv = styled.div`
  margin: 0 auto;
  height: max-content;
  width: 400px;
  border-radius: 20px;
  box-shadow: 1px 1px 9px 0px rgba(0, 0, 0, 0.3);
  font-size: 20px;
`
const TestDataContainer = styled.div``
const TopContainer = styled.div`
  background: #355799;
  color: white;
  text-align: center;
  padding: 10px;
  border-radius: 20px 20px 0px 0px;
  height: 50px;
`
const BottomContainer = styled.div`
  display: flex;
  position: relative;
  height: 150px;
`
const FinishBottomContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  position: relative;
  height: max-content;
  align-items: center;
  gap: 20px;
`
const ButtonContainer = styled.div`
  display: flex;
  gap: 10px;
  position: absolute;
  right: 10px;
  bottom: 20px;
`
const PastExamsContainer = styled.div`
  padding-top: 30px;
  width: 80vw;
  margin: 0 auto;
`
const HeaderDiv = styled.div`
  font-size: calc(1em + 1vw);
  font-weight: 700;
`
const TestScores = styled.div`
  font-size: 15px;
  line-height: 18px;
`
const TestStatus = styled.div`
  font-size: 15px;
  line-height: 18px;
  padding: 20px 10px;
`

function ExamLanding() {
  const location = useLocation()
  const navigate = useNavigate()
  const [testData, setTestData] = useState('')
  const [pageIndex, setPageIndex] = useState(0)
  const [qNumber, setQNumber] = useState(0)
  const [availableExamsForSubscription, setAvailableExamsForSubscription] =
    useState()
  const [userTestData, setUserTestData] = useState([])
  const [modalShow, setModalShow] = useState(false)

  const [modalShowTrigger, setModalShowTrigger] = useState(false)
  const [showDetails, setShowDetails] = useState(false)
  const [examCompleteCheck, setExamCompleteCheck] = useState([])
  const [errorMessageModalShow, setErrorMessageModalShow] = useState(false)

  const [refresh, setRefresh] = useState(false)

  const { auth } = useAuth()
  useEffect(() => {
    if (auth.user_type == 'Dev') {
      navigate('/dev')
    }
  }, [])
  useEffect(() => {
    const getExams = async () => {
      try {
        // const resp = await axiosPrivate.get('/userexams/', {
        //   headers: { authorization: 'Bearer ' + auth.accessToken },
        //   params: { uuid: auth.UUID },
        // })
        const resp = await axiosPrivate.get('/userexams2/', {
          headers: { authorization: 'Bearer ' + auth.accessToken },
          // params: { uuid: auth.UUID },
        })

        // console.log('getexam1', resp.data)
        // console.log('getexam2', resp2.data)
        // const resp3 = await axiosPrivate.get('/examdata/', {
        //   headers: { authorization: 'Bearer ' + auth.accessToken },
        //   params: { public_id: resp2.data.available?.assignments[0].public_id },
        //   // params: { uuid: auth.UUID },
        // })
        // const resp4 = await axiosPrivate.get('/examdata/', {
        //   headers: { authorization: 'Bearer ' + auth.accessToken },
        //   params: { public_id: resp2.data.available.assignments[1].public_id },
        //   // params: { uuid: auth.UUID },
        // })
        // const resp5 = await axiosPrivate.get('/examdata/', {
        //   headers: { authorization: 'Bearer ' + auth.accessToken },
        //   params: { public_id: resp2.data.available.assignments[2].public_id },
        //   // params: { uuid: auth.UUID },
        // })
        // const resp6 = await axiosPrivate.get('/examdata/', {
        //   headers: { authorization: 'Bearer ' + auth.accessToken },
        //   params: { public_id: resp2.data.available.assignments[3].public_id },
        //   // params: { uuid: auth.UUID },
        // })
        // console.log('getexam3', resp3.data, resp4.data, resp5.data, resp6.data)
        // setUserTestData(
        //   resp.data.data.dList
        //     .slice(0)
        //     .sort((a, b) => Date.parse(b.start_date) - Date.parse(a.start_date))
        // )
        // console.log('assignments: ', resp)
        setUserTestData(resp.data.assignments)
        if (auth.user_type == 'Individual') {
          const resp2 = await axiosPrivate.get(
            '/subscription/availableexams/',
            {
              headers: { authorization: 'Bearer ' + auth.accessToken },
            }
          )
          console.log('available exams' + resp2.data)
          setAvailableExamsForSubscription(resp2.data)
        }
        // console.log(resp2.data, resp.data)
        // setExamCompleteCheck(
        //   resp.data.data.dList.map((test) => test.completed).includes(false)
        // )
      } catch (err) {
        console.log(err)
      }
    }
    getExams()
  }, [modalShowTrigger, refresh])

  useEffect(() => {
    if (userTestData) {
      const testInitalizer = {
        index: 0,
        ...userTestData[0],
      }
      setTestData(testInitalizer)
    }
  }, [userTestData])

  const startExam = async () => {
    try {
      // const resp = await axiosPrivate.post('/startdemoexam/', {
      //   headers: { authorization: 'Bearer ' + auth.accessToken },
      // })
      // let newusertestdata = {
      //   exam_json: resp.data,
      //   completed: false,
      //   exam_id: resp.data.exam_id,
      //   exam_score: null,
      //   skill_category: null,
      //   start_date: resp.data.start_date,
      //   exam_section: 0,
      // }
      const resp2 = await axiosPrivate.get('/userexams2/', {
        headers: { authorization: 'Bearer ' + auth.accessToken },
        // params: { uuid: auth.UUID },
      })
      const resp = await axiosPrivate.get('/examdata/', {
        headers: { authorization: 'Bearer ' + auth.accessToken },
        params: { public_id: resp2.data.assignments[0].public_id },
        // params: { public_id: resp2.data.available.assignments[0].public_id }
        // params: { uuid: auth.UUID },
      })
      resp.data.exam_section = '1'
      navigate('/exam/' + resp2.data.assignments[0].public_id)
      // navigate('/exam/' + resp2.data.available.assignments[0].public_id, { state: resp.data })
      // navigate('/exam/' + resp.data.exam_id, { state: newusertestdata })
      //setUserTestData([newusertestdata])
      // console.log(resp.data)
    } catch (err) {
      console.log(err)
    }
    // navigate('../exam/' + 'assessment')
    // try {
    //   const resp = await axiosPrivate.post(
    //     '/exam/start/',
    //     { data: { uuid: auth.UUID } },
    //     { headers: { authorization: 'Bearer ' + auth.accessToken } }
    //   )
    //   navigate('../exam/' + 'assessment', { state: resp.data })
    // } catch (err) {
    //   console.log(err)
    // }
  }
  useEffect(() => {
    setRefresh(!refresh)
  }, [modalShowTrigger])

  const resumeExam = async (test) => {
    navigate('/exam/' + test.public_id)
  }

  const reviewExam = async (test) => {
    if (test.public_id) {
      navigate('../exam/' + test.public_id + '/review', {
        state: test,
        replace: true,
      })
    } else {
      navigate('../exam/' + test.form_code + '/review', {
        state: test,
        replace: true,
      })
    }
  }

  const deleteExamModal = () => {
    setModalShow(true)
  }

  const closeDeleteModal = () => {
    setModalShow(false)
    setModalShowTrigger(!modalShowTrigger)
  }

  const closeErrorMessageModal = () => {
    setErrorMessageModalShow(false)
  }
  const showErrorMessage = () => {
    setErrorMessageModalShow(true)
  }

  const addTest = async (form_code) => {
    try {
      const resp = await axiosPrivate.post(`/individual/addtest/`, {
        headers: { authorization: 'Bearer ' + auth.accessToken },
        form_code: form_code,
      })
      setRefresh(!refresh)
    } catch (err) {
      console.log(err)
    }
  }
  return userTestData ? (
    <>
      <TopBar />

      <FullTestContainer>
        <ListOfTestContainer>
          <WelcomeHeader>
            Welcome, {auth.username}! Practice and get ready for your test date.
          </WelcomeHeader>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              gap: '20px',
            }}
          >
            {availableExamsForSubscription?.map((list, index) => {
              return (
                <Button onClick={() => addTest(list.form_code)}>
                  start new {list.form_code}
                </Button>
              )
            })}
          </div>
          <ListHeader>
            <HeaderDiv> Active Practice Exams </HeaderDiv>
          </ListHeader>
          <ListDiv>
            {userTestData.map((list, index) => {
              if (list.completed == false && list.submitted != true) {
                return (
                  <ListIndividualDiv key={index}>
                    <TestDataContainer>
                      <TopContainer>
                        Test {index + 1}:{' '}
                        {list.test_form?.form_code
                          ? list.test_form.form_code
                          : list.form_code}
                      </TopContainer>
                      <BottomContainer>
                        <TestStatus>
                          {' '}
                          <AiOutlineClockCircle /> In Progress
                        </TestStatus>
                        <ButtonContainer>
                          {/* <ResetButton onClick={deleteExamModal}>
                            Delete This Test
                          </ResetButton> */}
                          <StartButton onClick={() => resumeExam(list)}>
                            Resume Exam
                          </StartButton>
                        </ButtonContainer>
                      </BottomContainer>
                    </TestDataContainer>

                    {/* <IoChevronForwardOutline /> */}
                  </ListIndividualDiv>
                )
              } else if (list.completed == null && list.submitted == false) {
                return (
                  <ListIndividualDiv key={index}>
                    <TestDataContainer>
                      <TopContainer>
                        Test {index + 1}:{' '}
                        {list.test_form?.form_code
                          ? list.test_form.form_code
                          : list.form_code}
                      </TopContainer>
                      <BottomContainer>
                        <TestStatus>
                          {' '}
                          <AiOutlineClockCircle /> New Test
                        </TestStatus>
                        <ButtonContainer>
                          {/* <StartButton onClick={deleteExamModal}>
                            Delete This Test
                          </StartButton> */}
                          <StartButton onClick={() => resumeExam(list)}>
                            Start Exam
                          </StartButton>
                        </ButtonContainer>
                      </BottomContainer>
                    </TestDataContainer>

                    {/* <IoChevronForwardOutline /> */}
                  </ListIndividualDiv>
                )
              }
            })}
          </ListDiv>
          <PastExamsContainer>
            <HeaderDiv>Previous Practice Exams</HeaderDiv>
          </PastExamsContainer>
          <ListDiv>
            {userTestData.map((list, index) => {
              console.log('list', list)
              if (list.submitted == true) {
                if (list.grades?.is_pending) {
                  return (
                    <FinishListIndividualDiv
                      key={index}
                      selected={testData.id === list.id}
                    >
                      <TestDataContainer>
                        <TopContainer>
                          Test {index + 1}:{' '}
                          {list.test_form?.form_code
                            ? list.test_form.form_code
                            : list.form_code}
                        </TopContainer>
                        <FinishBottomContainer
                          style={{ justifyContent: 'center' }}
                        >
                          <TestTotalContainer>
                            <TestTotalHeader>Score:</TestTotalHeader>
                            <TestTotalScore style={{ fontSize: 20 }}>
                              Pending
                            </TestTotalScore>
                          </TestTotalContainer>

                          {/* <ButtonContainer>
                            
                            <ReviewButton onClick={() => reviewExam(list)}>
                              Review Exam
                            </ReviewButton>
                          </ButtonContainer> */}
                        </FinishBottomContainer>
                      </TestDataContainer>

                      {/* <IoChevronForwardOutline /> */}
                    </FinishListIndividualDiv>
                  )
                } else {
                  return (
                    <FinishListIndividualDiv
                      key={index}
                      // onClick={() => {
                      //   const listWithIndex = { index: index, ...list }
                      //   setTestData(listWithIndex)
                      // }}
                      selected={testData.id === list.id}
                    >
                      <TestDataContainer>
                        <TopContainer>
                          Test {index + 1}:{' '}
                          {list.test_form?.form_code
                            ? list.test_form.form_code
                            : list.form_code}
                        </TopContainer>
                        <FinishBottomContainer>
                          {list.grades?.scores &&
                          list.grades?.scores.length > 2 ? (
                            <>
                              <TestTotalContainer>
                                <TestTotalHeader>Total</TestTotalHeader>
                                <TestTotalScore>
                                  {parseInt(
                                    list.grades.scores[2].scaled_score
                                  ).toFixed(0)}
                                </TestTotalScore>
                              </TestTotalContainer>
                              <TestSectionContainer>
                                <TestSectionDiv>
                                  <TestSectionHeader>
                                    Reading and Writing
                                  </TestSectionHeader>
                                  <TestSectionScore>
                                    {parseInt(
                                      list.grades.scores[0].scaled_score
                                    ).toFixed(0)}
                                  </TestSectionScore>
                                </TestSectionDiv>
                                <TestSectionDiv>
                                  <TestSectionHeader>Math</TestSectionHeader>
                                  <TestSectionScore>
                                    {parseInt(
                                      list.grades.scores[1].scaled_score
                                    ).toFixed(0)}
                                  </TestSectionScore>
                                </TestSectionDiv>
                              </TestSectionContainer>
                            </>
                          ) : (
                            <>
                              <TestTotalContainer>
                                <TestTotalHeader>
                                  Scaled scores unavailable
                                </TestTotalHeader>
                                <TestTotalScore></TestTotalScore>
                              </TestTotalContainer>
                              <TestSectionContainer>
                                <TestSectionHeader>
                                  You can still review
                                </TestSectionHeader>
                              </TestSectionContainer>
                              <TestSectionContainer></TestSectionContainer>
                            </>
                          )}{' '}
                          <ButtonContainer>
                            {/* <ResetButton onClick={deleteExamModal}>
                            Delete This Test
                          </ResetButton> */}
                            <ReviewButton onClick={() => reviewExam(list)}>
                              Review Exam
                            </ReviewButton>
                          </ButtonContainer>
                        </FinishBottomContainer>
                      </TestDataContainer>

                      {/* <IoChevronForwardOutline /> */}
                    </FinishListIndividualDiv>
                  )
                }
              }
            })}
          </ListDiv>
        </ListOfTestContainer>
        {/* <TestContainer>{resultsSwitch({ test: testData })}</TestContainer> */}
        <DeleteExamModal
          show={modalShow}
          onHide={closeDeleteModal}
          test={testData}
        />
        <NoAnswerErrorMessage
          show={errorMessageModalShow}
          onHide={closeErrorMessageModal}
        />
        {/* <div>{parse('')}</div> */}
      </FullTestContainer>
    </>
  ) : (
    <></>
  )
}

export default ExamLanding
