import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'

const ReviewContainer = styled.div`
  height: 100vh;
  display: flex;
  flex-direction: column;
`
const ReviewHeader = styled.div`
  padding: 10px 0 10px 0;
  display: flex;
  justify-content: space-around;
  border-bottom: 1px solid rgba(33, 36, 44, 0.16);
`
const SectionContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`
const SectionTime = styled.div`
  display: flex;
  flex-direction: column;
`
const SectionCircle = styled.div`
  height: 30px;
  width: 30px;
  color: white;
  border: 2px solid #644172;
  border-radius: 5px;
  background: #644172;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-weight: bold;
`
const FTop = styled.div`
  font-size: 15px;
`
const FBot = styled.div`
  font-size: 10px;
`
const SectionTitle = styled.div``
const ReturnButton = styled.button`
  cursor: pointer;
  outline: none;
  background: none;
  border: none;
  font-size: 16px;
  font-weight: bold;
  color: #1586ca;
`

const ReadingContainer = styled.div`
  flex: 1 1 auto;
  margin: 0 auto;
  color: #555;
  margin-top: 80px;
  width: 65%;
`
const ReadingHeader = styled.span`
  text-transform: uppercase;
`

const ReadingResultsHeader = styled.div`
  font-size: 24px;
  margin-top: 20px;
`
const ReadingResultsContainer = styled.div`
  padding-top: 25px;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: space-evenly;
`
const CorrectDiv = styled.div`
  width: 200px;
  border-left: 1px solid rgba(33, 36, 44, 0.16);
  border-right: 1px solid rgba(33, 36, 44, 0.16);
  text-align: center;
`
const RTop = styled.div`
  font-size: 44px;
  color: ${(props) => props.color};
`
const RBot = styled.div`
  text-transform: uppercase;
  font-weight: bold;
  color: ${(props) => props.color};
`
const IncorrectDiv = styled.div`
  width: 200px;
  text-align: center;
  border-left: 1px solid rgba(33, 36, 44, 0.16);
  border-right: 1px solid rgba(33, 36, 44, 0.16);
`
const NADiv = styled.div`
  width: 200px;
  text-align: center;
  border-left: 1px solid rgba(33, 36, 44, 0.16);
  border-right: 1px solid rgba(33, 36, 44, 0.16);
`
const ResultsFooterContainer = styled.div`
  display: flex;
  justify-content: space-around;
  border-top: 1px solid rgba(33, 36, 44, 0.16);
  vertical-align: baseline;
`
const ReviewButton = styled.button`
  background: #1586ca;
  font-size: 15px;
  border-radius: 4px;
  border: none;
  color: white;
  padding: 10px 20px;
  margin: 10px 0 10px 0;
  cursor: pointer;
`

function ReadingExamResults({ test }) {
  const navigate = useNavigate()
  return (
    <>
      <ReviewContainer>
        <ReviewHeader>
          <SectionContainer>
            <SectionTime>
              <SectionCircle>
                <FTop>32</FTop>
                <FBot>min</FBot>
              </SectionCircle>
            </SectionTime>
            {test.exam_section.section === 'r1' ? (
              <SectionTitle> Reading and Writing Module 1 </SectionTitle>
            ) : (
              <SectionTitle> Reading and Writing Module 2</SectionTitle>
            )}
          </SectionContainer>
          <ReturnButton onClick={() => navigate(-1)}>
            Return to the list of tests
          </ReturnButton>
        </ReviewHeader>
        <ReadingContainer>
          {test.exam_section.section === 'r1' ? (
            <>
              <ReadingHeader>
                Reading and Writingw Module 1 Complete
              </ReadingHeader>
              <ReadingResultsHeader>RW Module 1 Results</ReadingResultsHeader>
            </>
          ) : (
            <>
              <ReadingHeader>
                Reading and Writing Module 2 Complete
              </ReadingHeader>
              <ReadingResultsHeader>RW Module 2 Results</ReadingResultsHeader>{' '}
            </>
          )}

          <ReadingResultsContainer>
            <CorrectDiv>
              <RTop color={'#090'}>{test.exam_section.score.correct}</RTop>
              <RBot color={'#090'}>Correct</RBot>
            </CorrectDiv>
            <IncorrectDiv>
              <RTop color={'#900'}>
                {27 -
                  test.exam_section.score.na -
                  test.exam_section.score.correct}
              </RTop>
              <RBot color={'#900'}>Incorrect</RBot>
            </IncorrectDiv>
            <NADiv>
              <RTop color={'#555'}>{test.exam_section.score.na}</RTop>
              <RBot color={'#555'}>Not Answered</RBot>
            </NADiv>
          </ReadingResultsContainer>
        </ReadingContainer>
        <ResultsFooterContainer>
          <div></div>
          <ReviewButton>Review your answers</ReviewButton>
        </ResultsFooterContainer>
      </ReviewContainer>
    </>
  )
}

export default ReadingExamResults
