import React, { useState, useContext } from 'react'
import styled from 'styled-components'
import useAuth from '../hooks/useAuth'
import { useLocation } from 'react-router-dom'
import TopBar from '../components/Topbar'
import NavBar from '../components/NavBar'

const NavBarContainer = styled.div``
const DashboardContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 30px;
  margin: 10px;
  height: 100vh;
  background: #f7f7f7;
`
const TodayGoalContainer = styled.div`
  margin: 0;
  background: #2088c8;
  height: 350px;
  width: 100vw;
`
const CurrentSkillContainer = styled.div`
  border: 1px #d9d9d9 solid;
  width: 670px;
  height: 380px;
  background: white;
`
const CurrentSkillTextContainer = styled.div``
const CurrentSkillHeader = styled.div`
  // font-family: 'Inter';
  font-weight: 600;
  font-size: 24px;
  line-height: 29px;
  padding: 35px 24px 35px 24px;
  border-bottom: 1px solid #d9d9d9;
`
const CurrentSkillBody = styled.div`
  display: flex;
  justify-content: space-around;
  padding-top: 30px;
`
const CSReadingWriting = styled.div`
  display: flex;
  gap: 20px;
  align-items: center;
  font-size: 24px;
  line-height: 29px;
  font-weight: 600;
  color: #7e608d;
`
const CSMath = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
  font-size: 24px;
  line-height: 29px;
  font-weight: 600;
  color: #227589;
`
const MathSVG = styled.svg``
const ReadingSVG = styled.svg``

const CSSpan = styled.div`
  // margin: 0 auto;
  margin-top: 40px;
  text-align: center;
`
const Container2 = styled(CurrentSkillContainer)``

const Container2Text = styled(CurrentSkillTextContainer)``

const ButtonDiv = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 20px;
`
const Button = styled.button`
  height: 30px;
  font-size: 20px;
`

function Dashboard() {
  const { auth } = useAuth()
  const location = useLocation()

  return (
    <>
      <TopBar />
      <NavBarContainer>
        <NavBar location={location} />
      </NavBarContainer>
      <TodayGoalContainer></TodayGoalContainer>
      <DashboardContainer>
        <CurrentSkillContainer>
          <CurrentSkillTextContainer>
            <CurrentSkillHeader>Current Skill Levels</CurrentSkillHeader>
            <CurrentSkillBody>
              <CSReadingWriting>
                <ReadingSVG
                  width='40'
                  height='40'
                  viewBox='0 0 40 40'
                  fill='none'
                  xmlns='http://www.w3.org/2000/svg'>
                  <path
                    d='M0 6C0 2.68629 2.68629 0 6 0H34C37.3137 0 40 2.68629 40 6V34C40 37.3137 37.3137 40 34 40H6C2.68629 40 0 37.3137 0 34V6Z'
                    fill='#7E608D'
                  />
                  <path
                    d='M25.4885 11.2516C26.917 11.2223 28.567 11.6188 30.2896 12.3941C30.7258 12.5905 31 13.0453 31 13.5328V27.5016C31 27.9527 30.542 28.2652 30.1425 28.0738C25.3186 25.7637 21.7474 28.5035 21.5946 28.6211C21.4858 28.707 21.3559 28.75 21.2222 28.75C20.7486 28.75 20.6111 28.3055 20.6111 28.1258V13.1266C20.6111 12.9233 20.7078 12.7328 20.8701 12.6156C20.9434 12.5617 22.7233 11.3027 25.4885 11.2516ZM9.71042 12.3941C11.4334 11.6211 13.0899 11.2375 14.5115 11.2512C17.2783 11.3024 19.0222 12.5617 19.1292 12.6152C19.2934 12.7328 19.3889 12.923 19.3889 13.1266V28.0914C19.3889 28.2969 19.2552 28.75 18.7778 28.75C18.6447 28.75 18.5128 28.7061 18.4042 28.62C18.2543 28.5021 14.6825 25.7622 9.85628 28.0731C9.45677 28.2644 8.99882 27.9518 8.99882 27.5009L8.99881 13.5321C9.03437 13.0457 9.275 12.5902 9.71042 12.3941Z'
                    fill='#F3DBFF'
                  />
                </ReadingSVG>
                Reading and Writing
              </CSReadingWriting>
              <CSMath>
                <MathSVG
                  width='40'
                  height='40'
                  viewBox='0 0 40 40'
                  fill='none'
                  xmlns='http://www.w3.org/2000/svg'>
                  <path
                    d='M0 6C0 2.68629 2.68629 0 6 0H34C37.3137 0 40 2.68629 40 6V34C40 37.3137 37.3137 40 34 40H6C2.68629 40 0 37.3137 0 34V6Z'
                    fill='#227589'
                  />
                  <path
                    d='M13.0497 17.2266V8.37997H15.0568V17.2266H13.0497ZM9.62997 13.8068V11.7997H18.4766V13.8068H9.62997Z'
                    fill='white'
                  />
                  <path
                    d='M30.8512 11.5952V13.5H24.9578V11.5952H30.8512Z'
                    fill='white'
                  />
                  <path
                    d='M11.081 22.1818L13.0625 25.8061L15.076 22.1818H17.5241L14.5646 27.0909L17.5753 32H15.1399L13.0625 28.4652L11.0043 32H8.54972L11.5412 27.0909L8.62642 22.1818H11.081Z'
                    fill='white'
                  />
                  <path
                    d='M31.7206 25.8445V27.7685H23.0274V25.8445H31.7206ZM27.374 31.6804C26.9819 31.6804 26.6474 31.544 26.3704 31.2713C26.0977 30.9943 25.9613 30.6598 25.9613 30.2678C25.9613 29.8842 26.0977 29.5561 26.3704 29.2834C26.6474 29.0107 26.9819 28.8743 27.374 28.8743C27.7575 28.8743 28.0856 29.0107 28.3583 29.2834C28.6311 29.5561 28.7674 29.8842 28.7674 30.2678C28.7674 30.6598 28.6311 30.9943 28.3583 31.2713C28.0856 31.544 27.7575 31.6804 27.374 31.6804ZM27.374 24.7322C27.114 24.7322 26.8754 24.6705 26.6581 24.5469C26.445 24.419 26.2745 24.2486 26.1467 24.0355C26.0231 23.8182 25.9613 23.5795 25.9613 23.3196C25.9613 22.9361 26.0977 22.608 26.3704 22.3352C26.6474 22.0625 26.9819 21.9261 27.374 21.9261C27.7575 21.9261 28.0856 22.0625 28.3583 22.3352C28.6311 22.608 28.7674 22.9361 28.7674 23.3196C28.7674 23.7074 28.6311 24.0398 28.3583 24.3168C28.0856 24.5937 27.7575 24.7322 27.374 24.7322Z'
                    fill='white'
                  />
                </MathSVG>
                Mathematics
              </CSMath>
            </CurrentSkillBody>
            <CSSpan>
              <p>To set your current skill levels, complete your full tests.</p>
            </CSSpan>
          </CurrentSkillTextContainer>
        </CurrentSkillContainer>
        <Container2>
          <Container2Text>Container 2 </Container2Text>
        </Container2>
      </DashboardContainer>
    </>
  )
}

export default Dashboard
