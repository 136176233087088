import React from 'react'
import { Route, Routes, Navigate, Link } from 'react-router-dom'
import Login from './pages/Login'
import SignUp from './pages/SignUp'
import RequireAuth from './components/RequireAuth'
import { Layout } from './components/Layout'
import LinkPage from './pages/LinkPage'
import PersistLogin from './components/PersistLogin'
import CreateTicketPage from './DevPages/CreateTicket'
import ExamLanding from './pages/ExamLanding'
import 'bootstrap/dist/css/bootstrap.min.css'
import ExamResults from './pages/ExamResults'
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css'
import Dashboard from './pages/Dashboard'
import IndividualExam from './pages/IndividualExam'
import Details from './pages/Details'
import { MathJaxContext, MathJax } from 'better-react-mathjax'
import useAxiosPrivate from './hooks/useAxiosPrivate'
import ExamReview from './pages/ExamReview'
import ReadingExam from './pages/ReadingExam'
import MathExam from './pages/MathExam'
import AccountSubscription from './pages/AccountSubscription'
import DevLanding from './DevPages/DevLanding'
import ViewTickets from './DevPages/ViewTickets'
import RequireAuthDev from './components/RequireAuthDev'
const xapi = require('./xapi/xapi')

function App() {
  const uap = useAxiosPrivate()
  window.console.log = () => {}

  xapi.createxapijson()
  xapi.addxapi('1', '3')
  const config = {
    'fast-preview': {
      disabled: false,
    },
    tex2jax: {
      inlineMath: [
        ['$', '$'],
        ['\\(', '\\)'],
      ],
      displayMath: [
        ['$$', '$$'],
        ['\\[', '\\]'],
      ],
      // processEscapes: true,
    },
    TeX: {
      Macros: {
        Segment: ['{\\overline{#1}}', 1],
        Set: ['\\(\\left\\{ #1 \\right\\}\\)', 1],
        Tuple: ['\\({\\left(#1\\right)}\\)', 1],
        Ang: '\\angle',
        '(': '',
        ')': '',
        num: ['\\text{#1}', 1],
      },
    },
    messageStyle: 'none',
    renderMode: 'post',
  }
  return (
    <MathJaxContext
      version={2}
      config={config}
      onStartup={(mathJax) => (mathJax.Hub.processSectionDelay = 0)}
    >
      <Routes>
        <Route path='/' element={<Layout />}>
          <Route path='login' element={<Login />} />
          <Route path='signup' element={<SignUp />} />
          {/* <Route path='linkpage' element={<LinkPage />} /> */}
          {/* <Route path='details/:id' element={<Details />} /> */}
          <Route path='createticket' exact element={<CreateTicketPage />} />
          {/* <Route path='dashboard' element={<Dashboard />} /> */}
          {/* <Route path='exam' exact element={<Exam />} /> */}

          {/* Protected */}

          <Route element={<PersistLogin />}>
            <Route element={<RequireAuthDev />}>
              <Route path='dev' exact element={<DevLanding />} />
              <Route path='dev/tickets' exact element={<ViewTickets />} />
            </Route>
            <Route element={<RequireAuth />}>
              <Route
                path='account/subscription'
                exact
                element={<AccountSubscription />}
              />
              <Route path='/' element={<Navigate replace to='exam' />} />
              <Route path='exam' exact element={<ExamLanding />} />
              <Route path='exam/:id'>
                <Route index element={<IndividualExam />} />
                <Route path='reading' element={<ReadingExam />} />
                <Route path='math' element={<MathExam />} />
                <Route path='results' exact element={<ExamResults />} />
                <Route path='review' element={<ExamReview />} />
              </Route>
              {/* <Route path='/' element={<Home />} /> */}
            </Route>
          </Route>
        </Route>
      </Routes>
    </MathJaxContext>
  )
}

export default App
