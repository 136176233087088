import React, { useState, useRef, useEffect } from 'react'
import styled from 'styled-components'
import useAuth from '../hooks/useAuth'
import useLogout from '../hooks/useLogout'
import useXapi from '../hooks/useXapi'
import { useNavigate } from 'react-router-dom'

const MenuContainer = styled.div`
  position: relative;
`
const MenuButton = styled.button`
  background: #ffffff;
  border-radius: 90px;
  cursor: pointer;
  display: flex;
  gap: 20px;
  align-items: center;
  padding: 4px 6px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.3);
  border: none;
  vertical-align: middle;
  transition: box-shadow 0.4s ease;
  &:hover {
    box-shadow: 0 1px 8px rgba(0, 0, 0, 0.3);
  }
`

const MenuUser = styled.span`
  font-family: roboto;
  font-weight: 700;
  vertical-align: middle;
  font-size: 14px;
  margin: 0 10px;
`

const Menu = styled.div`
  background: #ffffff;
  z-index: 2;
  border-radius: 8px;
  position: absolute;
  top: 60px;
  right: 0;
  width: 200px;

  box-shadow: 0 1px 8px rgba(0, 0, 0, 0.3);
  opacity: 0;
  visibility: hidden;
  tranform: translateY(-20px);
  transition: opacity 0.4s ease, transform 0.4s ease, visibility 0.4s;

  ${(props) =>
    props.active
      ? `
        opacity: 1;
        visibility: visible;
        transform: translateY(0);
        `
      : null}
`
const List = styled.ul`
  font-family: roboto;
  list-style: none;
  padding: 0px;
  margin: 0;
`
const ListItem = styled.li`
  border-bottom: 1px solid #dddddd;
  //   text-align: center;
  padding: 15px 20px;
  cursor: pointer;
  &:hover {
    border-radius: 8px;
    color: white;
    background: linear-gradient(145deg, #9cc5ff, #2b81f7, #2b81f7);
    // box-shadow: 0 1px 8px rgba(0, 0, 0, 0.3);
  }
`
const ProfileImage = styled.img`
  width: 40px;
  border-radius: 50%;
`

const UserDropdownMenu = () => {
  const dropdownRef = useRef(null)
  const [isActive, setisActive] = useState(false)
  const { auth } = useAuth()
  const navigate = useNavigate()
  //   const onClick = () => setisActive(!isActive)
  const logout = useLogout()
  const sendxapi = useXapi()

  //   useEffect(() => {
  //     const pageClickEvent = (e) => {
  //       console.log(dropdownRef)
  //       console.log(e.target)
  //       if (
  //         dropdownRef.current !=== null &&
  //         !dropdownRef.current.contains(e.target)
  //       ) {
  //         console.log(e)
  //         // setisActive(!isActive)
  //       }
  //     }
  //     if (isActive) {
  //       console.log('in active')
  //       window.addEventListener('click', pageClickEvent)
  //     }
  //     return () => {
  //       window.removeEventListener('click', pageClickEvent)
  //     }
  //   }, [isActive])

  return (
    <MenuContainer>
      <MenuButton
        onClick={() => setisActive(!isActive)}
        onBlur={() => setisActive(!isActive)}
      >
        <ProfileImage src='/blank_profile.png' alt='image' />{' '}
        <MenuUser> {auth.username}</MenuUser>
      </MenuButton>
      <Menu active={isActive} ref={dropdownRef}>
        <List>
          {/* <ListItem> Account Settings </ListItem> */}
          {/* {auth.user_type == 'Individual' ? (
            <ListItem
              onClick={() => {
                navigate('/account/subscription/')
              }}
            >
              {' '}
              Change Subscription{' '}
            </ListItem>
          ) : (
            <></>
          )} */}
          <ListItem
            onClick={() => {
              sendxapi()
              logout()
            }}
          >
            {' '}
            Logout{' '}
          </ListItem>
        </List>
      </Menu>
    </MenuContainer>
  )
}

export default UserDropdownMenu
