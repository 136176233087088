import React, { useState, useEffect } from 'react'
import useAuth from '../hooks/useAuth'
import { axiosPrivate } from '../apis/axios'
import TopBar from '../components/Topbar'
import styled from 'styled-components'
import parse from 'html-react-parser'
import { useProSidebar } from 'react-pro-sidebar'
import { GiHamburgerMenu } from 'react-icons/gi'
import DeveloperSidebar from '../components/DeveloperSidebar'
import { AiFillCheckCircle } from 'react-icons/ai'
const TicketCardContainer = styled.div`
  background: #355799;
  display: flex;
  height: 92vh;
  gap: 40px;
`
const TicketCard = styled.div`
  background-color: white;
  height: content;
  border-radius: 20px;
  padding: 20px;
  width: 95%;
`
const TicketsDiv = styled.div`
  padding-top: 40px;
  width: 100%;
  overflow: auto;
`
const SidebarContainer = styled.div`
  display: flex;
  height: 100%;
`
const HamburgerButton = styled.button`
  width: 100px;
  cursor: pointer;
  border: none;
  background: none;
  height: 20px;
`
const OkCheck = styled.div`
  display: flex;
  flex-direction: row;
  gap: 30px;
  // color: #0a6073;

  ${(props) => (props.check ? `display:flex` : `display:none`)}
`

const TicketListPage = () => {
  //const [videoData, setVideoData] = useState()
  const { auth } = useAuth()
  const [ticketList, setTicketList] = useState()
  const [showTelemetry, setShowTelemetry] = useState([])
  const [toggled, setToggled] = useState(false)
  const [broken, setBroken] = useState(false)

  useEffect(() => {
    const getTicketList = async () => {
      try {
        const resp = await axiosPrivate.get('/dev/tickets/list/')
        console.log('tickets:', resp.data)
        setTicketList(resp.data)
      } catch (err) {
        console.log(err)
      }
    }

    getTicketList()
  }, [])

  if (ticketList !== undefined) {
    return (
      <>
        <TopBar color={'#9AB5CE'} titleName={'Tickets'} />
        <TicketCardContainer>
          <SidebarContainer>
            <DeveloperSidebar
              setToggled={setToggled}
              toggled={toggled}
              setBroken={setBroken}
              broken={broken}
            />
          </SidebarContainer>
          {broken && (
            <HamburgerButton
              className='sb-button'
              onClick={() => setToggled(true)}
            >
              <GiHamburgerMenu size={40} />
            </HamburgerButton>
          )}
          <TicketsDiv>
            {ticketList.map((data, idx) => {
              return (
                <>
                  <TicketCard>
                    <h1>
                      Date created:{' '}
                      {new Date(data.date_created).toLocaleString()}
                      {/* Date created: {new Date(data.date_created).toLocaleString()} */}
                    </h1>
                    {data.date_closed ? (
                      <p>
                        Date closed:{' '}
                        {new Date(data.date_closed).toLocaleString()}
                      </p>
                    ) : (
                      <></>
                    )}
                    <h3>Submitted by: {data.submitted_by}</h3>
                    <h3>Contact Email: {data.contact_email}</h3>
                    <OkCheck check={data.ok_to_contact}>
                      <h3>Ok to Contact:</h3>
                      <AiFillCheckCircle color={'#0a6073'} size={30} />
                    </OkCheck>

                    <h3>User Type: {data.user_type}</h3>
                    <h3>Ticket ID: {data.ticket_id}</h3>
                    <h3>Report: </h3>
                    <>{parse(data.report)}</>
                    <h3>status: </h3>
                    <p>{data.status}</p>
                    <h3>Dev Notes: </h3>
                    <p>{data.se2_notes}</p>
                    <h3>Telemetry:</h3>
                    <button
                      onClick={() => {
                        console.log('updating ticket list', ticketList)
                        let tmplist = [...ticketList]
                        tmplist[idx].showTelemetry =
                          !ticketList[idx].showTelemetry
                        setTicketList(tmplist)
                      }}
                    >
                      {data.showTelemetry ? 'hide' : 'show'}
                    </button>
                    {data.showTelemetry ? (
                      <pre>
                        <code>
                          {' '}
                          {JSON.stringify(data.telemetry, null, '  ')}
                        </code>
                      </pre>
                    ) : (
                      <></>
                    )}
                  </TicketCard>{' '}
                  <br />
                </>
              )
            })}
          </TicketsDiv>
        </TicketCardContainer>
      </>
    )
  } else {
    return <p>Loading...</p>
  }
}

export default TicketListPage
