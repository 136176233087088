import react, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { MdOutlineErrorOutline } from 'react-icons/md'
import { Tooltip } from 'react-tooltip'
import 'react-tooltip/dist/react-tooltip.css'
function ErrorReportButton({ id, telemetry, size, tooltip }) {
  const navigate = useNavigate()
  // const [mouseOn, setMouseOn] = useState(false)
  return (
    <>
      <MdOutlineErrorOutline
        color='black'
        size={size ? size : '30'}
        data-tooltip-content={tooltip ? tooltip : 'Report an error'}
        data-tooltip-id={id}
        onClick={() =>
          navigate('/createticket', { state: { telemetry: telemetry } })
        }

        // onMouseEnter={() => setMouseOn(true)}
        // onMouseLeave={() => setMouseOn(false)}
      />
      <Tooltip id={id} />

      {/* {<span  style={{display: !mouseOn ? 'none' : ''}}>Report an Error</span>} */}
    </>
  )
}
export default ErrorReportButton
