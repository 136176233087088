import React, { useState } from 'react'
import Draggable from 'react-draggable'

import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { Modal } from 'react-bootstrap'
import useAuth from '../hooks/useAuth'
import { axiosPrivate } from '../apis/axios'
import { Expression, GraphingCalculator } from 'desmos-react'
import './GraphCalcModal.css'
import { AiOutlineClose } from 'react-icons/ai'
// Username, Name of file, up
const Button = styled.button`
  appearance: none;
  border-radius: 4px;
  height: 30px;
  cursor: pointer;
  font-size: 15px;
  padding: 0px 15px;
  border: none;
  background: #0084ce;
  color: white;
`
const CalcDiv = styled.div``
const CloseButton = styled.div``
const HomeButtonDiv = styled(Link)`
  font-size: 10px;
`

function GraphCalcModal(props) {
  return (
    <Draggable handle='.handle'>
      <div
        // backdrop={false}
        // keyboard={false}
        // className='GCalc'
        {...props}
        // aria-labelledby='finshed-exam-modal'
        // centered
      >
        <CalcDiv>
          <CloseButton>
            {' '}
            <AiOutlineClose
              className='CloseButton'
              style={{ cursor: 'pointer' }}
              size={30}
              onClick={props.close}
              onTouchStart={props.close}
            />
          </CloseButton>
          <GraphingCalculator
            attributes={{ className: 'calculator' }}
            projectorMode
          >
            <Expression id='fn' latex='x^2' />
          </GraphingCalculator>
        </CalcDiv>
        {/* <Modal.Footer>
        <Button onClick={() => deleteExam(props.test)}> Delete </Button>
        <Button onClick={props.onHide}>Close</Button>
      </Modal.Footer> */}
      </div>
    </Draggable>
  )
}

export default GraphCalcModal
