import React, { useState, useEffect, useRef } from 'react'
import styled from 'styled-components'
import { MathJax } from 'better-react-mathjax'
import { BsBookmark, BsBookmarkFill } from 'react-icons/bs'
import { RiErrorWarningLine } from 'react-icons/ri'
import parse from 'html-react-parser'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { useTimer, useStopwatch } from 'react-timer-hook'
import ExamReviewRadioButton from '../components/ExamReviewRadioButton'
import BookmarkMenu from '../components/BookmarkMenu'
import axios, { axiosPrivate } from '../apis/axios'
import useAuth from '../hooks/useAuth'
import AssessmentArc from '../components/AssessmentArc'
import ReviewDataPlot from '../components/ReviewDataPlot'
import ReportModal from '../Modals/ReportModal'
import Accordion from 'react-bootstrap/Accordion'
import { Button } from 'react-bootstrap'
import Table from 'react-bootstrap/Table'
import ReviewModal from '../Modals/ReviewModal'
import { flushSync } from 'react-dom'

const ReviewContainer = styled.div`
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow: scroll;
`
const GoBackHeader = styled.div`
  padding: 10px 0 10px 0;
  display: flex;
  justify-content: space-around;
  border-bottom: 1px solid rgba(33, 36, 44, 0.16);
`
const ReviewTopContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 30px;
  padding: 20px 30px;
`
const ScoreDetailsContainer = styled.div``
const ScoreDetailSpan = styled.div`
  font-size: calc(1em + 1vw);
  font-weight: 700;
`
const ReviewSectionSelector = styled.div`
  display: flex;
  justify-content: space-evenly;

  padding-bottom: 20px;
`
const ReviewScoreOverview = styled.div`
  padding: 20px 30px;
`
const ReviewSection = styled.div`
  ${(props) => (props.selected ? `border-bottom:1px solid black;` : null)}
  cursor: pointer;
  &hover: {
    border-bottom: 1px solid black;
  }
`
const ReviewQuestionsOverview = styled.div`
  padding: 20px 30px;
`
const QuestionsOverviewSpan = styled.div`
  font-size: calc(1em + 0.5vw);
  font-weight: 700;
  padding-bottom: 20px;
`
const OverviewContainer = styled.div`
  margin: 0 auto;
  width: 90vw;
  height: 10vh;
  background: #e6f8fc;
  display: flex;
  // gap: 100px;
  justify-content: space-evenly;
`
const OverviewTop = styled.div`
  font-size: calc(1.2em + 1vw);
  font-weight: 700;
`
const OverviewBottom = styled.div``
const OverviewLeft = styled.div`
  width: 33%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
`
const OverviewMiddle = styled.div`
  width: 33%;
  // border-left: 1px solid black;
  // margin-top: 20px;
  // margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  ${(props) => (props.color == '#355799' ? `color:#355799;` : `color:black;`)}
`
const OverviewRight = styled.div`
  // border-left: 1px solid black;
  // margin-top: 20px;
  // margin-bottom: 20px;
  width: 33%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  ${(props) => (props.color == '#355799' ? `color:#355799;` : `color:black;`)}
`
const ReviewQuestionsTable = styled.div`
  text-align: center;
`
const ReturnButton = styled.button`
  cursor: pointer;
  outline: none;
  background: none;
  border: 1px solid white;
  font-size: 1em;
  font-weight: bold;
  color: #1586ca;
`
const ReviewButton = styled(Button)`
  border-radius: 25px;
  height: max-content;
  background: #355799;
  border: none;
`
const THead = styled.thead`
  background: #2da0b7;
  color: white;
  // border-radius: 22px 22px 0px 0px;
`
const TableTh = styled.th`
  font-size: ${(prop) =>
    prop.fontSize ? prop.fontSize : `calc(0.8em + 0.6vw)`};
  text-align: center;
  width: ${(prop) => (prop.width ? prop.width : 'max-content')};
  border-radius: 30px 0px 0px 0px;
`

const TableTd = styled.td`
  text-align: center;
  font-size: calc(0.5em + 0.6vw);
`
const TableTd2 = styled.td`
  text-align: center;
  font-size: calc(0.5em + 0.6vw);
  ${(props) => (props.correct ? `color:blue;` : `color:red;`)}
`
const TableTr = styled.tr``

function ExamReview() {
  const navigate = useNavigate()
  const location = useLocation()
  const params = useParams()

  const { auth } = useAuth()
  const ref = useRef()
  const [questions, setQuestions] = useState()
  const [examUserData, setExamUserData] = useState(
    location.state ? location.state : null
  )
  const [examData, setExam] = useState()
  const [examGrade, setExamGrade] = useState('')
  const [section, setSection] = useState('All')
  const [sectionData, setSectionData] = useState()
  const [qNum, setQNum] = useState()
  const [showReview, setShowReview] = useState(false)
  // const [correctNum, setCorrectNum] = useState(0)
  const [totalQuestions, setTotalQuestions] = useState(0)
  var correctNum = 0
  // const [examInfo, setExamInfo] = useState(location.state.test_form)

  const CloseReviewModal = () => setShowReview(false)
  const ReviewModalData = (index, index2) => {
    setSectionData(index)
    setQNum(index2)
    setShowReview(true)

    // setShowReview(true)
  }

  useEffect(() => {
    const getExams = async () => {
      try {
        const response1 = await axiosPrivate.get('/examdata/', {
          headers: { authorization: 'Bearer ' + auth.accessToken },
          params: { public_id: params.id },
          // params: { uuid: auth.UUID },
        })
        if (response1.data.grades.is_pending) {
          navigate('/')
        } else {
          if (
            auth.user_type != 'staff_member' &&
            response1.data?.submission_date == null
          ) {
            navigate(-1)
          } else {
            if (!response1.data.grades) {
              response1.data.grades = {}
            }
            if (
              !response1.data.grades.scores ||
              response1.data.grades.scores.length < 3
            ) {
              response1.data.grades.scores = [
                { scaled_score: 0 },
                { scaled_score: 0 },
                { scaled_score: 0 },
              ]
            }

            setExamGrade(response1.data)
            // console.log('submittion', response1.data)
            setExam(response1.data)
            // setExam(resp2.data)
          }
        }
      } catch (err) {
        console.log(err)
      }
    }
    getExams()
  }, [])
  useEffect(() => {
    correctNum = 0
  }, [section])

  console.log('examdata', examData)
  if (examGrade) {
    if (section == 'All') {
      return (
        <ReviewContainer>
          <GoBackHeader>
            <ReturnButton onClick={() => navigate('/exam')}>
              Go back to exams page.
            </ReturnButton>
          </GoBackHeader>
          <ReviewTopContainer>
            <ScoreDetailsContainer>
              <ScoreDetailSpan>Score Details</ScoreDetailSpan>
              <div>
                {/* {examData.test_form.form_code} -/ */}
                {examData.test_data.form_code} -
                {new Date(examData.submission_date).toLocaleDateString('en-US')}
              </div>
            </ScoreDetailsContainer>
            <ReviewButton onClick={() => ReviewModalData(0, 0)}>
              Review Test
            </ReviewButton>
            {/* <NextButton> Review Test</NextButton> */}
          </ReviewTopContainer>
          <ReviewSectionSelector>
            <ReviewSection
              selected={section == 'All'}
              onClick={() => setSection('All')}
            >
              All Questions
            </ReviewSection>
            <ReviewSection
              selected={section == 'Reading'}
              onClick={() => setSection('Reading')}
            >
              Reading and Writing
            </ReviewSection>
            <ReviewSection
              selected={section == 'Math'}
              onClick={() => setSection('Math')}
            >
              Math
            </ReviewSection>
          </ReviewSectionSelector>
          <ReviewScoreOverview>
            <QuestionsOverviewSpan> Scores</QuestionsOverviewSpan>

            <OverviewContainer>
              <OverviewLeft>
                <OverviewTop>
                  {parseInt(examGrade.grades.scores[2].scaled_score).toFixed(0)}
                </OverviewTop>
                <OverviewBottom>Total Score</OverviewBottom>
              </OverviewLeft>
              <div
                style={{
                  marginTop: '10px',
                  marginBottom: '10px',
                  borderLeft: '1px solid black',
                }}
              ></div>
              <OverviewMiddle>
                <OverviewTop>
                  {parseInt(examGrade.grades.scores[0].scaled_score).toFixed(0)}
                </OverviewTop>
                <OverviewBottom> Reading and Writing Score</OverviewBottom>
              </OverviewMiddle>
              <div
                style={{
                  marginTop: '10px',
                  marginBottom: '10px',
                  borderLeft: '1px solid black',
                }}
              ></div>
              <OverviewRight>
                <OverviewTop>
                  {parseInt(examGrade.grades.scores[1].scaled_score).toFixed(0)}
                </OverviewTop>
                <OverviewBottom> Math Score</OverviewBottom>
              </OverviewRight>
            </OverviewContainer>
          </ReviewScoreOverview>

          <ReviewQuestionsOverview>
            <QuestionsOverviewSpan> Questions Overview</QuestionsOverviewSpan>
            <OverviewContainer>
              <OverviewLeft>
                <OverviewTop>
                  {Object.keys(examData.exam_json[1]).length +
                    Object.keys(examData.exam_json[2]).length +
                    Object.keys(examData.exam_json[3]).length +
                    Object.keys(examData.exam_json[4]).length}
                </OverviewTop>
                <OverviewBottom>Total Questions</OverviewBottom>
              </OverviewLeft>
              <div
                style={{
                  marginTop: '10px',
                  marginBottom: '10px',
                  borderLeft: '1px solid black',
                }}
              ></div>
              <OverviewMiddle>
                <OverviewTop>
                  {Object.values(examGrade?.grades.section_responses).map(
                    (sections) => {
                      correctNum += sections.responses_grades.filter(
                        (x) => x === 3
                      ).length
                    }
                  )}
                  {correctNum}
                </OverviewTop>
                <OverviewBottom> Correct Answers</OverviewBottom>
              </OverviewMiddle>
              <div
                style={{
                  marginTop: '10px',
                  marginBottom: '10px',
                  borderLeft: '1px solid black',
                }}
              ></div>
              <OverviewRight>
                <OverviewTop>
                  {' '}
                  {Object.keys(examData.exam_json[1]).length +
                    Object.keys(examData.exam_json[2]).length +
                    Object.keys(examData.exam_json[3]).length +
                    Object.keys(examData.exam_json[4]).length -
                    correctNum}
                </OverviewTop>
                <OverviewBottom> Incorrect Answers</OverviewBottom>
              </OverviewRight>
            </OverviewContainer>
          </ReviewQuestionsOverview>
          <ReviewQuestionsTable>
            <Table style={{ margin: 0 }} size='sm' bordered>
              <thead>
                <TableTr>
                  <TableTh width='15%'>Question</TableTh>
                  <TableTh width='25%'>Section</TableTh>
                  <TableTh width='20%'>Correct Answer</TableTh>
                  <TableTh width='20%'>Your Answer</TableTh>
                  <TableTh width='20%'>Action</TableTh>
                </TableTr>
              </thead>
              {/* <tbody> */}
              {Object.values(examData.exam_json).map((testData, index) => {
                return Object.values(testData).map((questions, index2) => {
                  return (
                    <tbody>
                      <TableTr>
                        <TableTd>{index2 + 1}</TableTd>
                        <TableTd>
                          {index > 2
                            ? 'Math 2'
                            : index > 1
                            ? 'Math 1'
                            : index > 0
                            ? 'Reading and Writing 2'
                            : 'Reading and Writing 1'}{' '}
                        </TableTd>
                        <TableTd>
                          <MathJax hideUntilTypeset={'first'} inline dynamic>
                            {
                              examGrade?.test_data.sections[index + 1][
                                index2 + 1
                              ].content.answer
                            }
                          </MathJax>
                        </TableTd>
                        <TableTd2
                          correct={
                            examGrade.grades.section_responses[index + 1]
                              .responses_grades[index2] == 3
                          }
                        >
                          {examGrade?.grades.section_responses[index + 1]
                            .responses[index2]
                            ? examGrade?.grades.section_responses[index + 1]
                                .responses[index2]
                            : 'Omitted'}{' '}
                          {examGrade?.grades.section_responses[index + 1]
                            .responses_grades[index2] > 3
                            ? ': Not Graded'
                            : examGrade?.grades.section_responses[index + 1]
                                .responses_grades[index2] == 3
                            ? ': Correct'
                            : examGrade?.grades.section_responses[index + 1]
                                .responses_grades[index2] == 2
                            ? ': Incorrect'
                            : ''}
                        </TableTd2>
                        <TableTd>
                          <Button
                            onClick={() => ReviewModalData(index, index2)}
                          >
                            Review
                          </Button>
                        </TableTd>
                      </TableTr>
                    </tbody>
                  )
                })
              })}
            </Table>
          </ReviewQuestionsTable>

          {/* <ExamFooter>
          <NextButton onClick={handleNext}>Review Exam </NextButton>
        </ExamFooter> */}
          <ReviewModal
            show={showReview}
            onHide={CloseReviewModal}
            sectionData={sectionData}
            qNum={qNum}
            examData={examData}
            examGrade={examGrade}
            section={section}
            setSectionData={setSectionData}
            setQNum={setQNum}
          />
        </ReviewContainer>
      )
    } else if (section == 'Reading') {
      return (
        <ReviewContainer>
          <GoBackHeader>
            <ReturnButton onClick={() => navigate('/exam')}>
              Go back to exams page.
            </ReturnButton>
          </GoBackHeader>
          <ReviewTopContainer>
            <ScoreDetailsContainer>
              <ScoreDetailSpan>Score Details</ScoreDetailSpan>
              <div>
                {examData.test_data.form_code} -
                {new Date(examData.submission_date).toLocaleDateString('en-US')}
              </div>
            </ScoreDetailsContainer>
            <ReviewButton onClick={() => ReviewModalData(0, 0)}>
              Review Test
            </ReviewButton>
            {/* <NextButton> Review Test</NextButton> */}
          </ReviewTopContainer>
          <ReviewSectionSelector>
            <ReviewSection
              selected={section == 'All'}
              onClick={() => setSection('All')}
            >
              All Questions
            </ReviewSection>
            <ReviewSection
              selected={section == 'Reading'}
              onClick={() => setSection('Reading')}
            >
              Reading and Writing
            </ReviewSection>
            <ReviewSection
              selected={section == 'Math'}
              onClick={() => setSection('Math')}
            >
              Math
            </ReviewSection>
          </ReviewSectionSelector>
          <ReviewScoreOverview>
            <QuestionsOverviewSpan> Scores</QuestionsOverviewSpan>

            <OverviewContainer>
              <OverviewLeft>
                <OverviewTop>
                  {parseInt(examGrade.grades.scores[2].scaled_score).toFixed(0)}
                </OverviewTop>
                <OverviewBottom>Total Score</OverviewBottom>
              </OverviewLeft>
              <div
                style={{
                  marginTop: '10px',
                  marginBottom: '10px',
                  borderLeft: '1px solid black',
                }}
              ></div>
              <OverviewMiddle color={'#355799'}>
                <OverviewTop>
                  <b>
                    {parseInt(examGrade.grades.scores[0].scaled_score).toFixed(
                      0
                    )}
                  </b>
                </OverviewTop>
                <OverviewBottom>
                  {' '}
                  <b>Reading and Writing Score</b>
                </OverviewBottom>
              </OverviewMiddle>
              <div
                style={{
                  marginTop: '10px',
                  marginBottom: '10px',
                  borderLeft: '1px solid black',
                }}
              ></div>
              <OverviewRight>
                <OverviewTop>
                  {parseInt(examGrade.grades.scores[1].scaled_score).toFixed(0)}
                </OverviewTop>
                <OverviewBottom> Math Score</OverviewBottom>
              </OverviewRight>
            </OverviewContainer>
          </ReviewScoreOverview>

          <ReviewQuestionsOverview>
            <QuestionsOverviewSpan> Questions Overview</QuestionsOverviewSpan>
            <OverviewContainer>
              <OverviewLeft>
                <OverviewTop>
                  {Object.keys(examData.exam_json[1]).length +
                    Object.keys(examData.exam_json[2]).length}
                </OverviewTop>
                <OverviewBottom>Total Questions</OverviewBottom>
              </OverviewLeft>
              <div
                style={{
                  marginTop: '10px',
                  marginBottom: '10px',
                  borderLeft: '1px solid black',
                }}
              ></div>
              <OverviewMiddle>
                <OverviewTop>
                  {examGrade.grades.section_responses[1].responses_grades.filter(
                    (x) => x === 3
                  ).length +
                    examGrade.grades.section_responses[2].responses_grades.filter(
                      (x) => x === 3
                    ).length}
                </OverviewTop>
                <OverviewBottom> Correct Answers</OverviewBottom>
              </OverviewMiddle>
              <div
                style={{
                  marginTop: '10px',
                  marginBottom: '10px',
                  borderLeft: '1px solid black',
                }}
              ></div>
              <OverviewRight>
                <OverviewTop>
                  {' '}
                  {Object.keys(examData.exam_json[1]).length +
                    Object.keys(examData.exam_json[2]).length -
                    (examGrade.grades.section_responses[1].responses_grades.filter(
                      (x) => x === 3
                    ).length +
                      examGrade.grades.section_responses[2].responses_grades.filter(
                        (x) => x === 3
                      ).length)}
                </OverviewTop>
                <OverviewBottom> Incorrect Answers</OverviewBottom>
              </OverviewRight>
            </OverviewContainer>
          </ReviewQuestionsOverview>
          <ReviewQuestionsTable>
            <Table style={{ margin: 0 }} size='sm' bordered>
              <thead>
                <TableTr>
                  <TableTh width='15%'>Question</TableTh>
                  <TableTh width='25%'>Section</TableTh>
                  <TableTh width='20%'>Correct Answer</TableTh>
                  <TableTh width='20%'>Your Answer</TableTh>
                  <TableTh width='20%'>Action</TableTh>
                </TableTr>
              </thead>
              {/* <tbody> */}
              {Object.values(examData.exam_json[1]).map((questions, index2) => {
                return (
                  <tbody>
                    <TableTr>
                      <TableTd>{index2 + 1}</TableTd>
                      <TableTd>Reading and Writing 1</TableTd>
                      <TableTd>
                        <MathJax hideUntilTypeset={'first'} inline dynamic>
                          {
                            examGrade?.test_data.sections[1][index2 + 1].content
                              .answer
                          }
                        </MathJax>
                      </TableTd>
                      <TableTd2
                        correct={
                          examGrade.grades.section_responses[1]
                            .responses_grades[index2] == 3
                        }
                      >
                        {examGrade?.grades.section_responses[1].responses[
                          index2
                        ]
                          ? examGrade?.grades.section_responses[1].responses[
                              index2
                            ]
                          : 'Omitted'}{' '}
                        {examGrade?.grades.section_responses[1]
                          .responses_grades[index2] > 3
                          ? ': Not Graded'
                          : examGrade?.grades.section_responses[1]
                              .responses_grades[index2] == 3
                          ? ': Correct'
                          : examGrade?.grades.section_responses[1]
                              .responses_grades[index2] == 2
                          ? ': Incorrect'
                          : ''}
                      </TableTd2>
                      <TableTd>
                        <Button onClick={() => ReviewModalData(0, index2)}>
                          Review
                        </Button>
                      </TableTd>
                    </TableTr>
                  </tbody>
                )
              })}
              {Object.values(examData.exam_json[2]).map((questions, index2) => {
                return (
                  <tbody>
                    <TableTr>
                      <TableTd>{index2 + 1}</TableTd>
                      <TableTd>Reading and Writing 2</TableTd>
                      <TableTd>
                        <MathJax hideUntilTypeset={'first'} inline dynamic>
                          {
                            examGrade?.test_data.sections[2][index2 + 1].content
                              .answer
                          }
                        </MathJax>
                      </TableTd>
                      <TableTd2
                        correct={
                          examGrade.grades.section_responses[2]
                            .responses_grades[index2] == 3
                        }
                      >
                        {examGrade?.grades.section_responses[2].responses[
                          index2
                        ]
                          ? examGrade?.grades.section_responses[2].responses[
                              index2
                            ]
                          : 'Omitted'}{' '}
                        {examGrade?.grades.section_responses[2]
                          .responses_grades[index2] > 3
                          ? ': Not Graded'
                          : examGrade?.grades.section_responses[2]
                              .responses_grades[index2] == 3
                          ? ': Correct'
                          : examGrade?.grades.section_responses[2]
                              .responses_grades[index2] == 2
                          ? ': Incorrect'
                          : ''}
                      </TableTd2>
                      <TableTd>
                        <Button onClick={() => ReviewModalData(1, index2)}>
                          Review
                        </Button>
                      </TableTd>
                    </TableTr>
                  </tbody>
                )
              })}
            </Table>
          </ReviewQuestionsTable>

          {/* <ExamFooter>
          <NextButton onClick={handleNext}>Review Exam </NextButton>
        </ExamFooter> */}
          <ReviewModal
            show={showReview}
            onHide={CloseReviewModal}
            sectionData={sectionData}
            qNum={qNum}
            examData={examData}
            examGrade={examGrade}
            section={section}
            setSectionData={setSectionData}
            setQNum={setQNum}
          />
        </ReviewContainer>
      )
    } else if (section == 'Math') {
      return (
        <ReviewContainer>
          <GoBackHeader>
            <ReturnButton onClick={() => navigate('/exam')}>
              Go back to exams page.
            </ReturnButton>
          </GoBackHeader>
          <ReviewTopContainer>
            <ScoreDetailsContainer>
              <ScoreDetailSpan>Score Details</ScoreDetailSpan>
              <div>
                {examData.test_data.form_code} -
                {new Date(examData.submission_date).toLocaleDateString('en-US')}
              </div>
            </ScoreDetailsContainer>
            <ReviewButton onClick={() => ReviewModalData(2, 0)}>
              Review Test
            </ReviewButton>
            {/* <NextButton> Review Test</NextButton> */}
          </ReviewTopContainer>
          <ReviewSectionSelector>
            <ReviewSection
              selected={section == 'All'}
              onClick={() => setSection('All')}
            >
              All Questions
            </ReviewSection>
            <ReviewSection
              selected={section == 'Reading'}
              onClick={() => setSection('Reading')}
            >
              Reading and Writing
            </ReviewSection>
            <ReviewSection
              selected={section == 'Math'}
              onClick={() => setSection('Math')}
            >
              Math
            </ReviewSection>
          </ReviewSectionSelector>
          <ReviewScoreOverview>
            <QuestionsOverviewSpan> Scores</QuestionsOverviewSpan>

            <OverviewContainer>
              <OverviewLeft>
                <OverviewTop>
                  {parseInt(examGrade.grades.scores[2].scaled_score).toFixed(0)}
                </OverviewTop>
                <OverviewBottom>Total Score</OverviewBottom>
              </OverviewLeft>
              <div
                style={{
                  marginTop: '10px',
                  marginBottom: '10px',
                  borderLeft: '1px solid black',
                }}
              ></div>
              <OverviewMiddle>
                <OverviewTop>
                  {parseInt(examGrade.grades.scores[0].scaled_score).toFixed(0)}
                </OverviewTop>
                <OverviewBottom> Reading and Writing Score</OverviewBottom>
              </OverviewMiddle>
              <div
                style={{
                  marginTop: '10px',
                  marginBottom: '10px',
                  borderLeft: '1px solid black',
                }}
              ></div>
              <OverviewRight color={'#355799'}>
                <OverviewTop>
                  <b>
                    {parseInt(examGrade.grades.scores[1].scaled_score).toFixed(
                      0
                    )}
                  </b>
                </OverviewTop>
                <OverviewBottom>
                  <b> Math Score</b>
                </OverviewBottom>
              </OverviewRight>
            </OverviewContainer>
          </ReviewScoreOverview>
          <ReviewQuestionsOverview>
            <QuestionsOverviewSpan> Questions Overview</QuestionsOverviewSpan>
            <OverviewContainer>
              <OverviewLeft>
                <OverviewTop>
                  {Object.keys(examData.exam_json[3]).length +
                    Object.keys(examData.exam_json[4]).length}
                </OverviewTop>
                <OverviewBottom>Total Questions</OverviewBottom>
              </OverviewLeft>
              <div
                style={{
                  marginTop: '10px',
                  marginBottom: '10px',
                  borderLeft: '1px solid black',
                }}
              ></div>
              <OverviewMiddle>
                <OverviewTop>
                  {examGrade.grades.section_responses[3].responses_grades.filter(
                    (x) => x === 3
                  ).length +
                    examGrade.grades.section_responses[4].responses_grades.filter(
                      (x) => x === 3
                    ).length}
                </OverviewTop>
                <OverviewBottom> Correct Answers</OverviewBottom>
              </OverviewMiddle>
              <div
                style={{
                  marginTop: '10px',
                  marginBottom: '10px',
                  borderLeft: '1px solid black',
                }}
              ></div>
              <OverviewRight>
                <OverviewTop>
                  {' '}
                  {Object.keys(examData.exam_json[3]).length +
                    Object.keys(examData.exam_json[4]).length -
                    (examGrade.grades.section_responses[3].responses_grades.filter(
                      (x) => x === 3
                    ).length +
                      examGrade.grades.section_responses[4].responses_grades.filter(
                        (x) => x === 3
                      ).length)}
                </OverviewTop>
                <OverviewBottom> Incorrect Answers</OverviewBottom>
              </OverviewRight>
            </OverviewContainer>
          </ReviewQuestionsOverview>
          <ReviewQuestionsTable>
            <Table style={{ margin: 0 }} size='sm' bordered>
              <thead>
                <TableTr>
                  <TableTh width='15%'>Question</TableTh>
                  <TableTh width='25%'>Section</TableTh>
                  <TableTh width='20%'>Correct Answer</TableTh>
                  <TableTh width='20%'>Your Answer</TableTh>
                  <TableTh width='20%'>Action</TableTh>
                </TableTr>
              </thead>
              {/* <tbody> */}
              {Object.values(examData.exam_json[3]).map((questions, index2) => {
                return (
                  <tbody>
                    <TableTr>
                      <TableTd>{index2 + 1}</TableTd>
                      <TableTd>Math 1</TableTd>
                      <TableTd>
                        <MathJax hideUntilTypeset={'first'} inline dynamic>
                          {
                            examGrade?.test_data.sections[3][index2 + 1].content
                              .answer
                          }
                        </MathJax>
                      </TableTd>
                      <TableTd2
                        correct={
                          examGrade.grades.section_responses[3]
                            .responses_grades[index2] == 3
                        }
                      >
                        {examGrade?.grades.section_responses[3].responses[
                          index2
                        ]
                          ? examGrade?.grades.section_responses[3].responses[
                              index2
                            ]
                          : 'Omitted'}{' '}
                        {examGrade?.grades.section_responses[3]
                          .responses_grades[index2] > 3
                          ? ': Not Graded'
                          : examGrade?.grades.section_responses[3]
                              .responses_grades[index2] == 3
                          ? ': Correct'
                          : examGrade?.grades.section_responses[3]
                              .responses_grades[index2] == 2
                          ? ': Incorrect'
                          : ''}
                      </TableTd2>
                      <TableTd>
                        <Button onClick={() => ReviewModalData(2, index2)}>
                          Review
                        </Button>
                      </TableTd>
                    </TableTr>
                  </tbody>
                )
              })}
              {Object.values(examData.exam_json[4]).map((questions, index2) => {
                return (
                  <tbody>
                    <TableTr>
                      <TableTd>{index2 + 1}</TableTd>
                      <TableTd>Math 2</TableTd>
                      <TableTd>
                        <MathJax hideUntilTypeset={'first'} inline dynamic>
                          {
                            examGrade?.test_data.sections[4][index2 + 1].content
                              .answer
                          }
                        </MathJax>
                      </TableTd>
                      <TableTd2
                        correct={
                          examGrade.grades.section_responses[4]
                            .responses_grades[index2] == 3
                        }
                      >
                        {examGrade?.grades.section_responses[4].responses[
                          index2
                        ]
                          ? examGrade?.grades.section_responses[4].responses[
                              index2
                            ]
                          : 'Omitted'}{' '}
                        {examGrade?.grades.section_responses[4]
                          .responses_grades[index2] > 3
                          ? ': Not Graded'
                          : examGrade?.grades.section_responses[4]
                              .responses_grades[index2] == 3
                          ? ': Correct'
                          : examGrade?.grades.section_responses[4]
                              .responses_grades[index2] == 2
                          ? ': Incorrect'
                          : ''}
                      </TableTd2>
                      <TableTd>
                        <Button onClick={() => ReviewModalData(3, index2)}>
                          Review
                        </Button>
                      </TableTd>
                    </TableTr>
                  </tbody>
                )
              })}
            </Table>
          </ReviewQuestionsTable>

          {/* <ExamFooter>
          <NextButton onClick={handleNext}>Review Exam </NextButton>
        </ExamFooter> */}
          <ReviewModal
            show={showReview}
            onHide={CloseReviewModal}
            sectionData={sectionData}
            qNum={qNum}
            examData={examData}
            examGrade={examGrade}
            section={section}
            setSectionData={setSectionData}
            setQNum={setQNum}
          />
        </ReviewContainer>
      )
    }
  }
}

export default ExamReview
