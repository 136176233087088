import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { MathJax } from 'better-react-mathjax'
import aCross from '../Images/a-cross.png'
import bCross from '../Images/b-cross.png'
import cCross from '../Images/c-cross.png'
import dCross from '../Images/d-cross.png'
import parse2 from '../components/CustomLatexParser'

const REGEX = '[^{}]*(?=})'
const ChoicesContainer = styled.div`
  // border-top: 1px solid #ccc;
  width: 40vw;
  position: relative;
`
const WithCrossContainer = styled.div`
  display: flex;
  align-items: center;
`
const ChoiceSection = styled.div`
  margin: 2px;
  cursor: pointer;
  display: flex;
  gap: 15px;
  align-items: center;
  justify-content: flex-start;

  border-bottom: 1px solid #ccc;
  padding: 20px 16px 20px 16px;
  &:hover {
    background: #d0e6f4;
    outline: 2px solid #1586ca;
  }
  ${(props) =>
    // console.log(props)
    props.cross
      ? `
      width: 35vw;`
      : `width:40vw;`}
  transition: ease-in, 0.5s;
`
const ChoiceSectionA = styled(ChoiceSection)`
  border-top: 1px solid #ccc;
`
const QChoicesContainer = styled.div`
  padding: 10px 0px 10px 0px;
  ${(props) =>
    props.cross
      ? ` text-decoration: line-through; color:#ccc; `
      : `text-decoration: none;`}}
`
const ChoiceCross = styled.div`
  // position: absolute;
  right: 0;
  // top: 0px;
  transition: ease-in, 0.5s;
`
const QChoices = styled.div`
  font-height: 15px;
  font-size: 15px;
`
const QExplain = styled.div``
const AnsButton = styled.div`
  min-width: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #1586ca;
  border: 2px solid #1586ca;
  border-radius: 50%;
  height: 30px;
  width: 30px;
  ${(props) =>
    // console.log(props)
    props.selected
      ? `
    color: white;
    background: #1586ca;`
      : null}
  ${(props) =>
    props.cross
      ? ` text-decoration: line-through; color:#ccc; border-color:#ccc `
      : `text-decoration: none;`}}
`

function ReadingRadioButton({
  qChoices,
  optionsChosen,
  setChosen,
  userTestData,
  showCross,
  crossDataA,
  crossDataB,
  crossDataC,
  crossDataD,
  crossData,
  setCrossoutDataA,
  setCrossoutDataB,
  setCrossoutDataC,
  setCrossoutDataD,
  setCrossoutData,
}) {
  // const [crossDataA, setCrossoutDataA] = useState(crossData.a)
  // const [crossDataB, setCrossoutDataB] = useState(crossData.b)
  // const [crossDataC, setCrossoutDataC] = useState(crossData.c)
  // const [crossDataD, setCrossoutDataD] = useState(crossData.d)
  useEffect(() => {
    if (userTestData != null || userTestData != '') {
      setChosen(userTestData)
    }
  }, [userTestData])

  const selectOption = (e) => {
    if (optionsChosen == e) {
      userTestData = ''
      setChosen('')
    } else {
      setChosen(e)
      userTestData = e
    }
  }
  return (
    <ChoicesContainer>
      <WithCrossContainer>
        <MathJax>
          <ChoiceSectionA
            cross={showCross}
            selected={userTestData == 'A'}
            onClick={() => {
              selectOption('A')
              setCrossoutDataA(false)
            }}
          >
            <AnsButton cross={crossDataA} selected={optionsChosen == 'A'}>
              A
            </AnsButton>
            <QChoicesContainer cross={crossDataA}>
              <QChoices>
                {parse2(
                  qChoices[0].includes('reflines')
                    ? qChoices.passage_emp[qChoices.choices[0].match(REGEX)]
                    : qChoices[0]
                )}
              </QChoices>

              <QExplain></QExplain>
            </QChoicesContainer>
          </ChoiceSectionA>
        </MathJax>
        <ChoiceCross>
          {showCross ? (
            crossDataA == false ? (
              <img
                onClick={() => {
                  setCrossoutDataA(true)
                  setCrossoutData((prev) => {
                    const tmpdata = prev
                    tmpdata.a = true
                    return tmpdata
                  })
                }}
                src={aCross}
                alt='aCross'
                width={30}
              />
            ) : (
              <div
                style={{ textDecoration: 'underline' }}
                onClick={() => {
                  setCrossoutDataA(false)
                  // crossData.a = false
                  setCrossoutData((prev) => {
                    const tmpdata = prev
                    tmpdata.a = false
                    return tmpdata
                  })
                }}
              >
                Undo
              </div>
            )
          ) : null}
        </ChoiceCross>
      </WithCrossContainer>
      <WithCrossContainer>
        <MathJax>
          <ChoiceSection
            cross={showCross}
            selected={userTestData == 'B'}
            onClick={() => selectOption('B')}
          >
            <AnsButton cross={crossDataB} selected={optionsChosen == 'B'}>
              B
            </AnsButton>
            <QChoicesContainer cross={crossDataB}>
              <QChoices>
                {parse2(
                  qChoices[1].includes('reflines')
                    ? qChoices.passage_emp[qChoices.choices[1].match(REGEX)]
                    : qChoices[1]
                )}
              </QChoices>
              <QExplain></QExplain>
            </QChoicesContainer>
          </ChoiceSection>
        </MathJax>
        <ChoiceCross>
          {showCross ? (
            crossDataB == false ? (
              <img
                onClick={() => {
                  setCrossoutDataB(true)
                  // crossData.b = true
                  setCrossoutData((prev) => {
                    const tmpdata = prev
                    tmpdata.b = true
                    return tmpdata
                  })
                }}
                src={bCross}
                alt='bCross'
                width={30}
              />
            ) : (
              <div
                style={{ textDecoration: 'underline' }}
                onClick={() => {
                  setCrossoutDataB(false)
                  // crossData.b = false
                  setCrossoutData((prev) => {
                    const tmpdata = prev
                    tmpdata.b = false
                    return tmpdata
                  })
                }}
              >
                Undo
              </div>
            )
          ) : null}
        </ChoiceCross>
      </WithCrossContainer>
      <WithCrossContainer>
        <MathJax>
          <ChoiceSection
            cross={showCross}
            selected={userTestData == 'C'}
            onClick={() => selectOption('C')}
          >
            <AnsButton cross={crossDataC} selected={optionsChosen == 'C'}>
              C
            </AnsButton>
            <QChoicesContainer cross={crossDataC}>
              <QChoices>
                {parse2(
                  qChoices[2].includes('reflines')
                    ? qChoices.passage_emp[qChoices.choices[2].match(REGEX)]
                    : qChoices[2]
                )}{' '}
              </QChoices>
              <QExplain></QExplain>
            </QChoicesContainer>
          </ChoiceSection>
        </MathJax>
        <ChoiceCross>
          {showCross ? (
            crossDataC == false ? (
              <img
                onClick={() => {
                  setCrossoutDataC(true)
                  setCrossoutData((prev) => {
                    const tmpdata = prev
                    tmpdata.c = true
                    return tmpdata
                  })
                }}
                src={cCross}
                alt='cCross'
                width={30}
              />
            ) : (
              <div
                style={{ textDecoration: 'underline' }}
                onClick={() => {
                  setCrossoutDataC(false)
                  setCrossoutData((prev) => {
                    const tmpdata = prev
                    tmpdata.c = false
                    return tmpdata
                  })
                }}
              >
                Undo
              </div>
            )
          ) : null}
        </ChoiceCross>
      </WithCrossContainer>
      <WithCrossContainer>
        <MathJax>
          <ChoiceSection
            cross={showCross}
            selected={userTestData == 'D'}
            onClick={() => selectOption('D')}
          >
            <AnsButton cross={crossDataD} selected={optionsChosen == 'D'}>
              D
            </AnsButton>
            <QChoicesContainer cross={crossDataD}>
              <QChoices>
                {parse2(
                  qChoices[3].includes('reflines')
                    ? parse2(
                        qChoices.passage_emp[qChoices.choices[3].match(REGEX)]
                      )
                    : qChoices[3]
                )}
              </QChoices>
              <QExplain></QExplain>
            </QChoicesContainer>
          </ChoiceSection>
        </MathJax>
        <ChoiceCross>
          {showCross ? (
            crossDataD == false ? (
              <img
                onClick={() => {
                  setCrossoutDataD(true)
                  setCrossoutData((prev) => {
                    const tmpdata = prev
                    tmpdata.d = true
                    return tmpdata
                  })
                }}
                src={dCross}
                alt='dCross'
                width={30}
              />
            ) : (
              <div
                style={{ textDecoration: 'underline' }}
                onClick={() => {
                  setCrossoutDataD(false)
                  setCrossoutData((prev) => {
                    const tmpdata = prev
                    tmpdata.d = false
                    return tmpdata
                  })
                }}
              >
                Undo
              </div>
            )
          ) : null}
        </ChoiceCross>
      </WithCrossContainer>
    </ChoicesContainer>
  )
}

export default ReadingRadioButton
