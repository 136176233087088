import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { MathJax } from 'better-react-mathjax'
import parse2 from './CustomLatexParser'

const REGEX = '[^{}]*(?=})'
const ChoicesContainer = styled.div`
  // border-top: 1px solid #ccc;
  width: 40vw;
  position: relative;
`
const WithCrossContainer = styled.div`
  display: flex;
  align-items: center;
`
const ChoiceSection = styled.div`
  width: 40vw;
  margin: 2px;
  cursor: pointer;
  display: flex;
  gap: 15px;
  align-items: center;
  justify-content: flex-start;

  border-bottom: 1px solid #ccc;
  padding: 20px 16px 20px 16px;
  &:hover {
    background: #d0e6f4;
    outline: 2px solid #1586ca;
  }
  ${(props) =>
    props.correct == true
      ? `
              background: #d0e6f4;`
      : ` background:none;`}
`
const ChoiceSectionA = styled(ChoiceSection)`
  border-top: 1px solid #ccc;
`
const QChoicesContainer = styled.div`
  padding: 10px 0px 10px 0px;
  ${(props) =>
    props.cross
      ? ` text-decoration: line-through; color:#ccc; `
      : `text-decoration: none;`}}
`
const ChoiceCross = styled.div`
  // position: absolute;
  right: 0;
  // top: 0px;
  transition: ease-in, 0.5s;
`
const QChoices = styled.div`
  font-height: 15px;
  font-size: 15px;
`
const QExplain = styled.div``
const AnsButton = styled.div`
  min-width: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #1586ca;
  border: 2px solid #1586ca;
  border-radius: 50%;
  height: 30px;
  width: 30px;
  ${(props) =>
    // console.log(props)
    props.selected && props.correct == true
      ? `
    color: white;
    background: #1586ca;`
      : null}
  ${(props) =>
    props.correct != props.selected
      ? `color:white; 
              background:#1586ca;`
      : null}
  ${(props) =>
    props.selected && props.correct == false
      ? `color:white; 
      background:#EB5264;`
      : null}
  ${(props) =>
    props.omitted && props.correct
      ? `color:white; 
    background:#EB5264;`
      : null}
`

function ReadingReviewRadioButton({
  qChoices,
  optionsChosen,

  correctAnswer,
}) {
  // useEffect(() => {
  //   if (correctAnswer != null || correctAnswer != '') {
  //     setChosen(correctAnswer)
  //   }
  // }, [correctAnswer])

  // const selectOption = (e) => {
  //   if (optionsChosen == e) {
  //     correctAnswer = ''
  //     setChosen('')
  //   } else {
  //     setChosen(e)
  //     correctAnswer = e
  //   }

  //   console.log(e)
  // }
  return (
    <ChoicesContainer>
      <WithCrossContainer>
        <MathJax>
          <ChoiceSection
            // selected={
            //   examData.page_and_q.questions[index].student_answer == 'a'
            // }
            // correct={examData.page_and_q.questions[index].correct_choice == 'a'}
            selected={optionsChosen == 'A'}
            correct={correctAnswer == 'A'}
            // onClick={() => {
            //   selectOption('A')
            // }}
          >
            <AnsButton
              selected={optionsChosen == 'A'}
              correct={correctAnswer == 'A'}
              omitted={optionsChosen == ''}
            >
              A
            </AnsButton>
            <QChoicesContainer>
              <QChoices>
                {parse2(
                  qChoices[0].includes('reflines')
                    ? qChoices.passage_emp[qChoices.choices[0].match(REGEX)]
                    : qChoices[0]
                )}
              </QChoices>

              <QExplain></QExplain>
            </QChoicesContainer>
          </ChoiceSection>
        </MathJax>
      </WithCrossContainer>
      <WithCrossContainer>
        <MathJax>
          <ChoiceSection
            selected={optionsChosen == 'B'}
            correct={correctAnswer == 'B'}
            // onClick={() => selectOption('B')}
          >
            <AnsButton
              selected={optionsChosen == 'B'}
              correct={correctAnswer == 'B'}
              omitted={optionsChosen == ''}
            >
              B
            </AnsButton>
            <QChoicesContainer>
              <QChoices>
                {parse2(
                  qChoices[1].includes('reflines')
                    ? qChoices.passage_emp[qChoices.choices[1].match(REGEX)]
                    : qChoices[1]
                )}
              </QChoices>
              <QExplain></QExplain>
            </QChoicesContainer>
          </ChoiceSection>
        </MathJax>
      </WithCrossContainer>
      <WithCrossContainer>
        <MathJax>
          <ChoiceSection
            selected={optionsChosen == 'C'}
            correct={correctAnswer == 'C'}
            // onClick={() => selectOption('C')}
          >
            <AnsButton
              selected={optionsChosen == 'C'}
              correct={correctAnswer == 'C'}
              omitted={optionsChosen == ''}
            >
              C
            </AnsButton>
            <QChoicesContainer>
              <QChoices>
                {parse2(
                  qChoices[2].includes('reflines')
                    ? qChoices.passage_emp[qChoices.choices[2].match(REGEX)]
                    : qChoices[2]
                )}{' '}
              </QChoices>
              <QExplain></QExplain>
            </QChoicesContainer>
          </ChoiceSection>
        </MathJax>
      </WithCrossContainer>
      <WithCrossContainer>
        <MathJax>
          <ChoiceSection
            selected={optionsChosen == 'D'}
            correct={correctAnswer == 'D'}
            // onClick={() => selectOption('D')}
          >
            <AnsButton
              selected={optionsChosen == 'D'}
              correct={correctAnswer == 'D'}
              omitted={optionsChosen == ''}
            >
              D
            </AnsButton>
            <QChoicesContainer>
              <QChoices>
                {parse2(
                  qChoices[3].includes('reflines')
                    ? parse2(
                        qChoices.passage_emp[qChoices.choices[3].match(REGEX)]
                      )
                    : qChoices[3]
                )}
              </QChoices>
              <QExplain></QExplain>
            </QChoicesContainer>
          </ChoiceSection>
        </MathJax>
      </WithCrossContainer>
    </ChoicesContainer>
  )
}

export default ReadingReviewRadioButton
