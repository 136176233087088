import React, { useState, useEffect } from 'react'
import Draggable from 'react-draggable'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import RefImg from '../Images/reference_2.png'
import parse from 'html-react-parser'
import { Modal } from 'react-bootstrap'
import useAuth from '../hooks/useAuth'
import { axiosPrivate } from '../apis/axios'
import { Expression, GraphingCalculator } from 'desmos-react'
import './GraphCalcModal.css'
import { AiOutlineClose } from 'react-icons/ai'

const Button = styled.button`
  appearance: none;
  border-radius: 4px;
  height: 30px;
  cursor: pointer;
  font-size: 15px;
  padding: 0px 15px;
  border: none;
  background: #0084ce;
  color: white;
`
const CalcDiv = styled.div``
const CloseButton = styled.div``
const HomeButtonDiv = styled(Link)`
  font-size: 10px;
`
const RefDiv = styled.div`
  background-image: url(${RefImg});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  width: 550px;
  height: 700px;
`

function MathRefModal(props) {
  //   const [text, setText] = useState()

  //   useEffect(() => {
  //     fetch(RefTest)
  //       .then((response) => response.text())
  //       .then((textContent) => {
  //         setText(textContent)
  //       })
  //   }, [])
  return (
    <Draggable handle='.handle2'>
      <div
        // backdrop={false}
        // keyboard={false}
        // className='GCalc'
        {...props}
        // aria-labelledby='finshed-exam-modal'
        // centered
      >
        <CloseButton>
          {' '}
          <AiOutlineClose
            className='CloseButton'
            style={{ cursor: 'pointer' }}
            size={30}
            onClick={props.close}
            onTouchStart={props.close}
          />
        </CloseButton>
        <RefDiv>{/* <img height={500} src={RefImg} /> */}</RefDiv>
        {/* <Modal
          // className='MathDirection'
          dialogClassName='modal80w'
          {...props}
          aria-labelledby='finshed-exam-modal'
          centered
          scrollable
        >
          <Modal.Header closeButton>
            <Modal.Title id='finshed-exam-modal'>Math Directions</Modal.Title>
          </Modal.Header>
          <Modal.Body>test</Modal.Body>
          <Modal.Footer>
            <Button onClick={props.onHide}>Close</Button>
          </Modal.Footer>
        </Modal> */}

        {/* <Modal.Footer>
        <Button onClick={() => deleteExam(props.test)}> Delete </Button>
        <Button onClick={props.onHide}>Close</Button>
      </Modal.Footer> */}
      </div>
    </Draggable>
  )
}

export default MathRefModal
