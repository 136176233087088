import Plotly from 'plotly.js-basic-dist'
import createPlotlyComponent from 'react-plotly.js/factory'
import React from 'react'

function ResultsDataPlot({ test }) {
  const Plot = createPlotlyComponent(Plotly)
  // console.log(test)
  var y0 =
    (test.exam_score.module.m1correct /
      (test.exam_score.module.m1correct + test.exam_score.module.m1incorrect)) *
    100
  var y1 =
    (test.exam_score.module.m2correct /
      (test.exam_score.module.m2correct + test.exam_score.module.m2incorrect)) *
    100
  var y2 =
    (test.exam_score.module.m3correct /
      (test.exam_score.module.m3correct + test.exam_score.module.m3incorrect)) *
    100
  var y3 =
    (test.exam_score.module.m4correct /
      (test.exam_score.module.m4correct + test.exam_score.module.m4incorrect)) *
    100

  var plotdata = [
    {
      x: [
        'Reading Module 1',
        'Reading Module 2',
        'Math Module 1',
        'Math Module 2',
      ],
      y: [y0, y1, y2, y3],
      ylabel: 'score',
      type: 'bar',

      marker: {
        color: [
          'rgb(0,132,206)',
          'rgb(251,206,20)',
          'rgb(206,74,0)',
          'rgb(206,74,10)',
        ],
      },
    },
  ]
  return (
    <Plot
      data={plotdata}
      layout={{
        font: { size: 16 },
        width: 800,
        height: 400,
        title: 'Analysis',
        xaxis: { title: 'Level' },
        yaxis: { title: 'Scores (%)', range: [0, 100] },
      }}
    />
  )
}

export default ResultsDataPlot
