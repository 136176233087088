import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { MdCloudUpload, MdHome } from 'react-icons/md'

const NavBarLarge = styled.div``
const NavBarSmall = styled.div`
  width: 70px;
`
const NavBarContainer = styled.div`
  background: #f7f7f7;
  font-family: roboto;
  display: flex;
  justify-content: center;
  margin: 0;
`
const DashboardContainer = styled(Link)`
  color: black;
  text-decoration: none;
  font-size: 18px;
  padding: 10px 10px 0px 10px;
  &:hover {
    border-bottom: solid 6px rgba(33, 36, 44, 0.16);
    transition: box-shadow 0.4s ease;
  }

  ${(props) =>
    props.location.pathname === '/dashboard'
      ? ` 
    color: black;
    border-bottom: solid 6px #2088c8;`
      : null}
`

const PracticeContainer = styled(Link)`
  color: black;
  text-decoration: none;
  font-size: 18px;
  padding: 10px 10px 0px 10px;
  display: flex;
  gap: 30px;
  &:hover {
    border-bottom: solid 6px rgba(33, 36, 44, 0.16);
    transition: box-shadow 0.4s ease;
  }
  ${(props) =>
    props.location.pathname === '/upload'
      ? ` 
    color: white;
    border-bottom: solid 6px #2088c8;`
      : null}
`
const FullExamContainer = styled(Link)`
  color: black;
  text-decoration: none;
  font-size: 18px;
  padding: 10px 10px 0px 10px;
  display: flex;
  gap: 30px;
  // &:hover {
  //   border-bottom: solid 6px rgba(33, 36, 44, 0.16);
  //   transition: box-shadow 0.4s ease;
  }
  ${(props) =>
    props.location.pathname === '/exam'
      ? ` 
  color: black;
  border-bottom: solid 6px #2088c8;
  $:hover {
    border-bottom: solid 16px #0e5c8c;
  }`
      : null}
`

function NavBar({ location }) {
  const [width, setWidth] = useState(window.innerWidth)
  const breakpoint = 620

  useEffect(() => {
    window.addEventListener('resize', () => setWidth(window.innerWidth))
  })
  return (
    <NavBarContainer className='navbarcont'>
      <FullExamContainer location={location} to={'/exam'}>
        SAT Simulation
      </FullExamContainer>
      <PracticeContainer location={location} to={'/'}>
        Learning
      </PracticeContainer>
      <DashboardContainer location={location} to={'/'}>
        Trajectory
      </DashboardContainer>
    </NavBarContainer>
  )
}
export default NavBar
